import React from 'react';
import { getTheme } from '../components/theme-controller';
import { connect } from 'react-redux';
import get from 'lodash/get';

const Icon = ({ primaryColor, secondaryColor, accentColor, dispatch, ...props }) => {
	const theme = getTheme(primaryColor, secondaryColor, accentColor);
	return (
		<svg
			width="16px"
			height="14px"
			viewBox="0 0 16 14"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>D8253942-E478-447D-843A-91EF5095BE3F@3x</title>
			<desc>Created with sketchtool.</desc>
			<g
				id="Page-1"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
				strokeLinecap="square"
			>
				<g
					id="Icon-Set"
					transform="translate(-348.000000, -129.000000)"
					fillRule="nonzero"
					stroke={theme.palette.secondary.main}
				>
					<g id="Group-4-Copy" transform="translate(349.000000, 129.000000)">
						<path d="M0,1.14 L14,1.14" id="Line-2"></path>
						<path d="M0,7.14 L14,7.14" id="Line-2-Copy"></path>
						<path d="M0,13.14 L14,13.14" id="Line-2-Copy-2"></path>
					</g>
				</g>
			</g>
		</svg>
	);
};

const mapStateToProps = state => ({
	primaryColor: get(state, 'user.user.preferences.color_scheme.primary'),
	secondaryColor: get(state, 'user.user.preferences.color_scheme.secondary'),
	accentColor: get(state, 'user.user.preferences.color_scheme.accent'),
});

export default connect(mapStateToProps)(Icon);
