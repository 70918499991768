import { SIDEBAR } from '../constants';
import { createAction } from './utils';

export const updateHomeLeftSidebar = content => {
	return createAction(SIDEBAR.UPDATE_LEFT_HOME, content);
};

export const updateHomeRightSidebar = content => {
	return createAction(SIDEBAR.UPDATE_RIGHT_HOME, content);
};

export const toggleHomeLeftSidebar = () => {
	return createAction(SIDEBAR.TOGGLE_LEFT_HOME);
};

export const toggleHomeRightSidebar = () => {
	return createAction(SIDEBAR.TOGGLE_RIGHT_HOME);
};

export const updateGlobalLeftSidebar = content => {
	return createAction(SIDEBAR.UPDATE_LEFT_GLOBAL, content);
};

export const updateGlobalRightSidebar = content => {
	return createAction(SIDEBAR.UPDATE_RIGHT_GLOBAL, content);
};

export const toggleGlobalLeftSidebar = () => {
	return createAction(SIDEBAR.TOGGLE_LEFT_GLOBAL);
};

export const toggleGlobalRightSidebar = () => {
	return createAction(SIDEBAR.TOGGLE_RIGHT_GLOBAL);
};

export const resetAllSidebar = () => {
	return createAction(SIDEBAR.RESET);
};
