import React from 'react';
import ReactDOM from 'react-dom';
import { css } from 'emotion';
import HelpIcon from 'react-icons/lib/io/help-circled';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { heading, subHeading, subHeadingBold } from './styles';

const helpIcon = {
	fontSize: 45,
};

const main = css`
	position: fixed;
	bottom: 10px;
	right: 20px;
`;

class Help extends React.Component {
	state = {
		anchorEl: null,
	};

	handleClickButton = event => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
	};
	// TODO: update the links here
	handleClick = key => {
		switch (key) {
			case 'about_seller':
				// window.open('https://appbase.io/pricing/#support', '_blank');
				break;
			case 'become_seller':
				// window.open('https://twitter.com/appbaseio', '_blank');
				break;
			case 'working':
				// window.open('https://appbase.io/', '_blank');
				break;
			case 'refer':
				// window.open('https://appbase.io/privacy/', '_blank');
				break;
			case 'policy':
				window.open('https://www.iubenda.com/privacy-policy/79341112', '_blank');
				break;
			case 'term':
				// window.open('https://appbase.io/privacy/', '_blank');
				break;
			default:
		}
	};

	render() {
		const { anchorEl } = this.state;
		return (
			<div className={main}>
				<IconButton
					buttonRef={node => {
						this.anchorEl = node;
					}}
					color="secondary"
					onClick={this.handleClickButton}
					style={helpIcon}
				>
					<HelpIcon
						style={{
							marginLeft: 6,
						}}
					/>
				</IconButton>
				<Menu
					id="simple-menu"
					open={Boolean(anchorEl)}
					onClose={this.handleClose}
					anchorEl={anchorEl}
					getContentAnchorEl={null}
					// style={{
					// 	bottom: '80px important',
					// 	right: '10px important',
					// }}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
				>
					<MenuItem>
						<h3 className={heading}>Customer Support!</h3>
					</MenuItem>
					<MenuItem>
						<p className={subHeadingBold}>US +1 123 456 789</p>
					</MenuItem>
					<Divider />
					<MenuItem>
						<a
							className={subHeading}
							href="https://www.notion.so/industahub/Full-Stack-JavaScript-Engineer-8bf9ea2dad18449aaedb5e5d2be90c06"
							target="_blank"
						>
							We're hiring!
						</a>
					</MenuItem>
					<MenuItem onClick={() => this.handleClick('about_seller')}>
						<p className={subHeading}>About the sellers</p>
					</MenuItem>
					<MenuItem onClick={() => this.handleClick('become_seller')}>
						<p className={subHeading}>Become a seller</p>
					</MenuItem>
					<MenuItem onClick={() => this.handleClick('working')}>
						<p className={subHeading}>How it works</p>
					</MenuItem>
					<MenuItem onClick={() => this.handleClick('refer')}>
						<p className={subHeading}>Refer and save</p>
					</MenuItem>
					<MenuItem onClick={() => this.handleClick('policy')}>
						<p className={subHeading}>Privacy Policy</p>
					</MenuItem>
					<MenuItem onClick={() => this.handleClick('term')}>
						<p className={subHeading}>INDUSTAHUB - BUYER T&C</p>
					</MenuItem>
					<Divider />
					<MenuItem>
						<p className={subHeading}>&#9400; {new Date().getFullYear()} Industahub</p>
					</MenuItem>
				</Menu>
			</div>
		);
	}
}

const HelpChat = props =>
	ReactDOM.createPortal(<Help {...props} />, document.getElementById('help'));

export default HelpChat;
