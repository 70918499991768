export const APP_NAME = 'product-data';
export const APP_CREDENTIALS = '6LI1ESwZx:49bc0007-bb8b-4c56-9a53-4dba983ce36e';
export const authConfig = {
	audience: 'https://industahub.auth0.com/api/v2/',
	clientID: 'hFuEOgjmLQSqbckK50SB6a9zfmJQ07jF',
	domain: 'login.industahub.com',
};
export const FILE_STACK_API_KEY = 'AFXtTaO6YRNikpomE0WcPz';
export const metaDataConfig = 'http://we.dont.care/user_metadata';
export const whitelistedUsers = [
	'karl@infigic.com',
	'naresh@infigic.in',
	'siddharth@appbase.io',
	'kuldeep@appbase.io',
	'lakhan@appbase.io',
	'davida@bklevr.com',
	'david@industahub.com',
	'alma@industahub.com',
	'jyash97@gmail.com',
	'rishabh@codeation.io',
];
export const gridViewsOptions = [
	'seller_store',
	'courier',
	'connect',
	'calculator',
	'product_configurator',
	'hub',
	'scheduler',
];

export const DEFAULT_THEME_PRIMARY_COLOR = '#fff';
export const DEFAULT_THEME_SECONDARY_COLOR = '#333';
export const DEFAULT_THEME_ACCENT_COLOR = '#E62F23';

// export const API_URL = 'http://localhost:3001';
export const API_URL = 'https://backend.industahub.com';
export const APP_URL = `${API_URL}/search`;
export const CLUSTER_URL = 'https://industahub-new-jzjuovi-arc.searchbase.io';
export const CLUSTER_USERNAME = '8qYBPPsfC';
export const CLUSTER_PASSWORD = '77f6f1e1-2ccf-41f9-9531-f31214174861';
export const CLUSTER_CREDENTIALS = `${CLUSTER_USERNAME}:${CLUSTER_PASSWORD}`;
export const AUTH_HEADER = 'Basic ' + btoa(`${CLUSTER_USERNAME}:${CLUSTER_PASSWORD}`);
export const MARKETPLACE_ADMIN_HOSTED_URL = 'https://admin.industahub.com';
// export const MARKETPLACE_ADMIN_HOSTED_URL = 'http://localhost:3333';

export const ADMIN_URL = `${MARKETPLACE_ADMIN_HOSTED_URL}/?url=${CLUSTER_URL}&username=${CLUSTER_USERNAME}&password=${CLUSTER_PASSWORD}&redirect_url=admin`;

export const MAP_GEOCODE_KEY = 'AIzaSyB5Qs9BR260XoKQO-QBPHeMbp7y7DqkfJU';

export const pagesWithSearch = ['/', '/compare', '/recommend', '/compare/group'];

const getRandomView = () => {
	const key = Math.floor(Math.random() * 5);

	switch (key) {
		case 0:
			return 'group';
		case 1:
			return 'list';
		case 2:
			return 'map';
		case 3:
			return 'tabs';
		default:
			return 'group';
	}
};

const getRandomFacets = () => {
	const key = Math.floor(Math.random() * 5);

	return [
		{
			id: 'Application', // dataField
			label: 'Application', //
			isCollapsed: Math.random() > 0.35,
		},
		{
			id: 'Curators',
			label: 'Manufacturers',
			isCollapsed: Math.random() > 0.35,
		},
		{
			id: 'OEM',
			label: 'OEMs',
			isCollapsed: Math.random() > 0.35,
		},
		{
			id: 'Industry',
			label: 'Industries',
			isCollapsed: Math.random() > 0.35,
		},
		{
			id: 'Experts',
			label: 'Experts',
			isCollapsed: Math.random() > 0.35,
		},
	].slice(0, key);
};

export const customData = () => {
	return {
		default_view: getRandomView(),
		facets: getRandomFacets(),
	};
};

export const viewsByPath = {
	'/': 'recommend',
	'/compare': 'compare',
};

export const pathByView = {
	compare: '/compare',
	recommend: '/',
	list: '/list',
	group: '/',
};

export const hideCartOnNav = ['/cart', '/checkout'];
