import { CATEGORY } from '../constants';

const category = (state = [], action) => {
	switch (action.type) {
		case CATEGORY.UPDATE:
			return action.category;

		case CATEGORY.REMOVE:
			return null;
		default:
			return state;
	}
};

export default category;
