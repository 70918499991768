import React from 'react';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { SelectedFilters } from '@appbaseio/reactivesearch';
import 'react-table/react-table.css';

import Tab1 from './tab1';
import Tab2 from './tab2';
import Dimensions from './tab4';
import Technical from './tab3';
import './index.css';

import BOMView from '../bom';
import { useCustomRef } from '../../utils/refService';

const tabs = {
	tab1: 'Best Match',
	tab2: 'Pricing & Delivery',
	tab3: 'Dimensions',
	tab4: 'Technical',
};

const subViews = {
	bestMatch: tabs.tab1,
	pricing: tabs.tab2,
	dimensions: tabs.tab3,
	technical: tabs.tab4,
};

class Tabs extends React.Component {
	state = {
		activeTab: tabs.tab1,
		pageSize: 50,
		showCartInline: false,
		applied: false,
	};

	componentDidUpdate(prevProps) {
		const { view } = this.props;

		if (view !== prevProps.view) {
			/* eslint-disable-next-line */
			this.setState({
				applied: false,
			});
		}
	}

	static getDerivedStateFromProps(props, state) {
		const { view } = props;
		if (!view) {
			return state;
		}
		const subView = view.split(':')[1];
		if (
			view &&
			view.startsWith('compare') &&
			!state.applied &&
			subView &&
			subViews[subView] &&
			subViews[subView].toLowerCase() !== state.activeTab.toLowerCase()
		) {
			return {
				activeTab: subViews[subView],
				applied: true,
			};
		}
		return state;
	}

	handleTabChange = activeTab => {
		this.setState({
			activeTab,
		});
	};

	handlePageSizeChange = pageSize => {
		this.setState({
			pageSize,
		});
	};

	handleShowCartInline = () => {
		this.setState(prevState => ({
			showCartInline: !prevState.showCartInline,
		}));
	};

	clearFilter = () => {
		console.log('ClearFiler');
		useCustomRef('facets').triggerUpdate();
	};

	render() {
		const { activeTab, pageSize, showCartInline } = this.state;
		const { handleViewUpdate } = this.props;
		return (
			<div className="right-content" id="result-set">
				<div className="tabs-container">
					<div className="tab-list">
						{Object.keys(tabs).map((key, i) => {
							const isActive = tabs[key].toLowerCase() === activeTab.toLowerCase();
							return (
								<div
									onClick={() => this.handleTabChange(tabs[key])}
									key={key}
									className={`tab-list-item ${isActive && 'active-tab-item'}`}
								>
									{tabs[key]}
								</div>
							);
						})}
						<Button
							variant="contained"
							color="primary"
							className="inverse-button"
							onClick={this.handleShowCartInline}
							style={{ margin: '8px 20px 0 auto', height: '100%' }}
						>
							{showCartInline ? `Hide Cart` : `Show Cart Inline`}
						</Button>
					</div>
					<div className="dataContainer">
						{showCartInline ? (
							<div className="cart-inline">
								<BOMView
									containerStyle={{ margin: '10px 0 20px', padding: 0 }}
									tableProps={{ scroll: { x: 1300, y: 200 } }}
									hideSearch
								/>
							</div>
						) : null}
						<SelectedFilters
							showClearAll="default"
							className="selected-filter"
							onChange={this.clearFilter}
						/>
						{activeTab === tabs.tab1 && (
							<Tab1 handleViewUpdate={handleViewUpdate} pageSize={pageSize} />
						)}
						{activeTab === tabs.tab2 && (
							<Tab2 handleViewUpdate={handleViewUpdate} pageSize={pageSize} />
						)}
						{activeTab === tabs.tab3 && (
							<Dimensions handleViewUpdate={handleViewUpdate} pageSize={pageSize} />
						)}
						{activeTab === tabs.tab4 && (
							<Technical handleViewUpdate={handleViewUpdate} pageSize={pageSize} />
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		view: state.subview,
	};
};

export default connect(mapStateToProps)(Tabs);
