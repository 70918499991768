import React, { Fragment, createRef } from 'react';
import { Collapse, List, Popover } from 'antd';
import { MultiList } from '@appbaseio/reactivesearch';
import { get, union, difference, isEmpty } from 'lodash';
import { Link, withRouter } from 'react-router-dom';
import { facets, getComponentReactDependency, productIndex } from '../../../constants/facets';
import ListItem from '../../list-item/ListItem';
import ReactableComponent from '../../reactable';
import { setRef } from '../../../utils/refService';
import { peopleFacets, companyFacets, assetsFacets } from '../../../utils';

const { Panel } = Collapse;

const Icon = ({ icon, small, iconType = 'svg' }) => {
	return icon ? (
		<img
			alt=""
			className={`${small ? 'small' : ''} facet-panel-icon`}
			src={`/images/icons/${icon}.${iconType}`}
		/>
	) : null;
};

const PanelHeader = ({ icon, title, small, iconType = 'svg' }) => (
	<div className="panel-header">
		<Icon icon={icon} iconType={iconType} small={small} />
		{title ? <span className="panel-title">{title}</span> : null}
	</div>
);

class Facets extends React.Component {
	state = {
		activeKey: [productIndex],
		lastValuesChange: {
			[companyFacets[0]]: '',
			[peopleFacets[0]]: '',
			[assetsFacets[0]]: '',
			[assetsFacets[1]]: '',
			[assetsFacets[2]]: '',
		},
	};

	references = {
		[peopleFacets[0]]: createRef(),
		[companyFacets[0]]: createRef(),
	};

	constructor(props) {
		super(props);
		setRef('facets', this);
	}

	componentDidUpdate(prevProps) {
		const { activeKey, lastValuesChange } = this.state;
		const { selectedSidebarMenu } = this.props;
		const search = new URLSearchParams(window.location.search);
		const keys = [];
		const extState = {
			lastValuesChange: {
				...lastValuesChange,
			},
		};
		if (
			search.has(companyFacets[0]) &&
			search.get(companyFacets[0]) !== lastValuesChange[companyFacets[0]] &&
			!activeKey.find(i => i === 'Company')
		) {
			keys.push('Company');
			extState.lastValuesChange[companyFacets[0]] = search.get(companyFacets[0]);
		}

		if (
			search.has(peopleFacets[0]) &&
			search.get(peopleFacets[0]) !== lastValuesChange[peopleFacets[0]] &&
			!activeKey.find(i => i === 'People')
		) {
			keys.push('People');
			extState.lastValuesChange[peopleFacets[0]] = search.get(peopleFacets[0]);
		}

		if (
			search.has(assetsFacets[0]) &&
			search.get(assetsFacets[0]) !== lastValuesChange[assetsFacets[0]] &&
			!activeKey.includes('Assets') &&
			!keys.includes('Assets')
		) {
			keys.push('Assets');
			extState.lastValuesChange[assetsFacets[0]] = search.get(assetsFacets[0]);
		}
		if (
			search.has(assetsFacets[1]) &&
			search.get(assetsFacets[1]) !== lastValuesChange[assetsFacets[1]] &&
			!activeKey.includes('Assets') &&
			!keys.includes('Assets')
		) {
			keys.push('Assets');
			extState.lastValuesChange[assetsFacets[1]] = search.get(assetsFacets[1]);
		}
		if (
			search.has(assetsFacets[2]) &&
			search.get(assetsFacets[2]) !== lastValuesChange[assetsFacets[2]] &&
			!activeKey.includes('Assets') &&
			!keys.includes('Assets')
		) {
			keys.push('Assets');
			extState.lastValuesChange[assetsFacets[2]] = search.get(assetsFacets[2]);
		}

		if (
			(selectedSidebarMenu && get(prevProps, 'selectedSidebarMenu') !== selectedSidebarMenu) ||
			difference(keys, activeKey).length > 0
		) {
			this.onCollapseChange(union(selectedSidebarMenu, activeKey, keys), extState);
		}
	}

	onCollapseChange = (key, extState = {}) => {
		this.setState({
			activeKey: key,
			...extState,
		});
	};

	triggerUpdate = () => {
		console.log('triggerUpdate', this);
		this.forceUpdate();
	};

	renderContent = data => {
		const { facets: filters, useDynamicFacets, type, items } = data;

		if (type === 'list') {
			return (
				<List
					bordered={false}
					dataSource={Object.keys(items)}
					renderItem={item => {
						if (items[item].type === 'list') {
							return (
								<React.Fragment key={item}>
									<List.Item style={{ border: 0 }}>
										<PanelHeader
											icon={get(items, `${item}.icon`)}
											iconType={get(items, `${item}.iconType`)}
											title={item}
										/>
									</List.Item>
									<div style={{ padding: '0 10px' }}>{this.renderContent(items[item])}</div>
								</React.Fragment>
							);
						}

						if (items[item].type === 'menu') {
							return (
								<React.Fragment key={item}>
									<Collapse bordered={false} expandIconPosition="right">
										<Panel
											header={
												<PanelHeader
													icon={get(items, `${item}.icon`)}
													iconType={get(items, `${item}.iconType`)}
													title={item}
												/>
											}
											key={item}
										>
											<div style={{ padding: '0 10px' }}>
												{this.renderContent(items[item].content)}
											</div>
										</Panel>
									</Collapse>
								</React.Fragment>
							);
						}
						if (items[item].type === 'link') {
							return (
								<Link key={item} to={items[item].link}>
									<List.Item style={{ border: 0 }}>
										<PanelHeader
											iconType={get(items, `${item}.iconType`)}
											icon={get(items, `${item}.icon`)}
											title={item}
										/>
									</List.Item>
								</Link>
							);
						}

						if (items[item].type === 'popover') {
							return (
								<Popover
									key={item}
									content={<img style={{ maxWidth: '100%' }} src={items[item].image} alt={item} />}
									title={null}
									overlayClassName="facet-popover"
									placement="rightBottom"
									trigger="click"
								>
									<List.Item style={{ border: 0 }}>
										<PanelHeader
											iconType={get(items, `${item}.iconType`)}
											icon={get(items, `${item}.icon`)}
											title={item}
										/>
									</List.Item>
								</Popover>
							);
						}
						return null;
					}}
				/>
			);
		}

		if (!filters) {
			return null;
		}

		const { dynamic_facets } = this.props;
		return (
			<React.Fragment>
				{useDynamicFacets && dynamic_facets && dynamic_facets.length > 0 ? (
					<React.Fragment>
						{dynamic_facets.map(facet => (
							<Collapse
								key={facet.id}
								defaultActiveKey={facet.isCollapsed ? '' : facet.id}
								bordered={false}
								expandIconPosition="right"
							>
								<Panel key={facet.id} header={<PanelHeader title={facet.label} />}>
									{this.renderFilter(
										{
											component: MultiList,
											componentId: facet.id.replace('.keyword', ''),
											dataField: facet.id,
											title: facet.label,
										},
										facet.label,
										false,
									)}
								</Panel>
							</Collapse>
						))}
					</React.Fragment>
				) : null}
				{Object.keys(filters).map(filter => {
					switch (filters[filter].type) {
						case 'menu': {
							const WrapperComponent = filters[filter].wrapperComponent
								? filters[filter].wrapperComponent
								: Fragment;
							return (
								<Collapse
									key={filter}
									bordered={false}
									defaultActiveKey={filter}
									expandIconPosition="right"
								>
									<Panel
										header={
											<PanelHeader
												icon={get(filters, `${filter}.icon`)}
												iconType={get(filters, `${filter}.iconType`)}
												title={filter}
											/>
										}
										key={filter}
									>
										<WrapperComponent>{this.renderContent(filters[filter])}</WrapperComponent>
									</Panel>
								</Collapse>
							);
						}
						default:
							return this.renderFilter(filters[filter], filter);
					}
				})}
			</React.Fragment>
		);
	};

	renderFilter = (filter, title, showTitle = true) => {
		const {
			component: Component,
			skipReactDependency,
			placeholder,
			iconType,
			icon,
			react = { or: [], and: [] },
			hiddenReactableComponent,
			wrapperComponent: WrapperComponent = Fragment,
			...props
		} = filter;
		let extraProps = {};

		if (placeholder) {
			return (
				<div className="filter" key={filter.componentId}>
					<PanelHeader icon={icon} iconType={iconType} title={title} />
				</div>
			);
		}

		const isMultiList = Component.name === 'MultiList';
		const isRangeInput = Component.name === 'RangeInput';

		if (isMultiList) {
			extraProps = {
				renderItem: (item, count) => {
					return <ListItem showCount={props.showCount} item={item} key={item._id} count={count} />;
				},
				renderLoader: () => `Loading ${title}...`,
				showSearch: props.showSearch || false,
				showCheckbox: false,
				sortBy: props.sortBy || 'count',
				aggregationSize: props.size || 100,
			};
		} else if (isRangeInput) {
			extraProps = {
				showHistogram: false,
				includeNullValues: true,
			};
		}

		// define customQuery for the facets that lies on different index other than products. Query will be match_all: {}.
		if (skipReactDependency && isEmpty(react.or) && isEmpty(react.and)) {
			extraProps = {
				...extraProps,
				customQuery: () => {
					return {
						query: {
							match_all: {},
						},
					};
				},
			};
		}

		const react$ = skipReactDependency
			? { or: [], and: [] }
			: getComponentReactDependency(props.componentId);

		return (
			<Fragment key={filter.componentId}>
				{hiddenReactableComponent && <ReactableComponent key={filter.componentId} {...props} />}
				<WrapperComponent>
					<Component
						{...props}
						{...extraProps}
						className="filter"
						key={filter.componentId}
						URLParams
						title={
							showTitle ? <PanelHeader small iconType={iconType} title={title} icon={icon} /> : null
						}
						react={{ or: union(react.or, react$.or), and: union(react.and, react$.and) }}
					/>
				</WrapperComponent>
			</Fragment>
		);
	};

	render() {
		const { activeKey, loading } = this.state;
		if (loading) {
			return null;
		}
		return (
			<Collapse
				activeKey={activeKey}
				// accordion
				expandIconPosition="right"
				onChange={this.onCollapseChange}
			>
				{Object.keys(facets).map(facet => {
					const WrapperComponent = facets[facet].wrapperComponent
						? facets[facet].wrapperComponent
						: Fragment;

					return (
						<Panel
							header={
								<PanelHeader
									iconType={get(facets, `${facet}.iconType`)}
									icon={get(facets, `${facet}.icon`)}
									title={facet}
								/>
							}
							key={facet}
						>
							<WrapperComponent>{this.renderContent(facets[facet])}</WrapperComponent>
						</Panel>
					);
				})}
			</Collapse>
		);
	}
}

export default withRouter(Facets);
