import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

class ErrorPopup extends React.Component {
	state = {
		open: true,
	};

	componentDidUpdate(prevProps) {
		if (this.props.error !== prevProps.error) {
			this.setState({
				open: !!this.props.error,
			});
		}
	}

	get errorMsg() {
		const { error } = this.props;
		console.error(error);
		if (!error) {
			console.log('error is not present: ', error);
			return 'Something went wrong';
		}
		switch (error.status) {
			case 401:
				return 'You are not authorized to access this view, or your login session may have expired.';
			case 429:
				return 'You have reached the maximum number of allowed free searches. Upgrade your plan now!';
			default:
				console.log('error status: ', error.status);
				return 'Something went wrong';
		}
	}

	handleClose = () => {
		this.setState({
			open: false,
		});
	};

	render() {
		const { open } = this.state;
		return (
			<Dialog open={open} onClose={this.handleClose} aria-labelledby="simple-dialog-title">
				<DialogTitle id="simple-dialog-title">Error</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{this.errorMsg}</DialogContentText>
				</DialogContent>
			</Dialog>
		);
	}
}

export default ErrorPopup;
