import React from 'react';
import { connect } from 'react-redux';

import Results from './Results';

const tabs = {
	tab1: 'Application',
	tab2: 'Industry',
	tab3: 'Equipment',
};

const subViews = {
	application: tabs.tab1,
	industry: tabs.tab2,
	equipment: tabs.tab3,
};

class Group extends React.PureComponent {
	state = {
		applied: false,
	};

	componentDidUpdate(prevProps) {
		const { view } = this.props;

		if (view !== prevProps.view) {
			/* eslint-disable-next-line */
			this.setState({
				applied: false,
			});
		}
	}

	static getDerivedStateFromProps(props, state) {
		const { view } = props;
		if (!view) {
			return state;
		}
		const subView = view.split(':')[1];
		if (
			view &&
			(view.startsWith('recommend') || view.startsWith('group')) &&
			!state.applied &&
			subView &&
			subViews[subView] &&
			subViews[subView].toLowerCase() !== state.activeTab.toLowerCase()
		) {
			return {
				applied: true,
			};
		}
		return state;
	}

	render() {
		const { handleViewUpdate, showDesc } = this.props;

		return (
			<div className="right-content">
				<div className="tabs-container">
					<div className="dataContainer">
						<Results handleViewUpdate={handleViewUpdate} showDesc={showDesc} />
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		view: state.subview,
	};
};

export default connect(mapStateToProps)(Group);
