import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { whitelistedUsers, authConfig } from '../../../constants/config';
import { logoutUser } from '../../../actions';
import Auth from '../../../utils/authenticate';

const auth = new Auth(authConfig);

const UserProfile = ({ userRole, history, logout, isLoggedIn }) => {
	const handleLogout = () => {
		auth.logout();
		setTimeout(() => logout(), 100);
	};

	if (!isLoggedIn) {
		return 'Please sign in';
	}

	return (
		<React.Fragment>
			<List component="nav">
				<ListItem>
					<ListItemText primary={`Logged In as ${userRole}`} />
				</ListItem>
				<ListItem
					onClick={() => {
						history.push('/account');
					}}
					button
				>
					<ListItemText primary="My Account" />
				</ListItem>
				<ListItem
					onClick={() => {
						history.push('/orders');
					}}
					button
				>
					<ListItemText primary="My Orders" />
				</ListItem>
			</List>
			<Divider />
			<List component="nav">
				<ListItem onClick={handleLogout} button>
					<ListItemText primary="Logout" />
				</ListItem>
			</List>
		</React.Fragment>
	);
};

const mapStateToProps = state => {
	const email = get(state, 'user.user.email');
	const defaultBrandTitle = whitelistedUsers.includes(email) ? 'Admin User' : 'Preview User';
	return {
		isLoggedIn: !!get(state, 'user.user'),
		userRole: get(state, 'user.user.preferences.branding_title', defaultBrandTitle),
	};
};

const mapDispatchToProps = dispatch => ({
	logout: () => dispatch(logoutUser()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserProfile));
