import React from 'react';
import { DragSource, DropTarget } from 'react-dnd';

class BodyRow extends React.Component {
	render() {
		const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = this.props;
		const style = { ...restProps.style, cursor: 'move' };

		let { className } = restProps;
		if (isOver) {
			switch (restProps.record.type) {
				case 'kit_item':
					className += ' drop-over-kit-item';
					break;
				case 'kit':
				case 'child_kit':
					className += ' drop-over-kit';
					break;
				case 'item':
					className += ' drop-over-item';
					break;
			}
		}

		return connectDragSource(
			connectDropTarget(<tr {...restProps} className={className} style={style} />),
		);
	}
}

const rowSource = {
	beginDrag(props) {
		return {
			index: props.index,
			record: props.record,
		};
	},
};

const rowTarget = {
	drop(props, monitor) {
		const dragSource = monitor.getItem().record;
		const hoverRecord = props.record;
		const hoverIndex = props.index;
		// Don't replace items with themselves
		const sameItem = dragSource.id === hoverRecord.id && dragSource.type === hoverRecord.type;

		if (sameItem && dragSource.type === 'kit_item' && hoverRecord.kit === dragSource.kit) {
			return;
		}

		// Time to actually perform the action
		props.moveRow(dragSource);

		// Note: we're mutating the monitor item here!
		// Generally it's better to avoid mutations,
		// but it's good here for the sake of performance
		// to avoid expensive index searches.
		monitor.getItem().index = hoverIndex;
	},
};

const DraggableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
	connectDropTarget: connect.dropTarget(),
	isOver: monitor.isOver(),
}))(
	DragSource('row', rowSource, connect => ({
		connectDragSource: connect.dragSource(),
	}))(BodyRow),
);

export default DraggableBodyRow;
