import React from 'react';
import { SingleDropdownList } from '@appbaseio/reactivesearch';
import { connect } from 'react-redux';
import CategoryMap from './category-map';
import default_image from '../../image/default_image.jpg';

class CategoryFilter extends React.Component {
	state = {
		value: 'All Categories',
	};

	componentDidUpdate(prevProps) {
		const { predictedCategory } = this.props;
		if (prevProps.predictedCategory !== predictedCategory) {
			this.handleCategoryChange(predictedCategory);
		}
	}

	handleCategoryChange = category => {
		this.setState({
			value: category || 'All Categories',
		});
	};

	render() {
		const { value } = this.state;
		return (
			<SingleDropdownList
				sortBy="count"
				showSearch
				value={value}
				onChange={this.handleCategoryChange}
				customQuery={(val, props) => {
					if (val === 'All Categories') {
						return {
							query: {
								match_all: {},
							},
						};
					}
					return {
						query: {
							match: {
								'Category_space_Profile.keyword': val,
							},
						},
					};
				}}
				style={{ minWidth: 180 }}
				// placeholder="All Categories"
				transformData={values => [{ key: 'All Categories', doc_count: 0 }, ...values]}
				URLParams={value !== 'All Categories'}
				showFilter={value !== 'All Categories'}
				renderItem={(label, count, isSelected) => (
					<div>
						<img
							width="20"
							style={{ marginRight: 10 }}
							src={CategoryMap[label] || default_image}
							alt={label}
						/>
						<span style={isSelected ? { fontWeight: 'bold' } : {}}>{label}</span>
					</div>
				)}
				componentId="Category_space_Profile"
				filterLabel="Category Profile"
				dataField="Category_space_Profile.keyword"
			/>
		);
	}
}

const mapStateToProps = state => ({
	predictedCategory: state.category,
});

export default connect(mapStateToProps, null)(CategoryFilter);
