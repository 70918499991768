export const getUrlParams = url => {
	if (!url) {
		// treat a falsy value as having no params
		return {};
	}
	const searchParams = new URLSearchParams(url);
	return Array.from(searchParams.entries()).reduce(
		(allParams, [key, value]) => ({
			...allParams,
			[key]: value,
		}),
		{},
	);
};

export const parseJwt = token => {
	if (!token) {
		return null;
	}
	const base64Url = token.split('.')[1];
	const base64 = base64Url.replace('-', '+').replace('_', '/');
	try {
		return JSON.parse(window.atob(base64));
	} catch (e) {
		console.error(e);
	}
};

export const getColorsFromRGBA = color => {
	const rgb = color.match(/\d+/g);
	return rgb;
};

export const planBasePrice = {
	bootstrap: 29,
	growth: 89,
};

export const nanonetsMapping = {
	Rod_Wipers: 'Rod Wipers',
	Shaft_Seals: 'Shaft Seals',
	'O-Rings': 'O-Rings',
};

export const defaultDataFields = [
	{
		field: 'ID_open_d_close__space_-_space_Nominal_numeric',
		weight: 1,
	},
	{
		field: 'ID_open_d_close__space_-_space_Nominal',
		weight: 1,
	},
	{
		field: 'ID',
		weight: 1,
	},
	{
		field: 'Product_space_Type',
		weight: 1,
	},
	{
		field: 'Profile_space_Recipe',
		weight: 1,
	},
	{
		field: 'Profile',
		weight: 1,
	},
	{
		field: 'Product_space_Group',
		weight: 1,
	},
	{
		field: 'Description_space_4',
		weight: 2,
	},
	{
		field: 'Description_space_4.search',
		weight: 1,
	},
	{
		field: 'Description_space_3',
		weight: 2,
	},
	{
		field: 'Description_space_3.search',
		weight: 1,
	},
	{
		field: 'Description_space_2',
		weight: 2,
	},
	{
		field: 'Description_space_2.search',
		weight: 1,
	},
	{
		field: 'Description',
		weight: 2,
	},
	{
		field: 'Description.search',
		weight: 1,
	},
	{
		field: 'P21_space_Part_space_#',
		weight: 10,
	},
	{
		field: 'P21_space_Part_space_#.search',
		weight: 1,
	},
	{
		field: 'Part#_space_1',
		weight: 10,
	},
];
export const getTotal = (items = [], fn = () => {}, precision = 2) => {
	const total = items.reduce((agg, item) => {
		return agg + fn(item) + getTotal(item.children, fn);
	}, 0);
	return total ? Number(total.toFixed(precision)) : total;
};

export const companyFacets = ['Account Name'];
export const peopleFacets = ['Full Name (Transfer to First/Last)'];
export const assetsFacets = ['Make', 'Model', 'Equipment'];


export const COMPANY_INDEX = 'fluidseal-tenant-company';
export const PEOPLE_INDEX = 'fluidseal-tenant-people';
export const ASSET_INDEX = 'assets-data';

export const getSearchWidth = ({
	globalLeftSidebar,
	globalRightSidebar,
	homeLeftSidebar,
	homeRightSidebar,
}) => {
	const numberOfSidebarOpen =
		globalLeftSidebar + globalRightSidebar + homeLeftSidebar + homeRightSidebar;
	let minWidthForBars = 0;

	if (!homeLeftSidebar && numberOfSidebarOpen) {
		// Extra 40 because we need to show bars at the left sidebar which needs 40px width
		minWidthForBars = 80;
	}

	if (globalRightSidebar) {
		// Extra 60 because global right sidebar width is 320px
		minWidthForBars = minWidthForBars + 60;
	}

	return numberOfSidebarOpen
		? `calc(100% - ${260 * numberOfSidebarOpen + minWidthForBars}px)`
		: `calc(100% - 80px)`;
};

export const getRightPosition = ({ globalRightSidebar, homeRightSidebar }) => {
	return (globalRightSidebar + homeRightSidebar) * 260 + (globalRightSidebar ? 60 : 0);
};

export const constantFilters = ['Full Name (Transfer to First/Last)', 'Account Name', 'q'];
