const SIDEBAR = {
	TOGGLE: 'TOGGLE_SIDEBAR',
	TOGGLE_LEFT_HOME: 'TOGGLE_LEFT_HOME_SIDEBAR',
	TOGGLE_RIGHT_HOME: 'TOGGLE_RIGHT_HOME_SIDEBAR',
	UPDATE_RIGHT_HOME: 'UPDATE_RIGHT_HOME_SIDEBAR',
	UPDATE_LEFT_HOME: 'UPDATE_LEFT_HOME_SIDEBAR',
	TOGGLE_LEFT_GLOBAL: 'TOGGLE_LEFT_GLOBAL_SIDEBAR',
	TOGGLE_RIGHT_GLOBAL: 'TOGGLE_RIGHT_GLOBAL_SIDEBAR',
	UPDATE_RIGHT_GLOBAL: 'UPDATE_RIGHT_GLOBAL_SIDEBAR',
	UPDATE_LEFT_GLOBAL: 'UPDATE_LEFT_GLOBAL_SIDEBAR',
	RESET: 'RESET_SIDEBAR',
};

const FACETS = {
	ADD_FACETS: 'ADD_FACETS',
	REMOVE_FACETS: 'REMOVE_FACETS',
	UPDATE_CATEGORY: 'UPDATE_CATEGORY',
};

const CATEGORY = {
	UPDATE: 'UPDATE_CATEGORY',
	REMOVE: 'REMOVE_CATEGORY',
};

const USER = {
	LOGIN: 'USER_LOGIN',
	LOGOUT: 'USER_LOGOUT',
	GET_ROLE_DETAILS: 'USER_GET_ROLE_DETAILS',
	ADD_DYNAMIC_FACETS: 'ADD_DYNAMIC_FACETS',
};

const USER_PLAN = {
	GET: 'GET_USER_PLAN',
	GET_SUCCESS: 'GET_USER_PLAN_SUCCESS',
	GET_ERROR: 'GET_USER_PLAN_ERROR',
	UPDATE: 'UPDATE_USER_PLAN',
	UPDATE_SUCCESS: 'UPDATE_USER_PLAN_SUCCESS',
	UPDATE_ERROR: 'UPDATE_USER_PLAN_ERROR',
};

const CART = {
	UPDATE_CART: 'UPDATE_CART',
	ADD_PRODUCT: 'ADD_PRODUCT',
	REMOVE_PRODUCT: 'REMOVE_PRODUCT',
	ADD_KIT: 'ADD_KIT',
	REMOVE_KIT: 'REMOVE_KIT',
	UPDATE_KIT: 'UPDATE_KIT',
	UPDATE_PRODUCT: 'UPDATE_PRODUCT',
	REORDER_ITEMS: 'REORDER_ITEMS',
	ADD_SUB_KIT: 'ADD_SUB_KIT',
	REMOVE_SUB_KIT: 'REMOVE_SUB_KIT',
	REMOVE_SUB_KIT_ITEM: 'REMOVE_SUB_KIT_ITEM',
	ADD_SUB_KIT_ITEM: 'ADD_SUB_KIT_ITEM',
	ADD_KIT_ITEM: 'ADD_KIT_ITEM',
	REMOVE_KIT_ITEM: 'REMOVE`_KIT_ITEM',
};

const ORDER = {
	UPDATE_ORDER: 'UPDATE_ORDER',
};

const SUBVIEW = {
	UPDATE: 'UPDATE_SUBVIEW',
};

export { SIDEBAR, FACETS, USER, USER_PLAN, CATEGORY, CART, ORDER, SUBVIEW };
