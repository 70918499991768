export const leftSidebarContent = {
	Facets: 'FACETS',
	CP_Facets: 'COMPANY_PERSON_FACETS',
	Admin: 'ADMIN',
};

export const rightSidebarContent = {
	Cart: 'CART',
	Profile: 'PROFILE',
};
