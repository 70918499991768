import React from 'react';
import { Button, Icon } from 'antd';
import { ReactComponent as Search } from '../../icons/search.svg';
import Loader from '../loader';
import Suggestion from '../suggestions';
import { DataSearch } from '@appbaseio/reactivesearch';
import { get } from 'lodash';
import { defaultDataFields } from '../../utils';
import { connect } from 'react-redux';
import { getTheme } from '../theme-controller';
import { normalizeProductData } from '../add-cart';

const newKey = Date.now();

function ItemSearch({
	searchRef,
	dataFields,
	primaryColor,
	secondaryColor,
	accentColor,
	draggableRef,
}) {
	const theme = getTheme(primaryColor, secondaryColor, accentColor);
	const addProduct = selectedSuggestion => {
		const cart = draggableRef.current.state.cart;
		if (selectedSuggestion) {
			const itemToAdd = normalizeProductData(selectedSuggestion);

			const isItemPresent = cart.find(item => item.id === itemToAdd.id && item.type === 'item');
			if (isItemPresent) {
				if (draggableRef && draggableRef.current) {
					draggableRef.current.updateProductQuantity({
						id: itemToAdd.id,
						quantity: (isItemPresent.quantity || 0) + (itemToAdd.quantity || 0),
					});
				}
			} else {
				if (draggableRef && draggableRef.current) {
					draggableRef.current.addProductToCart(normalizeProductData(selectedSuggestion));
				}
			}

			if (searchRef && searchRef.current && searchRef.current.clearValue) {
				searchRef.current.clearValue();
			}
		}
	};
	return (
		<DataSearch
			key={`search-${newKey}`}
			componentId={`product_search-${newKey}`}
			icon={<Icon type="search" />}
			innerClass={{ list: 'suggestion' }}
			ref={searchRef}
			placeholder="Search and add product in Cart"
			className="datasearch home-datasearch home-page-search product-search"
			iconPosition="right"
			showDistinctSuggestions
			icon={
				<span
					style={{
						color: '#fff',
						background: theme.palette.secondary.main,
						borderTopRightRadius: '3px',
						borderBottomRightRadius: '3px',
						padding: '0 15px',
						marginRight: '-12px',
						height: '50px',
						paddingTop: '13px',
						paddingBottom: '11px',
						cursor: 'pointer',
						zIndex: 100,
					}}
				>
					<Search />
				</span>
			}
			render={({
				loading,
				rawData,
				value,
				downshiftProps: { isOpen, getItemProps, highlightedIndex },
			}) => {
				if (isOpen && Boolean((value || '').length))
					return (
						<div
							style={{
								position: 'absolute',
								background: '#fff',
								color: '#424242',
								fontSize: '0.9rem',
								border: '1px solid #ddd',
								borderRadius: 2,
								marginTop: 0,
								width: '100%',
								zIndex: 1000,
								maxHeight: 300,
								minHeight: 'auto',
								overflowY: 'scroll',
							}}
						>
							{loading ? (
								<Loader />
							) : (
								<div>
									{get(rawData, 'hits.hits', [])
										.slice(0, 10)
										.map((suggestion, index) => (
											<div
												style={{
													background: index === highlightedIndex ? '#eee' : 'transparent',
													cursor: 'pointer',
												}}
												key={suggestion._id}
												{...getItemProps({
													item: {
														value:
															String(suggestion._source['P21_space_Part_space_#']) ||
															String(suggestion._source['Vendor_space_Part#']),
														_click_id: index,
													},
												})}
											>
												<div
													onClick={e => {
														e.preventDefault();
														addProduct({
															...suggestion,
															...suggestion._source,
														});
													}}
												>
													<Suggestion
														showProfileLabel
														suggestion={suggestion}
														renderAddProduct={quantity => (
															<Button
																onClick={e => {
																	e.preventDefault();
																	e.stopPropagation();

																	addProduct({
																		...suggestion,
																		...suggestion._source,
																		quantity,
																	});
																}}
															>
																<Icon type="shopping-cart" />
																Add
															</Button>
														)}
													/>
												</div>
											</div>
										))}
								</div>
							)}
						</div>
					);
			}}
		/>
	);
}

const mapStateToProps = state => {
	const dataFields = get(state, 'user.user.dataFields.length')
		? get(state, 'user.user.dataFields')
		: defaultDataFields;
	return { dataFields };
};

export default connect(mapStateToProps)(ItemSearch);
