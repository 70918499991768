import React from 'react';
import { Check } from 'react-feather';

export const pricingDetails = {
	Experts: {
		price: 0,
		name: 'Expert',
		key: 'experts',
	},
	Distributors: {
		price: 100,
		name: 'Distributor',
		key: 'distributors',
	},
	WholesaleDistributors: {
		price: 300,
		name: 'WholesaleDistributor',
		key: 'wholesale-distributors',
	},
	Manufacturers: {
		price: 1000,
		name: 'Manufacturer',
		key: 'manufacturers',
	},
};
export const tableDetails = [
	[
		<span data-tip="Membership level: Each level is defined by the monthly fee and usage limits. A member may request a change to his membership level at any time.">
			Limits
		</span>,
		'_',
		'_',
		'_',
		'_',
	],
	[
		<span data-tip="A member may post an item quantity as a total of all quantities of that item at multiple warehouses, or as warehouse-specific quantities. Levels #3 and #4 are for those members who choose to post inventory for sale at the resolution of specific warehouses.">
			Locations
		</span>,
		'_',
		'_',
		'_',
		'_',
	],
	[
		<span data-tip="A member may create multiple user accounts per membership. Such resolution allows the member to better measure site usage.">
			Users
		</span>,
		'_',
		'_',
		'_',
		'_',
	],
	[
		<span data-tip="A member is restricted to view and post items only from manufacturers/divisions for which it is currently authorized.">
			Communities
		</span>,
		'_',
		'_',
		'_',
		'_',
	],
	[
		<span data-tip="A member is restricted to the number of items that may be posted to the web site for sale at any one time.">
			Posted Items
		</span>,
		'_',
		'_',
		'_',
		'_',
	],
	[<h4>Standard Features</h4>, '', '', '', ''],
	[
		<span data-tip="Browse inventory available from peer distributors to fulfill your end-customers’ open backorders.">
			Search
		</span>,
		<Check />,
		<Check />,
		<Check />,
		<Check />,
	],
	[
		<span data-tip="Upload a “shopping list” of your most commonly sold items. This feature will identify if any other Industahub member is offering any of these items at or below an acceptable threshold cost.">
			Bargain Hunt
		</span>,
		<Check />,
		<Check />,
		<Check />,
		<Check />,
	],
	[
		<span data-tip="Receive inquiry emails from other Industahub members who need an item, but could not find it in a Search result. Every one of these emails is a potential sale!">
			Receive Broadcast Requests
		</span>,
		<Check />,
		<Check />,
		<Check />,
		<Check />,
	],
	[
		<span data-tip="Receive monthly activity metrics via email. (All membership levels.) Receive a reminder email before your inventory data expires. (Membership levels 2 and higher only.) Receive a notification email when a new member joins one of your inventory-sharing communities. (Membership levels 3 and higher only.)">
			Default System Notifications
		</span>,
		<Check />,
		<Check />,
		<Check />,
		<Check />,
	],
	[<h4>Premium Features</h4>, '', '', '', ''],
	[
		<span data-tip="Search for up to twenty different part numbers simultaneously, instead of searching for one part number at a time.">
			Multi Search
		</span>,
		<Check />,
		<Check />,
		<Check />,
		<Check />,
	],
	[
		<span data-tip="Post your inventory at the site, for sale to other like-authorized distributors.">
			Post Inventory
		</span>,
		<Check />,
		<Check />,
		<Check />,
		<Check />,
	],
	[
		<span data-tip="Send an e-mail to all other like-authorized members of Industahub, to request an item that you could not find in a search result. This feature enables you to gain access to inventory not posted at the site.">
			Send Broadcast Requests
		</span>,
		<Check />,
		<Check />,
		<Check />,
		<Check />,
	],
	[
		<span data-tip="View “Search” and “Multi-Search” page layouts optimized for mobile devices with small screens (“smart phones”).">
			Mobile-Friendly Search Pages
		</span>,
		'_',
		<Check />,
		<Check />,
		<Check />,
	],
	[
		<span data-tip="Manually and automatically export inventory data posted by other Industahub members for use off-line.">
			Export Inventory Data
		</span>,
		'_',
		<Check />,
		<Check />,
		<Check />,
	],
	[
		<span data-tip="Download contact information (e.g., name, address, phone number) of every Industahub member participating in one or more of the inventory-sharing communities in which you also participate. Use this information to create customer and supplier accounts in your ERP system in anticipation of transacting with other Industahub members.">
			Export Member Records
		</span>,
		'_',
		<Check />,
		<Check />,
		<Check />,
	],
	[
		<span data-tip="Cross-reference inventory items as entered in your ERP system with standard part numbers or descriptions used by other Industahub members. Even if you do not use the manufacturer's exact model number as the part number in your ERP system, your item can still appear in other members' search results when they search on correct manufacturers' part numbers. This feature also helps peer distributors share commodity items, even if members use different part numbers or descriptions to identify the same commodity item.">
			Related Items
		</span>,
		'_',
		<Check />,
		<Check />,
		<Check />,
	],
	[
		<span data-tip="Modify which automated notification emails you receive, when you receive them and who at your company receives them. Notifications are for inventory data expiration, inventory data imports, inventory data exports and new members.">
			Customizable System Notifications
		</span>,
		'_',
		<Check />,
		<Check />,
		<Check />,
	],
	[
		<span data-tip="Post items to a designated location record on your account such that these postings are exposed to internet search engines. This may result in any non-member of Industahub being able to find these items simply by searching for their part numbers in a search engine (such as Bing, Google or Yahoo). This feature is intended to give you a much larger potential audience for your surplus inventory.">
			Web Market
		</span>,
		'_',
		<Check />,
		<Check />,
		<Check />,
	],
	[
		<span data-tip="Have any data record that was not added or refreshed by your last inventory data file upload be removed from your account immediately after uploading a new inventory data file, regardless of the record’s time-stamp or its location’s expiration period setting.">
			Auto-Delete Unrefreshed Records
		</span>,
		'_',
		<Check />,
		<Check />,
		<Check />,
	],
	[
		<span data-tip="Manually and automatically export other members’ contact information, your own user records and community IDs. (Availability of this data in your company’s IT infrastructure, such as in your ERP system or intranet, is required to enable Industahub’s API functionality.)">
			Advanced Data Exports
		</span>,
		'_',
		'_',
		<Check />,
		<Check />,
	],
	[
		<span data-tip="From within your company’s IT infrastructure (such as your ERP system or intranet), perform “live” inventory searches on the Industahub database, contact sellers via email, and send out BROADCAST REQUEST inquiries to other Industahub members.">
			Application Program Interface Capability (APIs)
		</span>,
		'_',
		'_',
		<Check />,
		<Check />,
	],
	[
		<span data-tip="Upon completing a search in SEARCH or in MULTI-SEARCH, the distance between buyer and seller is calculated and displayed in the search results table, to identify the closest available inventory.">
			Distance To Seller Displayed
		</span>,
		'_',
		'_',
		<Check />,
		<Check />,
	],
	[
		<span data-tip="Create custom browser bookmarks and links on your company's intranet that give your employees 'one-click' access to Industahub. This feature eliminates the need to manage an individual user account for each employee, and increases the security of your company's access to Industahub.">
			Portal Access
		</span>,
		'_',
		'_',
		<Check />,
		'_',
	],
];
