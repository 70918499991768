import React from 'react';
import { get } from 'lodash';
import { bool, shape, arrayOf, any, func } from 'prop-types';
import Suggestion from '../suggestions';
import Loader from '../loader';
import { peopleFacets } from '../../utils';

const Span = ({ children, align = 'left', excerpt = true, ...rest }) => (
	<span
		className={excerpt ? 'excerpt' : ''}
		style={{ flex: '1 1 0px', textAlign: align }}
		title={children}
		{...rest}
	>
		{children}
	</span>
);

const Title = ({ children }) => (
	<div
		style={{
			fontWeight: 'bold',
			width: '100%',
			padding: '3px 10px',
			pointerEvents: 'none',
			backgroundColor: 'rgb(238, 238, 238)',
		}}
	>
		{children}
	</div>
);

function SearchDropdown({
	loading,
	fetchIndexLoading,
	rawData,
	popularSearches,
	onCompanySelected,
	onPersonSelected,
	onAssetsSelected,
	handlePopularSearch,
	peopleData = [],
	companiesData = [],
	assetsData = [],
	downshiftProps: { getItemProps, highlightedIndex },
}) {
	return (
		<div
			style={{
				position: 'absolute',
				background: '#fff',
				color: '#424242',
				fontSize: '0.9rem',
				border: '1px solid #ddd',
				borderRadius: 2,
				marginTop: 0,
				width: '100%',
				zIndex: 1000,
				minHeight: 200,
				overflow: 'auto',
				maxHeight: '80vh',
			}}
		>
			{loading || fetchIndexLoading ? (
				<Loader />
			) : (
				<div>
					{get(rawData, 'hits.hits', [])
						.slice(0, 5)
						.map((suggestion, index) => (
							<div
								role="button"
								tabIndex={0}
								onKeyDown={e => e.preventDefault()}
								style={{
									background: index === highlightedIndex ? '#eee' : 'transparent',
									cursor: 'pointer',
								}}
								key={suggestion._id}
								onClick={e => {
									e.preventDefault();

									// window.location.href = '/search?q=' + JSON.stringify(suggestion._source['P21_space_Part_space_#'] || suggestion._source["Vendor_space_Part#"]);
								}}
								{...getItemProps({
									item: {
										value:
											String(suggestion._source['P21_space_Part_space_#']) ||
											String(suggestion._source['Vendor_space_Part#']),
										_click_id: index,
									},
								})}
							>
								<Suggestion showProfileLabel suggestion={suggestion} />
							</div>
						))}
					{companiesData.length > 0 ? <Title>Company Data</Title> : null}
					{companiesData.map(item => (
						<div
							role="button"
							tabIndex={0}
							onKeyDown={e => e.preventDefault()}
							key={item._id}
							className="popularItem"
							onClick={() => onCompanySelected(item._source.name)}
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								cursor: 'pointer',
							}}
						>
							<Span>{item._source.name}</Span>
							<Span align="center">{item._source['Address 1']}</Span>
							<Span align="right">{item._source['Address 1: City']}</Span>
						</div>
					))}
					{peopleData.length > 0 ? <Title>People Data</Title> : null}
					{peopleData.map(item => (
						<div
							role="button"
							tabIndex={0}
							onKeyDown={e => e.preventDefault()}
							key={item._id}
							className="popularItem"
							onClick={() => onPersonSelected(item._source.fullname)}
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								cursor: 'pointer',
							}}
						>
							<Span>{item._source.fullname}</Span>
							<Span align="center">{item._source['Address 1']}</Span>
							<Span align="right">{item._source['Address 1: City']}</Span>
						</div>
					))}

					{assetsData.length > 0 ? <Title>Assets</Title> : null}
					{assetsData.map(item => (
						<div
							role="button"
							tabIndex={0}
							onKeyDown={e => e.preventDefault()}
							key={item.key}
							className="popularItem"
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								cursor: 'pointer',
							}}
						>
							<Span onClick={() => onAssetsSelected({ Equipment: item._source.Equipment })}>
								{item._source.Equipment}
							</Span>
							<Span align="center" onClick={() => onAssetsSelected({ Make: item._source.Make })}>
								{item._source.Make}
							</Span>
							<Span align="right" onClick={() => onAssetsSelected({ Model: item._source.Model })}>
								{item._source.Model}
							</Span>
						</div>
					))}

					{popularSearches.length > 0 ? <Title>Popular Searches</Title> : null}
					{popularSearches.map(item => (
						<div
							role="button"
							tabIndex={0}
							onKeyDown={e => e.preventDefault()}
							key={item.key}
							className="popularItem"
							onClick={() => handlePopularSearch(item.key)}
						>
							{item.key}
						</div>
					))}
				</div>
			)}
		</div>
	);
}
SearchDropdown.propTypes = {
	loading: bool.isRequired,
	// eslint-disable-next-line react/require-default-props
	rawData: shape({
		hits: shape({
			hits: arrayOf(shape({})),
		}),
	}),
	popularSearches: arrayOf(shape({})).isRequired,
	handlePopularSearch: func.isRequired,
	onCompanySelected: func.isRequired,
	onPersonSelected: func.isRequired,
	onAssetsSelected: func.isRequired,

	downshiftProps: shape({
		isOpen: bool,
		getItemProps: func,
		highlightedIndex: any,
	}).isRequired,
	peopleData: arrayOf(shape({})).isRequired,
	companiesData: arrayOf(shape({})).isRequired,
	assetsData: arrayOf(shape({})).isRequired,
	fetchIndexLoading: bool.isRequired,
};

export default SearchDropdown;
