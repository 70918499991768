import { combineReducers } from 'redux';
import sidebarReducer from './sidebar';
import facets from './facets';
import user from './user';
import category from './category';
import cart from './cart';
import orders from './order';
import subview from './subview';

const rootReducer = combineReducers({
	sidebar: sidebarReducer,
	facets,
	user,
	category,
	cart,
	subview,
	orders,
});

export default rootReducer;
