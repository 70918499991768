import React from 'react';
import { getTheme } from '../components/theme-controller';
import { connect } from 'react-redux';
import get from 'lodash/get';

const Icon = ({ primaryColor, secondaryColor, accentColor, dispatch, ...props }) => {
	const theme = getTheme(primaryColor, secondaryColor, accentColor);
	return (
		<svg
			width="14px"
			height="14px"
			viewBox="0 0 14 14"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>2B4B0A1D-2589-4C86-A080-A2EADE8CB053@3x</title>
			<desc>Created with sketchtool.</desc>
			<g
				id="Page-1"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
				opacity="0.452450707"
			>
				<g
					id="Icon-Set"
					transform="translate(-309.000000, -99.000000)"
					fill={theme.palette.secondary.main}
					fillRule="nonzero"
				>
					<g id="Group-2" transform="translate(309.000000, 99.000000)">
						<rect id="Rectangle" x="0" y="0" width="6.36363636" height="6.36363636"></rect>
						<rect
							id="Rectangle-Copy-3"
							x="0"
							y="7.63636364"
							width="6.36363636"
							height="6.36363636"
						></rect>
						<rect
							id="Rectangle-Copy-2"
							x="7.63636364"
							y="0"
							width="6.36363636"
							height="6.36363636"
						></rect>
						<rect
							id="Rectangle-Copy-4"
							x="7.63636364"
							y="7.63636364"
							width="6.36363636"
							height="6.36363636"
						></rect>
					</g>
				</g>
			</g>
		</svg>
	);
};

const mapStateToProps = state => ({
	primaryColor: get(state, 'user.user.preferences.color_scheme.primary'),
	secondaryColor: get(state, 'user.user.preferences.color_scheme.secondary'),
	accentColor: get(state, 'user.user.preferences.color_scheme.accent'),
});

export default connect(mapStateToProps)(Icon);
