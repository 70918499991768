import React from 'react';
import { ReactiveBase, MultiList } from '@appbaseio/reactivesearch';
import { ASSET_INDEX } from '../utils';
import { CLUSTER_URL, CLUSTER_CREDENTIALS } from '../constants/config';

function AssetsReactiveBase({ children }) {
	console.log('AssetsReactiveBase');
	return (
		<ReactiveBase
			app={ASSET_INDEX}
			url={CLUSTER_URL}
			credentials={CLUSTER_CREDENTIALS}
			appbaseConfig={{
				recordAnalytics: true,
				suggestionAnalytics: true,
			}}
			enableAppbase
		>
			{children}
		</ReactiveBase>
	);
}

export default AssetsReactiveBase;
