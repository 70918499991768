import { FACETS } from '../constants';

const facets = (state = [], action) => {
	switch (action.type) {
		case FACETS.ADD_FACETS:
			const facet = action.filter;
			return state.indexOf(facet) > -1 ? state : [...state, action.filter];
		case FACETS.REMOVE_FACETS:
			return state.filter(item => item !== action.filter);
		default:
			return state;
	}
};

export default facets;
