import React from 'react';
import { ReactiveList, DataSearch } from '@appbaseio/reactivesearch';
import ReactTable from 'react-table';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import StartIcon from 'react-icons/lib/md/stars';
import get from 'lodash/get';
import { css } from 'react-emotion';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';

import { normalizeProductData } from '../../components/add-cart';
import Loader from '../../components/loader';
import ErrorPopup from '../../components/error-popup';
import { addFacets, removeFacet, addProduct, updateProduct } from '../../actions';
import componentIds from '../../constants/componentIds';
import { getTheme } from '../../components/theme-controller';
import { getColorsFromRGBA } from '../../utils';
import ResultStats from '../../components/result-stats';
import { getComponentReactDependency } from '../../constants/facets';

const cellClass = css`
	display: flex;
	flex-direction: column;
	vertical-align: middle;
	justify-content: center;
	font-size: 0.9rem;
`;

class Tab3 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectAll: false,
			selected: [],
		};
		const theme = getTheme(props.primaryColor, props.secondaryColor, props.accentColor);
		this.columns = [
			{
				id: 'Select',
				Header: original => {
					return (
						<input
							type="checkbox"
							onChange={() => this.selectAllData(original.data)}
							name="select-all"
							id="select-all"
							checked={this.state.selectAll}
						/>
					);
				},
				accessor: item => item['P21_space_Part_space_#'],
				Cell: row =>
					this.state.selectAll ? (
						<input type="checkbox" checked disabled />
					) : (
						<input
							type="checkbox"
							checked={this.state.selected.filter(item => item._id === row.original._id).length > 0}
							disabled={this.state.selectAll}
							onClick={() => this.selectData(row)}
							value={row.value}
						/>
					),
				width: 50,
			},
			{
				title: 'Action',
				id: 'ProductLink',
				Header: '',
				accessor: item => item._id,
				Cell: row => (
					<div className="tabular-button-container">
						<Button
							size="small"
							variant="outlined"
							color="secondary"
							onClick={() => {
								const itemCart = this.isItemPresent(row.original);
								if (itemCart) {
									props.updateProductQuantity({ ...itemCart, quantity: itemCart.quantity + 1 });
								} else {
									props.addToCart(normalizeProductData(row.original));
								}
							}}
						>
							Add <AddShoppingCartIcon style={{ fontSize: '1rem' }} />
						</Button>
					</div>
				),
				width: 100,
			},
			{
				id: 'P21_space_Part_space_#_tab_3',
				accessor: item => item._id,
				Header: () => (
					<div>
						<DataSearch
							className="tab-searchbox"
							componentId="P21_space_Part_space_#_tab_3"
							dataField={['P21_space_Part_space_#', 'P21_space_Part_space_#.search']}
							fieldWeights={[3, 1]}
							autosuggest={false}
							iconPosition="left"
							queryFormat="and"
							placeholder="Part Numbers"
							filterLabel="Part Numbers"
							showIcon={false}
							innerClass={{ input: 'searchinput' }}
							onValueChange={value => this.handleValueChange(value, 'P21_space_Part_space_#')}
						/>
					</div>
				),
				Cell: row => {
					const val =
						row.original['P21_space_Part_space_#.search'] ||
						row.original['P21_space_Part_space_#'] ||
						'Unknown';
					return (
						<div>
							{row.original._promoted && (
								<span
									className={css`
										margin-right: 10px;
										font-size: 20px;
										color: ${theme.palette.secondary.main};
									`}
								>
									<StartIcon />
								</span>
							)}
							<Button size="small" onClick={() => this.props.history.push(`/product/${row.value}`)}>
								{val}
							</Button>
						</div>
					);
				},
				maxWidth: 250,
			},
			{
				id: 'Application',
				Header: () => (
					<div>
						<DataSearch
							className="tab-searchbox"
							componentId="Application"
							dataField={['Application']}
							autosuggest={false}
							iconPosition="left"
							queryFormat="and"
							placeholder="Application"
							filterLabel="Application"
							showIcon={false}
							innerClass={{ input: 'searchinput' }}
							onValueChange={value => this.handleValueChange(value, 'Application')}
						/>
					</div>
				),
				accessor: item => item.Application,
				Cell: row => <div dangerouslySetInnerHTML={{ __html: row.value }} />,
				minWidth: 200,
				maxWidth: 300,
				className: cellClass,
			},
			{
				id: 'Fluid_space_Type',
				Header: () => (
					<div>
						<DataSearch
							className="tab-searchbox"
							componentId="Fluid_space_Type"
							dataField={['Fluid_space_Type']}
							autosuggest={false}
							iconPosition="left"
							queryFormat="and"
							placeholder="Fluid Type"
							filterLabel="Fluid Type"
							showIcon={false}
							innerClass={{ input: 'searchinput' }}
							onValueChange={value => this.handleValueChange(value, 'Fluid_space_Type')}
						/>
					</div>
				),
				accessor: item => item['Fluid_space_Type'],
				Cell: row => <div dangerouslySetInnerHTML={{ __html: row.value }} />,
				minWidth: 200,
				maxWidth: 300,
				className: cellClass,
			},
			{
				id: 'Material Compatibility',
				Header: () => (
					<div>
						<DataSearch
							className="tab-searchbox"
							componentId="Material Compatibility"
							dataField={['Material_space_#1']}
							autosuggest={false}
							iconPosition="left"
							queryFormat="and"
							placeholder="Material Compatibility"
							filterLabel="Material Compatibility"
							showIcon={false}
							innerClass={{ input: 'searchinput' }}
							onValueChange={value => this.handleValueChange(value, 'Material_space_#1')}
						/>
					</div>
				),
				accessor: item => item['Material_space_#1'],
				Cell: row => <div dangerouslySetInnerHTML={{ __html: row.value }} />,
				minWidth: 200,
				maxWidth: 300,
				className: cellClass,
			},
			{
				id: 'Color',
				Header: () => (
					<div>
						<DataSearch
							className="tab-searchbox"
							componentId="Color"
							dataField={['Color_space_#1']}
							autosuggest={false}
							iconPosition="left"
							queryFormat="and"
							placeholder="Color"
							filterLabel="Color"
							showIcon={false}
							innerClass={{ input: 'searchinput' }}
							onValueChange={value => this.handleValueChange(value, 'Color_space_#1')}
						/>
					</div>
				),
				accessor: item => item['Color_space_#1'],
				Cell: row => <div dangerouslySetInnerHTML={{ __html: row.value }} />,
				minWidth: 200,
				maxWidth: 300,
				className: cellClass,
			},
			{
				id: 'Temperature',
				Header: () => (
					<div>
						<DataSearch
							className="tab-searchbox"
							componentId="Temperature"
							dataField={['Temperature_space_+C']}
							autosuggest={false}
							iconPosition="left"
							queryFormat="and"
							placeholder="Temperature"
							filterLabel="Temperature"
							showIcon={false}
							innerClass={{ input: 'searchinput' }}
							onValueChange={value => this.handleValueChange(value, 'Temperature')}
						/>
					</div>
				),
				accessor: item => item['Temperature_space_+C'],
				Cell: row => <div dangerouslySetInnerHTML={{ __html: row.value }} />,
				minWidth: 200,
				maxWidth: 300,
				className: cellClass,
			},
			{
				id: 'Compound',
				Header: () => (
					<div>
						<DataSearch
							className="tab-searchbox"
							componentId="Compound"
							dataField={['Material_space_#1']}
							autosuggest={false}
							iconPosition="left"
							queryFormat="and"
							placeholder="Compound"
							filterLabel="Compound"
							showIcon={false}
							innerClass={{ input: 'searchinput' }}
							onValueChange={value => this.handleValueChange(value, 'Compound')}
						/>
					</div>
				),
				accessor: item => item['Compound'],
				Cell: row => <div dangerouslySetInnerHTML={{ __html: row.value }} />,
				minWidth: 200,
				maxWidth: 300,
				className: cellClass,
			},
			{
				id: 'Durometer',
				Header: () => (
					<div>
						<DataSearch
							className="tab-searchbox"
							componentId="Durometer"
							dataField={['Durometer']}
							autosuggest={false}
							iconPosition="left"
							queryFormat="and"
							placeholder="Durometer"
							filterLabel="Durometer"
							showIcon={false}
							innerClass={{ input: 'searchinput' }}
							onValueChange={value => this.handleValueChange(value, 'Durometer')}
						/>
					</div>
				),
				accessor: item => item['Durometer'],
				Cell: row => <div dangerouslySetInnerHTML={{ __html: row.value }} />,
				minWidth: 200,
				maxWidth: 300,
				className: cellClass,
			},
			{
				id: 'Pressure',
				Header: () => (
					<div>
						<DataSearch
							className="tab-searchbox"
							componentId="Pressure"
							dataField={['Pressure_space__open_PSI_close_']}
							autosuggest={false}
							iconPosition="left"
							queryFormat="and"
							placeholder="Pressure"
							filterLabel="Pressure"
							showIcon={false}
							innerClass={{ input: 'searchinput' }}
							onValueChange={value => this.handleValueChange(value, 'Pressure')}
						/>
					</div>
				),
				accessor: item => item['Pressure_space__open_PSI_close_'],
				Cell: row => <div dangerouslySetInnerHTML={{ __html: row.value }} />,
				minWidth: 200,
				maxWidth: 300,
				className: cellClass,
			},
		];

		this.ids = [];
		this.columns.forEach(item => item.id && this.ids.push(item.id));
	}

	shouldComponentUpdate(nextProps, nextState) {
		const {
			cart,
			secondaryColor,
			primaryColor,
			accentColor,
			pageSize,
			dynamic_facets,
		} = this.props;
		const { selected } = this.state;
		if (
			JSON.stringify(nextProps.cart) !== JSON.stringify(cart) ||
			JSON.stringify(nextProps.accentColor) !== JSON.stringify(accentColor) ||
			JSON.stringify(nextProps.primaryColor) !== JSON.stringify(primaryColor) ||
			JSON.stringify(nextProps.secondaryColor) !== JSON.stringify(secondaryColor) ||
			JSON.stringify(nextProps.pageSize) !== JSON.stringify(pageSize) ||
			JSON.stringify(nextProps.dynamic_facets) !== JSON.stringify(dynamic_facets) ||
			JSON.stringify(nextState.selected) !== JSON.stringify(selected)
		) {
			return true;
		}

		return false;
	}

	componentWillUnmount() {
		this.ids.forEach(item => {
			this.props.onRemoveQuery(item);
		});
	}

	isItemPresent = result => {
		const { cart } = this.props;

		const item = cart.find(item => item.id === result._id && item.type === 'item');

		return item;
	};

	handleValueChange = (value, componentId) => {
		if (value && value.trim()) {
			this.props.onAddQuery(componentId);
		} else {
			this.props.onRemoveQuery(componentId);
		}
	};

	selectData = data => {
		const { selected } = this.state;
		const currentItem = data;
		const isPresent = selected.find(item => item._id === currentItem.original._id);
		let selectedItems = [currentItem.original, ...selected];

		if (isPresent) {
			selectedItems = selected.filter(item => item._id !== currentItem.original._id);
		}
		this.setState({
			selected: selectedItems,
		});
	};

	selectAllData = data => {
		const { selectAll, selected } = this.state;
		let finalRes = [...selected];
		if (!selectAll) {
			data.forEach(item => {
				const isPresent = selected.find(i => i._id === item._original._id);
				if (!isPresent) {
					finalRes.push(item._original);
				}
			});
		} else {
			finalRes = [];
		}
		this.setState(prevValue => ({
			selectAll: !selectAll,
			selected: finalRes,
		}));
	};

	deselectAll = () => {
		this.setState({
			selectAll: false,
		});
	};

	handleAddToCart = () => {
		const { selected } = this.state;
		const { addToCart } = this.props;

		selected.map(item => {
			addToCart(normalizeProductData(item));
		});
		this.setState({
			selected: [],
			selectAll: false,
		});
	};

	render() {
		const { selected, selectAll } = this.state;
		const { pageSize, handleViewUpdate, dynamic_facets } = this.props;
		const userFacets = dynamic_facets.map(facet => facet.id);
		const reactList = {
			...getComponentReactDependency('tab_view'),
			and: [
				...new Set([...userFacets, ...this.ids, ...getComponentReactDependency('tab_view').and]),
			],
		};

		const theme = getTheme(
			this.props.primaryColor,
			this.props.secondaryColor,
			this.props.accentColor,
		);
		const colors = getColorsFromRGBA(theme.palette.secondary.light);
		return (
			<React.Fragment>
				{(Boolean(selected.length) || selectAll) && (
					<Button
						variant="contained"
						color="primary"
						className="inverse-button"
						onClick={this.handleAddToCart}
					>
						Add {selected.length} to cart
					</Button>
				)}
				<ReactiveList
					key={`tab3-${pageSize}`}
					componentId="tab_view_3"
					dataField="_score"
					react={reactList}
					defaultQuery={() => ({
						track_total_hits: true,
					})}
					onData={handleViewUpdate}
					pagination={false}
					scrollTarget="result-set"
					sortBy="desc"
					size={pageSize}
					renderError={error => {
						return <ErrorPopup error={error} />;
					}}
					loader={<Loader />}
					render={({ data, triggerAnalytics }) => {
						console.log('data', data);
						return (
							<ReactTable
								data={[...data]}
								columns={this.columns}
								showPagination={false}
								minRows={3}
								pageSize={data.length}
								className="-striped"
								sortable={false}
								getTrProps={(_, rowInfo) => {
									return {
										style: {
											backgroundColor: get(rowInfo, 'original._promoted')
												? `rgba(${colors[0]}, ${colors[1]}, ${colors[2]}, 0.08)`
												: 'inherit',
										},
										onClick: () => {
											triggerAnalytics(rowInfo.original._click_id);
										},
									};
								}}
								noDataText="We couldn't find any results. Try another search criteria."
							/>
						);
					}}
					renderResultStats={stats => <ResultStats {...stats} />}
					className="result-data"
					innerClass={{
						listItem: 'result-item',
						title: 'result-item-title',
						resultStats: 'result-stats',
						poweredBy: 'hide',
					}}
					showResultStats={true}
				/>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	primaryColor: get(state, 'user.user.preferences.color_scheme.primary'),
	secondaryColor: get(state, 'user.user.preferences.color_scheme.secondary'),
	accentColor: get(state, 'user.user.preferences.color_scheme.accent'),
	cart: get(state, 'cart', []),
	dynamic_facets: get(state, 'user.dynamic_facets', []),
});

const mapDispatchToProps = dispatch => ({
	onAddQuery: componentId => dispatch(addFacets(componentId)),
	onRemoveQuery: componentId => dispatch(removeFacet(componentId)),
	addToCart: product => dispatch(addProduct(product)),
	updateProductQuantity: product => dispatch(updateProduct(product)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tab3));
