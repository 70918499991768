export const getPrice = items => {
	return items.reduce((agg, item) => {
		if (item.type === 'child_kit') {
			return agg + getPrice(item.children);
		}
		return agg + item.price * item.quantity;
	}, 0);
};

export const getMonth = month => {
	console.log(month);
	switch (month) {
		case 1:
			return 'JAN';
		case 2:
			return 'FEB';
		case 3:
			return 'MAR';
		case 4:
			return 'APR';
		case 5:
			return 'MAY';
		case 6:
			return 'JUNE';
		case 7:
			return 'JULY';
		case 8:
			return 'AUG';
		case 9:
			return 'SEPT';
		case 10:
			return 'OCT';
		case 11:
			return 'NOV';
		case 12:
			return 'DEC';
		default:
			return 'NA';
	}
};
