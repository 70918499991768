import React from 'react';
import { connect } from 'react-redux';
import FilterIcon from 'react-icons/lib/fa/filter';
import CloseIcon from 'react-icons/lib/fa/close';
import Bars from 'react-icons/lib/fa/bars';
import Button from '@material-ui/core/Button';
import isMobile from 'ismobilejs';
import get from 'lodash/get';
import { Fab, Tooltip } from '@material-ui/core';

import { getTheme } from '../theme-controller';
import {
	toggleHomeLeftSidebar,
	updateHomeLeftSidebar,
	toggleHomeRightSidebar,
} from '../../actions';
import { leftSidebarContent, rightSidebarContent } from '../../constants/sidebar';
import Facets from './content/Facets';
import Cart from './content/Cart';
import Profile from './content/Profile';
import { whitelistedUsers, ADMIN_URL, pagesWithSearch } from '../../constants/config';

import './styles.css';
import FacetsIcon from './content/FacetsIcon';

class SideBar extends React.Component {
	state = { selectedSidebarMenu: '' };

	handleFilterClick = () => {
		const {
			isLeftSidebar,
			updateView,
			handleLeftSidebar,
			handleRightSidebar,
			direction,
		} = this.props;

		if (isLeftSidebar) {
			handleLeftSidebar();
		} else {
			updateView(leftSidebarContent.Facets);
		}
	};

	handleToggle = view => {
		const { updateView, currentView, handleLeftSidebar } = this.props;

		if (view !== currentView) {
			updateView(view);
		} else {
			handleLeftSidebar();
		}
	};

	handleMenuClick = clickedMenu => {
		this.setState({
			selectedSidebarMenu: clickedMenu,
		});
		this.handleToggle(leftSidebarContent.Facets);
	};

	renderView = () => {
		const { currentView, userEmail, dynamic_facets, token, isLeftSidebar, direction } = this.props;
		const { selectedSidebarMenu } = this.state;
		const isAdmin = whitelistedUsers.includes(userEmail);
		switch (currentView) {
			case rightSidebarContent.Cart:
				return <Cart />;
			case rightSidebarContent.Profile:
				return <Profile />;
			default: {
				// If we return null on default the facets would unmount and the filters applied would be gone on collapsing the sidebar.
				return (
					<React.Fragment>
						<div style={isLeftSidebar ? {} : { width: 0, display: 'none' }}>
							<Facets selectedSidebarMenu={selectedSidebarMenu} dynamic_facets={dynamic_facets} />
							{isAdmin && (
								<div className="admin-btn">
									<Button
										onClick={() => window.open(`${ADMIN_URL}&auth_token=${token}`, '_blank')}
										color="secondary"
										variant="flat"
									>
										Admin Center
									</Button>
								</div>
							)}
						</div>

						{!isLeftSidebar && direction === 'left' ? (
							<FacetsIcon handleMenuClick={this.handleMenuClick} />
						) : null}
					</React.Fragment>
				);
			}
		}
	};

	render() {
		const { isSidebar, primaryColor, secondaryColor, direction, isLeftSidebar } = this.props;
		const theme = getTheme(primaryColor, secondaryColor);
		const sidebarClass = isSidebar ? 'leftSidebarOpen' : 'leftSidebarClose';
		const wrapperClass = isSidebar ? 'sidebar-wrapper' : '';
		const homeRightSidebarStyle = isSidebar && direction === 'right' ? { width: 320 } : {};
		const isMobileSidebar = isMobile.any;
		const showFacets = pagesWithSearch.includes(window.location.pathname);

		const { textColor } = theme;
		return (
			<div
				className={`${wrapperClass} ${
					!isMobileSidebar && !isLeftSidebar && direction === 'left' ? 'facets-sidebar-wrapper' : ''
				}`}
				style={{
					background: theme.palette.background.default,
					color: textColor,
					...homeRightSidebarStyle,
				}}
			>
				{direction === 'left' && (
					<div className="collapse-button">
						<Tooltip title={isSidebar ? 'Hide Facets' : 'Show Facets'}>
							<Bars onClick={() => this.handleToggle(leftSidebarContent.Facets)} />
						</Tooltip>
					</div>
				)}

				<div
					className={`${isMobileSidebar && !isSidebar ? `hide` : ``} leftSidebar ${
						direction === 'left' ? 'facets-sidebar' : ''
					} ${sidebarClass}`}
					style={{
						backgroundColor: theme.palette.background.default,
						color: textColor,
						...homeRightSidebarStyle,
					}}
				>
					{this.renderView()}
				</div>
				{(showFacets || isLeftSidebar) && (
					<Fab className="filter-floating-icon" onClick={this.handleFilterClick}>
						{isLeftSidebar ? <CloseIcon /> : <FilterIcon />}
					</Fab>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state, props) => ({
	isSidebar: get(state, `sidebar.home.${props.direction}.open`, false),
	isLeftSidebar: get(state, `sidebar.home.left.open`, false),
	currentView: get(state, `sidebar.home.${props.direction}.view`),
	searchFacets: state.facets,
	primaryColor: get(state, 'user.user.preferences.color_scheme.primary'),
	secondaryColor: get(state, 'user.user.preferences.color_scheme.secondary'),
	token: get(state, 'user.user.jwt_token'),
	dynamic_facets: get(state, 'user.dynamic_facets', []),
	userEmail: get(state, 'user.user.email', ''),
});

const mapDispatchToProps = dispatch => ({
	handleLeftSidebar: () => dispatch(toggleHomeLeftSidebar()),
	handleRightSidebar: () => dispatch(toggleHomeRightSidebar()),
	updateView: view => dispatch(updateHomeLeftSidebar(view)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
