import React from 'react';
import { ReactiveList, DataSearch } from '@appbaseio/reactivesearch';
import ReactTable from 'react-table';
import Button from '@material-ui/core/Button';
import StartIcon from 'react-icons/lib/md/stars';
import get from 'lodash/get';
import { css } from 'react-emotion';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';

import ErrorPopup from '../../components/error-popup';
import { normalizeProductData } from '../../components/add-cart';
import Loader from '../../components/loader';
import { getTheme } from '../../components/theme-controller';
import { getColorsFromRGBA } from '../../utils';
import { addFacets, removeFacet, addProduct, updateProduct } from '../../actions';
import componentIds from '../../constants/componentIds';
import ResultStats from '../../components/result-stats';
import { getComponentReactDependency } from '../../constants/facets';

import dummy_image from '../../image/dummy_image.png';

const cellClass = css`
	display: flex;
	flex-direction: column;
	vertical-align: middle;
	justify-content: center;
	font-size: 0.9rem;
`;

class Tab1 extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selectAll: false,
			selected: [],
		};
		const theme = getTheme(props.primaryColor, props.secondaryColor, props.accentColor);
		this.columns = [
			{
				id: 'Select',
				Header: original => {
					return (
						<input
							type="checkbox"
							onChange={() => this.selectAllData(original.data)}
							name="select-all"
							id="select-all"
							checked={this.state.selectAll}
						/>
					);
				},
				accessor: item => item['P21_space_Part_space_#'],
				Cell: row => (
					<div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
						{this.state.selectAll ? (
							<input type="checkbox" checked disabled />
						) : (
							<input
								type="checkbox"
								checked={
									this.state.selected.filter(item => item._id === row.original._id).length > 0
								}
								disabled={this.state.selectAll}
								onClick={() => this.selectData(row)}
								value={row.value}
							/>
						)}
					</div>
				),
				width: 50,
			},
			{
				title: 'Action',
				id: 'ProductLink',
				Header: '',
				accessor: item => item._id,
				Cell: row => (
					<div
						className="tabular-button-container"
						style={{ display: 'flex', alignItems: 'center', height: '100%' }}
					>
						<Button
							size="small"
							variant="outlined"
							color="secondary"
							onClick={() => {
								const itemCart = this.isItemPresent(row.original);
								if (itemCart) {
									props.updateProductQuantity({ ...itemCart, quantity: itemCart.quantity + 1 });
								} else {
									props.addToCart(normalizeProductData(row.original));
								}
							}}
						>
							Add <AddShoppingCartIcon style={{ fontSize: '1rem' }} />
						</Button>
					</div>
				),
				width: 100,
			},
			{
				title: 'Image',
				id: 'img',
				Header: '',
				accessor: item => item._id,
				Cell: row => (
					<div className="tabular-button-container" style={{ maxHeight: 46 }}>
						<img
							alt="Dummy"
							style={{ objectFit: 'contain', maxWidth: '100%', maxHeight: 36 }}
							src={dummy_image}
						/>
					</div>
				),
				width: 100,
			},
			{
				id: 'P21_space_Part_space_#_tab_1',
				accessor: item => item._id,
				Header: () => (
					<div>
						<DataSearch
							className="tab-searchbox"
							componentId="P21_space_Part_space_#_tab_1"
							dataField={['P21_space_Part_space_#', 'P21_space_Part_space_#.search']}
							fieldWeights={[3, 1]}
							autosuggest={false}
							iconPosition="left"
							queryFormat="and"
							placeholder="Part Numbers"
							filterLabel="Part Numbers"
							showIcon={false}
							highlight={false}
							URLParams
							innerClass={{ input: 'searchinput' }}
							onValueChange={value => this.handleValueChange(value, 'P21_space_Part_space_#_tab_1')}
						/>
					</div>
				),
				Cell: row => {
					const val =
						row.original['P21_space_Part_space_#.search'] ||
						row.original['P21_space_Part_space_#'] ||
						'Unknown';
					return (
						<div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
							{row.original._promoted && (
								<span
									className={css`
										margin-right: 10px;
										font-size: 20px;
										color: ${theme.palette.secondary.main};
									`}
								>
									<StartIcon />
								</span>
							)}
							<Button size="small" onClick={() => this.props.history.push(`/product/${row.value}`)}>
								{val}
							</Button>
						</div>
					);
				},
				minWidth: 150,
				maxWidth: 300,
			},
			{
				id: 'Description_tab_1',
				Header: () => (
					<div>
						<DataSearch
							className="tab-searchbox"
							componentId="Description_tab_1"
							dataField={['Description', 'Description.search']}
							autosuggest={false}
							iconPosition="left"
							queryFormat="and"
							placeholder="Description"
							filterLabel="Description"
							showIcon={false}
							innerClass={{ input: 'searchinput' }}
							onValueChange={value => this.handleValueChange(value, 'Description')}
						/>
					</div>
				),
				accessor: item => item['Description'],
				Cell: row => <div dangerouslySetInnerHTML={{ __html: row.value }} />,
				minWidth: 200,
				maxWidth: 300,
				className: cellClass,
			},
			{
				id: 'List_Price_tab_1',
				Header: () => (
					<div>
						<DataSearch
							className="tab-searchbox"
							componentId="List_Price_tab_1"
							dataField={['List_space_Price']}
							autosuggest={false}
							iconPosition="left"
							queryFormat="and"
							placeholder="List Price"
							filterLabel="List Price"
							showIcon={false}
							innerClass={{ input: 'searchinput' }}
							onValueChange={value => this.handleValueChange(value, 'List_space_Price')}
						/>
					</div>
				),
				accessor: item => item['List_space_Price'],
				Cell: row => <div dangerouslySetInnerHTML={{ __html: row.value }} />,
				maxWidth: 250,
				className: cellClass,
			},
			{
				id: 'Lead_space_Time_tab_1',
				Header: () => (
					<div>
						<DataSearch
							className="tab-searchbox"
							componentId="Lead_space_Time_tab_1"
							dataField={['Lead_space_Time']}
							autosuggest={false}
							iconPosition="left"
							queryFormat="and"
							placeholder="Lead Time"
							filterLabel="Lead Time"
							showIcon={false}
							innerClass={{ input: 'searchinput' }}
							onValueChange={value => this.handleValueChange(value, 'Lead_space_Time')}
						/>
					</div>
				),
				accessor: item => item['Lead_space_Time'],
				Cell: row => <div dangerouslySetInnerHTML={{ __html: row.value }} />,
				maxWidth: 250,
				className: cellClass,
			},
			{
				id: 'Currency_tab_1',
				Header: () => (
					<div>
						<DataSearch
							className="tab-searchbox"
							componentId="Currency_tab_1"
							dataField={['Sale_space_-_space_Currency']}
							autosuggest={false}
							iconPosition="left"
							queryFormat="and"
							placeholder="Currency"
							filterLabel="Currency"
							showIcon={false}
							innerClass={{ input: 'searchinput' }}
							onValueChange={value => this.handleValueChange(value, 'Currency')}
						/>
					</div>
				),
				accessor: item => item['Sale_space_-_space_Currency'],
				Cell: row => <div dangerouslySetInnerHTML={{ __html: row.value }} />,
				maxWidth: 250,
				className: cellClass,
			},
		];

		this.ids = [];
		this.columns.forEach(item => item.id && this.ids.push(item.id));
	}

	shouldComponentUpdate(nextProps, nextState) {
		const {
			cart,
			secondaryColor,
			primaryColor,
			accentColor,
			pageSize,
			dynamic_facets,
		} = this.props;
		const { selected } = this.state;
		if (
			JSON.stringify(nextProps.cart) !== JSON.stringify(cart) ||
			JSON.stringify(nextProps.accentColor) !== JSON.stringify(accentColor) ||
			JSON.stringify(nextProps.primaryColor) !== JSON.stringify(primaryColor) ||
			JSON.stringify(nextProps.secondaryColor) !== JSON.stringify(secondaryColor) ||
			JSON.stringify(nextProps.pageSize) !== JSON.stringify(pageSize) ||
			JSON.stringify(nextProps.dynamic_facets) !== JSON.stringify(dynamic_facets) ||
			JSON.stringify(nextState.selected) !== JSON.stringify(selected)
		) {
			return true;
		}

		return false;
	}

	componentWillUnmount() {
		this.ids.forEach(item => {
			this.props.onRemoveQuery(item);
		});
	}

	isItemPresent = result => {
		const { cart } = this.props;

		const item = cart.find(item => item.id === result._id && item.type === 'item');

		return item;
	};

	handleValueChange = (value, componentId) => {
		if (value && value.trim()) {
			this.props.onAddQuery(componentId);
		} else {
			this.props.onRemoveQuery(componentId);
		}
	};

	selectData = data => {
		const { selected } = this.state;
		const currentItem = data;
		const isPresent = selected.find(item => item._id === currentItem.original._id);
		let selectedItems = [currentItem.original, ...selected];

		if (isPresent) {
			selectedItems = selected.filter(item => item._id !== currentItem.original._id);
		}
		this.setState({
			selected: selectedItems,
		});
	};

	selectAllData = data => {
		const { selectAll, selected } = this.state;
		let finalRes = [...selected];
		if (!selectAll) {
			data.forEach(item => {
				const isPresent = selected.find(i => i._id === item._original._id);
				if (!isPresent) {
					finalRes.push(item._original);
				}
			});
		} else {
			finalRes = [];
		}
		this.setState({
			selectAll: !selectAll,
			selected: finalRes,
		});
	};

	deselectAll = () => {
		this.setState({
			selectAll: false,
		});
	};

	handleAddToCart = () => {
		const { selected } = this.state;
		const { addToCart } = this.props;

		selected.forEach(item => {
			addToCart(normalizeProductData(item));
		});
		this.setState({
			selected: [],
			selectAll: false,
		});
	};

	render() {
		const { selected, selectAll } = this.state;
		const {
			pageSize,
			handleViewUpdate,
			primaryColor,
			secondaryColor,
			accentColor,
			dynamic_facets,
		} = this.props;
		const userFacets = dynamic_facets.map(facet => facet.id.replace('.keyword', ''));
		const reactList = {
			...getComponentReactDependency('tab_view'),
			and: [
				...new Set([...userFacets, ...this.ids, ...getComponentReactDependency('tab_view').and]),
			],
		};
		const theme = getTheme(primaryColor, secondaryColor, accentColor);
		const colors = getColorsFromRGBA(theme.palette.secondary.light);
		return (
			<div>
				{(Boolean(selected.length) || selectAll) && (
					<Button
						variant="contained"
						color="primary"
						className="inverse-button"
						onClick={this.handleAddToCart}
					>
						Add {selected.length} to cart
					</Button>
				)}
				<ReactiveList
					key={`tab1-${pageSize}`}
					componentId="tab_view"
					dataField="_score"
					react={reactList}
					defaultQuery={() => ({
						track_total_hits: true,
					})}
					onData={handleViewUpdate}
					sortBy="desc"
					pagination={false}
					loader={<Loader />}
					size={pageSize}
					scrollTarget="result-set"
					onPageChange={() => {
						this.deselectAll();
					}}
					renderResultStats={stats => <ResultStats {...stats} />}
					renderError={error => {
						return <ErrorPopup error={error} />;
					}}
					render={({ data, triggerAnalytics }) => {
						return (
							<ReactTable
								getTrProps={(_, rowInfo) => {
									return {
										style: {
											backgroundColor: get(rowInfo, 'original._promoted')
												? `rgba(${colors[0]}, ${colors[1]}, ${colors[2]}, 0.08)`
												: 'inherit',
										},
										onClick: () => {
											triggerAnalytics(rowInfo.original._click_id);
										},
									};
								}}
								data={[...data]}
								columns={this.columns}
								showPagination={false}
								minRows={3}
								pageSize={data.length}
								className="-striped"
								sortable={false}
								noDataText="We couldn't find any results. Try another search criteria."
							/>
						);
					}}
					className="result-data"
					innerClass={{
						listItem: 'result-item',
						title: 'result-item-title',
						resultStats: 'result-stats',
						poweredBy: 'hide',
					}}
					showResultStats
				/>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	primaryColor: get(state, 'user.user.preferences.color_scheme.primary'),
	secondaryColor: get(state, 'user.user.preferences.color_scheme.secondary'),
	accentColor: get(state, 'user.user.preferences.color_scheme.accent'),
	cart: get(state, 'cart', []),
	dynamic_facets: get(state, 'user.dynamic_facets', []),
});

const mapDispatchToProps = dispatch => ({
	onAddQuery: componentId => dispatch(addFacets(componentId)),
	addToCart: product => dispatch(addProduct(product)),
	onRemoveQuery: componentId => dispatch(removeFacet(componentId)),
	updateProductQuantity: product => dispatch(updateProduct(product)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tab1));
