import React from 'react';
import { Descriptions, Button, Tooltip, InputNumber } from 'antd';
import { connect } from 'react-redux';
import dummy_image from '../../image/dummy_image.png';
import { getTotal } from '../../utils';
import { updateProduct, removeProduct } from '../../actions';

function totalPrice(cartItem) {
	return getTotal(cartItem.children, item => Number(item.price || 0) * item.quantity);
}

const CartList = ({
	cart,
	updateProductQuantity,
	removeProductFromCart,
	isEditable = false,
	showProducts = true,
	showDelete = false,
}) => (
	<React.Fragment>
		{cart.map(item => (
			<div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
				<img src={dummy_image} alt="Dummy" style={{ width: 35, height: 35, marginRight: 5 }} />
				<Descriptions
					bordered={false}
					className="cart-info"
					key={item.id}
					size="small"
					title={
						<React.Fragment>
							<span> {item.name || item.id} </span>
							{showDelete ? (
								<Tooltip title="Remove item from cart">
									<Button
										onClick={() => removeProductFromCart(item.id)}
										ghost
										size="small"
										type="danger"
										icon="delete"
										style={{
											marginLeft: 2,
										}}
										shape="circle"
										className="delete_btn"
									/>
								</Tooltip>
							) : null}
						</React.Fragment>
					}
				>
					<Descriptions.Item label="Quantity">
						{isEditable ? (
							<InputNumber
								value={item.quantity}
								size="small"
								style={{
									width: 50,
								}}
								onChange={value =>
									updateProductQuantity({
										...item,
										quantity: value,
									})
								}
							/>
						) : (
							item.quantity
						)}
					</Descriptions.Item>
					{showProducts && item.type === 'kit' ? (
						<Descriptions.Item label="Components">{item.children.length}</Descriptions.Item>
					) : null}

					<Descriptions.Item label="Price">
						{item.type === 'kit'
							? totalPrice(item).toFixed(2)
							: (item.price * item.quantity).toFixed(2)}
					</Descriptions.Item>
				</Descriptions>
			</div>
		))}
	</React.Fragment>
);

const mapDispatchToProps = dispatch => ({
	removeProductFromCart: id => dispatch(removeProduct(id)),
	updateProductQuantity: product => dispatch(updateProduct(product)),
});

export default connect(null, mapDispatchToProps)(CartList);
