import React, { useState } from 'react';
import { Row, Col, Button, Icon } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getTotal } from '../../utils';

import './index.css';
import { getTheme } from '../../components/theme-controller';
import { updateCart as updateCartAction } from '../../actions';
import CartList from '../../components/cart-list';

function OrderRow({
	orderNo,
	cart = {},
	updateCart,
	primaryColor,
	secondaryColor,
	accentColor,
	history,
}) {
	const [visible, setVisible] = useState(false);
	const theme = getTheme(primaryColor, secondaryColor, accentColor);
	return (
		<Row>
			<Row>
				<Col span={5}>
					<Row>Order #{orderNo}</Row>
					<Row>Nov 29, 2019</Row>
				</Col>
				<Col span={4}>
					<Row>Items</Row>
					<Row>{getTotal(cart, item => Number(item.quantity))}</Row>
				</Col>
				<Col span={5}>
					<Row>Order Total</Row>
					<Row>${getTotal(cart, item => Number(item.price || 0) * item.quantity)}</Row>
				</Col>
				<Col span={5}>
					<Row>Shipped To</Row>
					<Row>Sid</Row>
				</Col>
				<Col span={5}>
					<Row>Status</Row>
					<Row>In Transit</Row>
				</Col>
			</Row>
			<Row>
				<div className="button-container">
					<Button
						onClick={() => setVisible(!visible)}
						type="primary"
						style={{
							background: theme.palette.secondary.main,
							borderColor: theme.palette.secondary.main,
							color: theme.palette.secondary.contrastText,
						}}
						className="view-button"
					>
						View Order Details
					</Button>
					<div className="order-footer">
						<Button className="track-button">
							<Icon type="car" /> Track Package
						</Button>
						<Button
							onClick={() => {
								updateCart(cart);
								history.push('/checkout');
							}}
						>
							<Icon type="reload" /> Repeat Order
						</Button>
					</div>
				</div>
			</Row>
			{visible && (
				<div className="card">
					<h2>Summary</h2>
					<div className="content">
						<CartList cart={cart} />
						<Button
							type="primary"
							style={{
								background: theme.palette.secondary.main,
								borderColor: theme.palette.secondary.main,
								color: theme.palette.secondary.contrastText,
							}}
							onClick={() => {
								history.push(`/orders/${orderNo}`);
							}}
						>
							<Icon
								type="shopping-cart"
								style={{
									color: theme.palette.secondary.contrastText,
								}}
							/>
							Edit and re-order
						</Button>
					</div>
				</div>
			)}
		</Row>
	);
}

const mapDispatchToProps = dispatch => ({
	updateCart: cart => dispatch(updateCartAction(cart)),
});

export default withRouter(connect(null, mapDispatchToProps)(OrderRow));
