/* eslint-disable react/require-default-props */
import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import StartIcon from 'react-icons/lib/md/stars';
import { css } from 'react-emotion';
import get from 'lodash/get';
import { shape, func, bool, arrayOf, string } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { getTheme } from '../../components/theme-controller';
import { getColorsFromRGBA } from '../../utils';
import { normalizeProductData } from '../../components/add-cart';
import { addProduct, updateProduct } from '../../actions';
import dummyImage from '../../image/dummy_image.png';

const ResultItem = props => {
	const {
		classes,
		result,
		onClick,
		cart,
		updateProductQuantity,
		addToCart,
		showDesc = false,
		isPopular = false,
		history,
		primaryColor,
		secondaryColor,
		accentColor,
	} = props;

	const theme = getTheme(primaryColor, secondaryColor, accentColor);
	const colors = getColorsFromRGBA(theme.palette.secondary.light);

	const isItemPresent = $result => {
		const item = cart.find(it => it.id === $result._id && it.type === 'item');
		return item;
	};

	return (
		<div
			tabIndex={0}
			role="button"
			onKeyDown={e => e.preventDefault()}
			key={result._id}
			id={result._id}
			onClick={onClick}
			className="card-result-item"
		>
			<Card
				className={classes.card}
				style={
					isPopular
						? {
								backgroundColor: `rgba(${colors[0]}, ${colors[1]}, ${colors[2]}, 0.08)`,
						  }
						: {}
				}
			>
				<CardMedia
					component="img"
					className={classes.media}
					height="50"
					image={dummyImage}
					title="Category"
				/>
				<CardContent className={`${classes.content} ${classes.title}`}>
					<div
						className="content"
						title={result['P21_space_Part_space_#'] || result['Part#_space_1'] || 'Unknown'}
					>
						{isPopular && (
							<span
								className={css`
									margin-right: 2px;
									color: ${theme.palette.secondary.main};
									font-size: 14px;
								`}
							>
								<StartIcon />
							</span>
						)}
						<strong>
							{result['P21_space_Part_space_#'] || result['Part#_space_1'] || 'Unknown'}
						</strong>
					</div>
					{showDesc && (
						<Fragment>
							<div className={classes.description} title={result.Description}>
								{result.Description}
							</div>
							<div className={classes.description} title={result.Description_space_2}>
								{result.Description_space_2}
							</div>
							<div className={classes.description} title={result.Description_space_4}>
								{result.Description_space_4}
							</div>
						</Fragment>
					)}
				</CardContent>
				<div className={classes.buttonContainer}>
					<Button
						size="small"
						color="default"
						className={`${classes.button} ${classes.buttonView}`}
						onClick={() => history.push(`/product/${result._id}`)}
					>
						View
					</Button>
					<Button
						size="small"
						color="secondary"
						className={`${classes.button} ${classes.buttonCart}`}
						title="Add to Cart"
						onClick={() => {
							const itemCart = isItemPresent(result);
							if (itemCart) {
								updateProductQuantity({
									...itemCart,
									quantity: itemCart.quantity + 1,
								});
							} else {
								addToCart(normalizeProductData(result));
							}
						}}
					>
						<AddShoppingCartIcon fontSize="small" />
					</Button>
				</div>
			</Card>
		</div>
	);
};

ResultItem.propTypes = {
	result: shape({}).isRequired,
	classes: shape({}),
	onClick: func.isRequired,
	updateProductQuantity: func,
	addToCart: func,
	cart: arrayOf(shape({})),
	showDesc: bool,
	isPopular: bool,
	primaryColor: string,
	secondaryColor: string,
	accentColor: string,
};

const styles = {
	card: {
		width: 150,
		maxWidth: '100%',
		padding: 3,
		paddingBottom: 37,
		height: '100%',
		position: 'relative',
	},
	title: {
		paddingLeft: '2px !important',
		paddingRight: '2px !important',
		textAlign: 'center',
	},
	content: {
		padding: '5px 10px',
	},
	typo: {
		fontSize: '10px',
	},
	media: {
		objectFit: 'contain',
	},
	action: {
		padding: 3,
	},
	button: {
		fontSize: 10,
		minHeight: 15,
		borderRadius: 0,
		flex: 'auto',
		minWidth: 35,
	},
	buttonCart: {
		paddingRight: 10,
	},
	buttonContainer: {
		display: 'flex',
		paddingTop: 0,
		position: 'absolute',
		justifyContent: 'space-between',
		width: '100%',
		bottom: 5,
	},
	description: {
		fontSize: 10,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
};

const mapStateToProps = state => ({
	primaryColor: get(state, 'user.user.preferences.color_scheme.primary'),
	secondaryColor: get(state, 'user.user.preferences.color_scheme.secondary'),
	accentColor: get(state, 'user.user.preferences.color_scheme.accent'),
	cart: get(state, 'cart', []),
});

const mapDispatchToProps = dispatch => ({
	addToCart: product => dispatch(addProduct(product)),
	updateProductQuantity: product => dispatch(updateProduct(product)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withStyles(styles)(withRouter(ResultItem)));
