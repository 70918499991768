export default {
	and: [
		'q',
		'Category_space_Profile',
		'Profile',
		'Color',
		'OEM_Brand',
		'Record_Type',
		'BrandFilter',
		'Material',
		'id',
		'od',
		'height',
		'person',
		'company',
	],
	or: ['height', 'od', 'id'],
};
