import { USER, USER_PLAN } from '../constants';

const initialUserState = {
	user: undefined,
	isFetchingPlan: undefined,
	getPlanError: null,
	isUpdatingPlan: undefined,
	updatePlanError: null,
};

function user(state = initialUserState, action) {
	switch (action.type) {
		case USER.LOGIN:
			return {
				...initialUserState,
				user: action.user,
			};
		case USER.GET_ROLE_DETAILS:
			return {
				...state,
				user: { ...state.user, ...action.user },
			};
		case USER.LOGOUT:
			return { ...initialUserState };
		case USER.ADD_DYNAMIC_FACETS:
			return { ...state, dynamic_facets: action.payload };
		case USER_PLAN.GET:
			return {
				...state,
				isFetchingPlan: true,
			};
		case USER_PLAN.GET_SUCCESS:
			return {
				...state,
				...action.payload,
				isFetchingPlan: false,
			};
		case USER_PLAN.GET_ERROR:
			return {
				...state,
				isFetchingPlan: false,
				getPlanError: action.error,
			};
		case USER_PLAN.UPDATE:
			return {
				...state,
				isUpdatingPlan: true,
			};
		case USER_PLAN.UPDATE_SUCCESS:
			return {
				...state,
				isUpdatingPlan: false,
			};
		case USER_PLAN.UPDATE_ERROR:
			return {
				...state,
				isUpdatingPlan: false,
				updatePlanError: action.error,
			};
		default:
			return state;
	}
}

export default user;
