import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { string, bool } from 'prop-types';
import { Result } from 'antd';
import { Button } from '@material-ui/core';
import { API_URL, authConfig, whitelistedUsers } from '../../constants/config';
import AppWrapper from '../app-wrapper/AppWrapper';
import Auth from '../../utils/authenticate';
import { logoutUser } from '../../actions';
import { parseJwt } from '../../utils';

const auth = new Auth(authConfig);

class PrivateRoute extends React.Component {
	constructor(props) {
		super(props);
		let isAuthenticated = true;
		fetch(`${API_URL}/authenticate`, {
			headers: {
				'Content-Type': 'application/json',
				'x-auth0-token': props.token,
			},
		})
			.then(res => {
				if (String(res.status) === '200') {
					isAuthenticated = true;
					this.setState({
						isAuthenticated,
					});
				} else {
					isAuthenticated = false;
					this.setState({
						isAuthenticated,
					});
				}
			})
			.catch(error => {
				console.error(error);
			});
		this.state = {
			isAuthenticated,
		};
	}

	componentDidMount() {
		const { role, token, accessToken } = this.props;

		if (!role && token) {
			const user = parseJwt(token);
			auth.patchUserMetadata(
				accessToken,
				user.sub,
				{
					user_role: 'default',
				},
				error => {
					if (error) {
						console.error(error);
					}
				},
			);
		}
	}

	handleLogout = () => {
		const { logout } = this.props;
		auth.logout();
		setTimeout(() => logout(), 100);
	};

	render() {
		const { isLoggedIn, userEmail, role, ...rest } = this.props;
		const { isAuthenticated } = this.state;
		if (process.env.REACT_APP_CI || process.env.NODE_ENV === 'development') {
			return <AppWrapper {...rest} />;
		}

		if (
			isAuthenticated &&
			isLoggedIn &&
			!whitelistedUsers.includes(userEmail) &&
			(!role || role === 'default')
		) {
			return (
				<Result
					status="warning"
					style={{ marginTop: 50 }}
					title={
						<div>
							You don't have permission to view the search UI. <br /> Contact
							membership@industahub.com to gain access to the search.
						</div>
					}
					extra={
						<React.Fragment>
							<Button type="button" href="mailto:membership@industahub.com" color="primary">
								Send Mail
							</Button>
							<Button onClick={this.handleLogout} type="secondary">
								Logout
							</Button>
						</React.Fragment>
					}
				/>
			);
		}
		if (isAuthenticated && isLoggedIn) {
			return <AppWrapper {...rest} />;
		}
		// Set last route to be redirect after success login
		localStorage.setItem('lastRoute', window.location.pathname);
		return <Redirect to="/login" />;
	}
}

PrivateRoute.defaultProps = {
	userEmail: '',
	token: '',
	isLoggedIn: false,
	role: '',
};

PrivateRoute.propTypes = {
	userEmail: string,
	token: string,
	isLoggedIn: bool,
	role: string,
};

const mapStateToProps = state => ({
	isLoggedIn: !!get(state, 'user.user', false),
	userEmail: get(state, 'user.user.email', ''),
	token: get(state, 'user.user.jwt_token'),
	accessToken: get(state, 'user.user.access_token'),
	role: get(state, 'user.user.user_role'),
});

const mapDispatchToProps = dispatch => ({
	logout: () => dispatch(logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
