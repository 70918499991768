export default {
	'O-Rings': 'http://www.sealsonline.com/flabca/c/198-medium_default/orings.jpg',
	'Shaft Seals':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Kits - Customer':
		'http://www.sealsonline.com/flabca/c/357-medium_default/industrial-seal-kits.jpg',
	'Kits - Manufacturer':
		'http://www.sealsonline.com/flabca/c/357-medium_default/industrial-seal-kits.jpg',
	'Material_space_#1':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Piston Seal': 'http://www.sealsonline.com/flabca/c/168-medium_default/piston-seals.jpg',
	'Vee Packing': 'http://www.sealsonline.com/flabca/c/186-medium_default/vee-packings.jpg',
	'Rod/Piston Seals': 'http://www.sealsonline.com/flabca/c/150-medium_default/rod-seals.jpg',
	Backups:
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Piston Seals': 'http://www.sealsonline.com/flabca/c/168-medium_default/piston-seals.jpg',
	Drawings:
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Wear Ring - Guiding Element':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Rod Wipers': 'http://www.sealsonline.com/flabca/c/42-medium_default/rod-wipers.jpg',
	'Rod Seals': 'http://www.sealsonline.com/flabca/c/150-medium_default/rod-seals.jpg',
	'Oil seal':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	Bearings:
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Piston seal': 'http://www.sealsonline.com/flabca/c/168-medium_default/piston-seals.jpg',
	Polypak:
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Retaining Rings':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	Couplings:
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	Gaskets:
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	' Bladder':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Piston Cup':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Q-Rings':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Caps & Plugs':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Piston/Rod seal':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Rod wiper':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Quad Rings':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'V-Rings':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Bonded Seals':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	Miscellaneous:
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Rod seal':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Wear ring':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Back-ups':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	Peek:
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Wear strip':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	Bop:
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	Bearing:
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Flexi Seals':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	Hernon:
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	Packing:
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Braided Packing':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'End Seal':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Shaft seal':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Z - Seals':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Speedi Sleeves':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Rod Seal':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Kit - O-Rings':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	Cord:
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Mechanical Seals':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	Plug:
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	Catalog:
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Cushion Seals':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	Washers:
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Vee packing':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	Pneumatic:
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Seal Kit':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'OEM part':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Piston Ring':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	Gasket:
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Seal kits':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	Adhesives:
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Rod Boots':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'EP Seal':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	Hose:
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Tool Joint Protector':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'GLYD-Ring':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Wiper seal':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	Cup:
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	Fittings:
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Packer Elements':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	Tape:
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Oem Products':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'            Molding KZT':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Bearing Isolators':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Seal Ring':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	Nuts:
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	Cap:
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	' GRR/GRP':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Metal Face':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	Spring:
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	Packaging:
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'GRP/ZHP':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	Materil:
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Swivel Seal':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Piston ring':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Rod Gland':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	Tube:
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Rota Seal':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Cover Seal':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'S.Seal':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	XTP:
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Head Seal':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Kit - Box':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Union Seal':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	Fees:
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	'Bar Packer':
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	MTP:
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	Literature:
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
	KZT:
		'https://images.homedepot-static.com/productImages/cd2a76bf-a549-4612-8960-6e9a47f28f2e/svn/the-hillman-group-grommets-55055-64_1000.jpg',
};
