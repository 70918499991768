/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { connect } from 'react-redux';
import { DataSearch, MultiDropdownList } from '@appbaseio/reactivesearch';
import { withRouter } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuIcon from '@material-ui/icons/Menu';
import PeopleIcon from '@material-ui/icons/People';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import throttle from 'lodash/throttle';
import { Select } from 'antd';
import isMobile from 'ismobilejs';
import { ReactComponent as List } from '../../icons/list.svg';
import { ReactComponent as Table } from '../../icons/table.svg';
import { ReactComponent as Map } from '../../icons/location.svg';
import ActiveList from '../../icons/ActiveList';
import ActiveTable from '../../icons/ActiveTable';
import ActiveCube from '../../icons/ActiveCube';
import ActiveMap from '../../icons/ActiveMap';
import { ReactComponent as Camera } from '../../icons/camera.svg';
import { ReactComponent as Balance } from '../../icons/balance.svg';
import { ReactComponent as Cubes } from '../../icons/cubes.svg';
import { ReactComponent as Attach } from '../../icons/attach.svg';
import { ReactComponent as Microphone } from '../../icons/microphone.svg';
import { ReactComponent as Search } from '../../icons/search.svg';
import { getTheme } from '../theme-controller';
import {
	defaultDataFields,
	nanonetsMapping,
	getSearchWidth,
	getRightPosition,
	getUrlParams,
	constantFilters,
	peopleFacets,
	companyFacets,
	assetsFacets,
} from '../../utils';
import ShoppingCart from './ShoppingCart';

import { CLUSTER_URL, AUTH_HEADER } from '../../constants/config';
import { addFacets, removeFacet, updateCategory } from '../../actions';
import CategoryFilter from '../category-filter';
import SearchDropdown from './SearchDropdown';
import { fetchCompanies, fetchPeople, fetchAssets, recordSearch } from '../../utils/helpers';
import { useCustomRef, setRef } from '../../utils/refService';

const { Option } = Select;

class SearchContainer extends React.Component {
	fetchIndexes = throttle(value => {
		Promise.all([fetchCompanies({ value }), fetchPeople({ value }), fetchAssets({ value })]).then(
			([companiesData, peopleData, assetsData]) => {
				this.setState({
					loading: false,
					companiesData: get(companiesData, 'search.hits.hits', []),
					peopleData: get(peopleData, 'search.hits.hits', []),
					assetsData: get(assetsData, 'search.hits.hits', []),
				});
			},
		);
	}, 500);

	constructor(props) {
		super(props);

		this.state = {
			popularSearches: [],
			value: '',
			loading: false,
			predictions: [],
			assetsData: [],
			companiesData: [],
			peopleData: [],
			selectedPerson: null,
			selectedCompany: null,
			selectedAssets: {
				Model: null,
				Make: null,
				Equipment: null,
			},
			open: true,
			isPredicting: false,
		};

		setRef('search', this);

		const search = new URLSearchParams(window.location.search);

		if (search.has(companyFacets[0])) {
			const val = search.get(companyFacets[0]);
			this.state.selectedCompany = val.substr(2, val.length - 4);
		}

		if (search.has(peopleFacets[0])) {
			const val = search.get(peopleFacets[0]);
			this.state.selectedPerson = val.substr(2, val.length - 4);
		}
	}

	componentDidMount() {
		this.fetchPopularSearches();
		if (this.searchRef) {
			this.searchRef._inputRef.setAttribute('autocorrect', 'off');
			this.searchRef._inputRef.setAttribute('spellcheck', 'false');
			this.searchRef._inputRef.setAttribute('autocapitalize', 'off');
			this.searchRef._inputRef.setAttribute('autocomplete', 'off');
		}
	}

	onAssetsSelect = item => {
		const { selectedAssets } = this.state;

		const stateObj = {};
		const search = new URLSearchParams(window.location.search);

		if (!search.has(companyFacets[0])) {
			stateObj.selectedCompany = null;
		}
		if (!search.has(peopleFacets[0])) {
			stateObj.selectedPerson = null;
		}

		const selectedAsset = { ...selectedAssets };

		assetsFacets.forEach(asset => {
			if (selectedAsset[asset] !== item[asset]) {
				selectedAsset[asset] = item[asset];
			}
		});

		this.setState({ selectedAssets: selectedAsset, ...stateObj }, this.redirectUrl);
	};

	onPersonSelect = id => {
		const { selectedPerson, selectedAssets } = this.state;

		const stateObj = { selectedPerson: id, selectedAssets: { ...selectedAssets } };
		const search = new URLSearchParams(window.location.search);

		assetsFacets.forEach(asset => {
			if (!search.has[asset]) {
				stateObj.selectedAssets[asset] = null;
			}
		});

		if (!search.has(companyFacets[0])) {
			stateObj.selectedCompany = null;
		}
		if (selectedPerson !== id || id !== search.get(peopleFacets[0])) {
			this.setState(stateObj, this.redirectUrl);
		}
	};

	onCompanySelect = id => {
		const { selectedCompany, selectedAssets } = this.state;
		const stateObj = { selectedCompany: id, selectedAssets: { ...selectedAssets } };
		const search = new URLSearchParams(window.location.search);

		assetsFacets.forEach(asset => {
			if (!search.has[asset]) {
				stateObj.selectedAssets[asset] = null;
			}
		});

		if (!search.has(peopleFacets[0])) {
			stateObj.selectedPerson = null;
		}
		if (selectedCompany !== id || id !== search.get(companyFacets[0])) {
			this.setState(stateObj, this.redirectUrl);
		}
	};

	redirectUrl = () => {
		const { history } = this.props;
		const { selectedCompany, selectedPerson, selectedAssets } = this.state;
		const search = new URLSearchParams(window.location.search);

		if (selectedCompany) {
			search.set(companyFacets[0], `["${selectedCompany}"]`);
		}
		if (selectedPerson) {
			search.set(peopleFacets[0], `["${selectedPerson}"]`);
		}

		assetsFacets.forEach(asset => {
			if (selectedAssets[asset]) {
				search.set(asset, `["${selectedAssets[asset]}"]`);
			}
		});

		history.push({
			pathname: '/',
			search: `?${search.toString()}`,
		});

		if (selectedPerson && selectedCompany) {
			this.setState({ open: false });
		}
		useCustomRef('facets').triggerUpdate();
	};

	recordSearch = value => {
		const { selectedCompany } = this.state;
		const search = new URLSearchParams(window.location.search);
		let urlCompanyName = null;
		if (search.has(companyFacets[0])) {
			const tmp = search.get(companyFacets[0]);
			urlCompanyName = tmp.substr(2, tmp.length - 4);
		}
		console.log('Entered search', value);
		if (urlCompanyName !== selectedCompany) {
			this.setState({ selectedCompany: urlCompanyName }, () => {
				if (!isEmpty(urlCompanyName) && !isEmpty(value)) {
					recordSearch(value, { company: urlCompanyName });
				}
			});
			return;
		}
		if (!isEmpty(selectedCompany) && !isEmpty(value)) {
			recordSearch(value, { company: selectedCompany });
		}
	};

	handleChange = (value, triggerQuery) => {
		this.setState(
			{
				value,
				open: true,
				loading: true,
			},
			() => {
				this.fetchIndexes(value);
				if (isEmpty(value) && triggerQuery) {
					triggerQuery();
				}
			},
		);
	};

	handleUpload = e => {
		this.setState({ isPredicting: true });
		const file = e.target.files[0];
		const data = new FormData();
		data.append('modelId', '6127a385-c129-4bea-bb34-8bc1f165a8fa');
		data.append('file', file);

		const options = {
			headers: { authorization: `Basic ${btoa('UZfxZ1ulnQa4P-lt7i1-Q8jABT_0mxyg:')}` },
			method: 'POST',
			body: data,
		};

		fetch('https://app.nanonets.com/api/v2/ImageCategorization/LabelFile/', options)
			.then(res => res.json())
			.then(data => {
				if (
					data.message === 'Success' &&
					data.result &&
					data.result[0] &&
					data.result[0].prediction
				) {
					const predictions = data.result[0].prediction.sort(
						(a, b) => a.probability > b.probability,
					);
					this.setState({
						isPredicting: false,
						predictions,
					});
					this.props.onSuccessfulPredicition(nanonetsMapping[predictions[0].label]);
				} else {
					throw new Error('Prediction Failed');
				}
			})
			.catch(e => {
				console.error(e);
				this.setState({ isPredicting: false });
			});
	};

	fetchPopularSearches = async () => {
		try {
			const response = await fetch(`${CLUSTER_URL}/_analytics/popular-searches?size=3`, {
				headers: {
					Authorization: AUTH_HEADER,
				},
				method: 'GET',
			});
			const res = await response.json();

			this.setState({
				popularSearches: res.popular_searches.slice(0, 5),
			});
		} catch (err) {
			console.log(err);
		}
	};

	handleValueChange = value => {
		const { history } = this.props;
		console.log('Selected -> Recording search');
		this.recordSearch(value);
		const params = getUrlParams(window.location.search);

		if (Object.keys(params).length > 0 && !params.q) {
			const globalFilters = constantFilters.reduce((agg, item, index) => {
				if (!params[item]) {
					return agg;
				}
				if (index !== 0) {
					return `${agg}&${item}=${params[item]}`;
				}
				return `${agg}${item}=${params[item]}`;
			}, '?');
			history.push(`${window.location.pathname}${globalFilters}`);
		}
		if (value && value.trim()) {
			this.props.onAddQuery();
		} else {
			this.props.onRemoveQuery();
		}
	};

	handleRouteClick = route => {
		this.props.history.push(`/${route}${window.location.search}`);
	};

	handlePopularSearch = value => {
		this.setState(
			{
				value,
				open: false,
			},
			() => {
				if (this.searchRef) {
					this.searchRef.triggerQuery();
				}
			},
		);
	};

	getWidth = () => {
		const { globalLeftSidebar, globalRightSidebar, homeLeftSidebar, homeRightSidebar } = this.props;
		return getSearchWidth({
			globalLeftSidebar,
			globalRightSidebar,
			homeLeftSidebar,
			homeRightSidebar,
		});
	};

	render() {
		const { handleSearchChange, globalRightSidebar, homeRightSidebar, resetRule } = this.props;
		const right = getRightPosition({ globalRightSidebar, homeRightSidebar });
		const containerWidth = this.getWidth();
		const {
			popularSearches,
			value,
			open,
			companiesData,
			peopleData,
			assetsData,
			selectedPerson,
			selectedCompany,
			loading,
		} = this.state;

		const theme = getTheme(
			this.props.primaryColor,
			this.props.secondaryColor,
			this.props.accentColor,
		);
		return (
			<div className="searchContainer" style={{ width: containerWidth, right }}>
				<CategoryFilter componentId="Category_space_Profile" />
				<DataSearch
					ref={c => (this.searchRef = c)}
					className="datasearch home-datasearch home-page-search"
					componentId="q"
					placeholder={
						isMobile
							? 'Search Applications, Parts, etc.'
							: 'Search Industrial Applications, Mobility, Mobile Machinery & Process industry, etc.'
					}
					innerClass={{
						input: 'searchbox',
						list: 'suggestionlist',
						mic: `voice-search ${value ? 'active' : ''}`,
					}}
					autosuggest
					iconPosition="right"
					filterLabel="search"
					URLParams
					showDistinctSuggestions
					showFilter
					showClear
					react={{ and: ['Category_space_Profile'] }}
					clearIcon={<Icon className="input-close-icon">close</Icon>}
					onValueSelected={this.handleValueChange}
					value={this.state.value}
					showVoiceSearch
					onChange={this.handleChange}
					onKeyDown={(e, triggerQuery) => {
						if (e.key === 'Enter') {
							this.setState(
								{
									open: false,
								},
								() => {
									triggerQuery();
								},
							);
						}
					}}
					onValueChange={resetRule}
					render={$props => {
						const {
							downshiftProps: { isOpen },
						} = $props;
						if (open && isOpen && Boolean((value || '').length)) {
							return (
								<SearchDropdown
									{...$props}
									popularSearches={popularSearches}
									handlePopularSearch={this.handlePopularSearch}
									companiesData={companiesData}
									peopleData={peopleData}
									assetsData={assetsData}
									selectedPerson={selectedPerson}
									selectedCompany={selectedCompany}
									onCompanySelected={this.onCompanySelect}
									onPersonSelected={this.onPersonSelect}
									onAssetsSelected={this.onAssetsSelect}
									fetchIndexLoading={loading}
								/>
							);
						}
						return null;
					}}
					icon={
						<React.Fragment>
							<span
								style={{
									color: '#fff',
									background: theme.palette.secondary.main,
									borderTopRightRadius: '3px',
									borderBottomRightRadius: '3px',
									padding: '0 15px',
									marginRight: '-12px',
									height: '50px',
									paddingTop: '13px',
									paddingBottom: '11px',
									cursor: 'pointer',
									zIndex: 100,
								}}
							>
								<Search />
							</span>
							<span className="image-upload upload-icon">
								<label htmlFor="file-input">
									{this.state.isPredicting ? (
										<CircularProgress color="secondary" size={20} thickness={4} />
									) : (
										<Camera width="16px" style={{ margin: 0 }} className="icon" />
									)}
								</label>

								<input id="file-input" onChange={this.handleUpload} type="file" />
							</span>
						</React.Fragment>
					}
					dataField={undefined}
				/>
				<div className="search-icons">
					<Microphone className="icon" />
					<Camera className="icon" />
					<Attach className="icon" />
					<Balance className="icon" />
				</div>
				<div className="content-icons">
					<span
						className="search-link-icon icon"
						onClick={() => {
							this.handleRouteClick('recommend');
						}}
					>
						{window.location.pathname === '/recommend' || window.location.pathname === '/' ? (
							<ActiveCube />
						) : (
							<Cubes />
						)}
					</span>
					{/* <span
						className="search-link-icon icon"
						onClick={() => {
							this.handleRouteClick('map');
						}}
					>
						{window.location.pathname === '/map' ? <ActiveMap /> : <Map />}
					</span>

					<span
						className="search-link-icon icon"
						onClick={() => {
							this.handleRouteClick('list');
						}}
					>
						{window.location.pathname === '/list' ? <ActiveList /> : <List />}
					</span> */}
					<span
						className="search-link-icon icon"
						onClick={() => {
							this.handleRouteClick('compare');
						}}
					>
						{window.location.pathname === '/compare' ? <ActiveTable /> : <Table />}
					</span>

					{window.innerWidth < 768 ? (
						<React.Fragment>
							<span className="search-link-icon icon">
								<img src="/images/icons/location.svg" alt="" />
							</span>
							<span className="search-link-icon icon">
								<img src="/images/icons/lines.svg" alt="" />
							</span>
							<span className="search-link-icon icon">
								<img style={{ filter: 'grayscale(1)' }} src="/images/icons/people.svg" alt="" />
							</span>
						</React.Fragment>
					) : null}

					{window.location.pathname === '/map' ? (
						<div className="show-sm">
							<ShoppingCart />
						</div>
					) : null}
				</div>
				<div className="break" />
				<div className="search-links">
					<div className="link-items">
						<span
							onClick={() => {
								this.handleRouteClick('recommend');
							}}
							className={`${
								window.location.pathname === '/recommend' || window.location.pathname === '/'
									? 'active'
									: ''
							}`}
						>
							{window.location.pathname === '/recommend' || window.location.pathname === '/' ? (
								<ActiveCube />
							) : (
								<Cubes />
							)}
							<span className="text">Recommend</span>
						</span>
						{/* <span
						onClick={() => {
							this.handleRouteClick('map');
						}}
						className={`${window.location.pathname === '/map' ? 'active' : ''}`}
					>
						{window.location.pathname === '/map' ? <ActiveMap /> : <Map />}
						<span className="text">Map</span>
					</span>

					<span
						onClick={() => {
							this.handleRouteClick('list');
						}}
						className={`${window.location.pathname === '/list' ? 'active' : ''}`}
					>
						{window.location.pathname === '/list' ? <ActiveList /> : <List />}
						<span className="text">List</span>
					</span> */}
						<span
							onClick={() => {
								this.handleRouteClick('compare');
							}}
							className={`${window.location.pathname.includes('/compare') ? 'active' : ''}`}
						>
							{window.location.pathname.includes('/compare') ? <ActiveTable /> : <Table />}
							<span className="text">Compare</span>
						</span>
					</div>
					<div className="link-items">
						<span className="search-link-icon icon">
							<img src="/images/icons/location.svg" alt="" />
							<span className="text">Map</span>
						</span>
						{window.location.pathname.includes('/compare') && (
							<span
								onClick={() => {
									this.handleRouteClick('compare');
								}}
								className={`${
									window.location.pathname === '/compare' ? 'active' : ''
								} search-link-icon icon`}
							>
								<MenuIcon
									color={window.location.pathname === '/compare' ? 'secondary' : 'disabled'}
								/>
								<span className="text">List</span>
							</span>
						)}
						<span
							onClick={() => {
								this.handleRouteClick(
									window.location.pathname === '/recommend' ? 'recommend' : 'compare/group',
								);
							}}
							className={`${
								window.location.pathname === '/' ||
								window.location.pathname === '/recommend' ||
								window.location.pathname === '/compare/group'
									? 'active'
									: ''
							} search-link-icon icon`}
						>
							<PeopleIcon
								color={
									window.location.pathname === '/' ||
									window.location.pathname === '/recommend' ||
									window.location.pathname === '/compare/group'
										? 'secondary'
										: 'disabled'
								}
							/>
							<span className="text">Group</span>
						</span>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	const dataFields = get(state, 'user.user.dataFields.length')
		? get(state, 'user.user.dataFields')
		: defaultDataFields;
	return {
		homeLeftSidebar: get(state, 'sidebar.home.left.open', false),
		homeRightSidebar: get(state, 'sidebar.home.right.open', false),
		globalLeftSidebar: get(state, 'sidebar.global.left.open', false),
		globalRightSidebar: get(state, 'sidebar.global.right.open', false),
		dataFields,
		primaryColor: get(state, 'user.user.preferences.color_scheme.primary'),
		secondaryColor: get(state, 'user.user.preferences.color_scheme.secondary'),
		accentColor: get(state, 'user.user.preferences.color_scheme.accent'),
	};
};

const mapDispatchToProps = dispatch => ({
	onAddQuery: () => dispatch(addFacets('q')),
	onRemoveQuery: () => dispatch(removeFacet('q')),
	onSuccessfulPredicition: category => dispatch(updateCategory(category)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchContainer));
