import { css } from 'react-emotion';

const heading = css`
	margin: 4px auto;
	font-weight: 600;
	line-height: 16px;
	font-size: 16px;
`;

const subHeading = css`
	margin: -2px auto 2px;
	color: #777;
	font-size: 12px;
`;

const subHeadingBold = css`
	margin: -2px auto 2px;
	color: #777;
	font-weight: bold;
	font-size: 14px;
`;

export { heading, subHeading, subHeadingBold };
