import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DraggableCart from '../../components/draggable-cart';
import { updateOrder } from '../../actions/order';
import { updateCart } from '../../actions';
import ItemSearch from '../../components/item-search';

const searchRef = React.createRef(null);
const draggableRef = React.createRef(null);

const getOrderId = match => {
	return Number(match.params.id);
};

const EditableOrder = ({ order, updateOrder, updateCart }) => {
	return (
		<div className="bom-container">
			<ItemSearch searchRef={searchRef} draggableRef={draggableRef} />
			<DraggableCart
				ref={draggableRef}
				cart={order.cart}
				onUnmount={modOrder => {
					updateOrder(order.id, modOrder);
					updateCart(modOrder);
				}}
			/>
		</div>
	);
};

const mapStateToProps = (state, props) => ({
	order: state.orders.find(order => order.id === getOrderId(props.match)),
});

const mapDispatchToProps = dispatch => ({
	updateOrder: (orderId, order) => dispatch(updateOrder(orderId, order)),
	updateCart: cart => dispatch(updateCart(cart)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditableOrder));
