import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';

import Cart from './content/Cart';
import {
	toggleGlobalLeftSidebar,
	updateGlobalLeftSidebar,
	updateGlobalRightSidebar,
	toggleGlobalRightSidebar,
} from '../../actions';
import { rightSidebarContent } from '../../constants/sidebar';
import UserProfile from './content/Profile';

class GlobalSidebar extends React.Component {
	renderView = () => {
		const { currentView } = this.props;

		switch (currentView) {
			case rightSidebarContent.Cart:
				return <Cart />;
			case rightSidebarContent.Profile:
				return <UserProfile />;

			default:
				return null;
		}
	};

	render() {
		const { isSidebar, direction, currentView } = this.props;
		const sidebarClass = isSidebar ? 'leftSidebarOpen' : 'leftSidebarClose';
		const wrapperClass = isSidebar ? 'sidebar-wrapper' : '';
		const homeRightSidebarStyle = isSidebar && direction === 'right' ? { width: 320 } : {};
		return (
			<div className={`${wrapperClass} hide-sm`} style={homeRightSidebarStyle}>
				<div className={`leftSidebar ${sidebarClass}`} style={homeRightSidebarStyle}>
					{this.renderView()}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => ({
	isSidebar: get(state, `sidebar.global.${props.direction}.open`, false),
	currentView: get(state, `sidebar.global.${props.direction}.view`, null),
});

const mapDispatchToProps = dispatch => ({
	toggleLeft: () => dispatch(toggleGlobalLeftSidebar()),
	updateLeftView: view => dispatch(updateGlobalLeftSidebar(view)),
	toggleRight: () => dispatch(toggleGlobalRightSidebar()),
	updateRightView: view => dispatch(updateGlobalRightSidebar(view)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSidebar);
