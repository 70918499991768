import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import { Button, Tooltip, Empty, Icon } from 'antd';
import { withStyles } from '@material-ui/core/styles';
import {
	removeProduct,
	toggleGlobalRightSidebar,
	updateGlobalRightSidebar,
	updateProduct,
} from '../../../actions';
import { getTotal } from '../../../utils';
import { getTheme } from '../../theme-controller';
import CartList from '../../cart-list';

const styles = theme => ({
	margin: {
		margin: theme.spacing.unit * 2,
	},
	padding: {
		padding: `0 ${theme.spacing.unit * 1}px`,
		cursor: 'pointer',
	},
});

const ShoppingCart = props => {
	const { primaryColor, secondaryColor, accentColor, cart, history } = props;
	if (!cart || !cart.length) {
		return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No items present in Cart" />;
	}
	const theme = getTheme(primaryColor, secondaryColor, accentColor);
	return (
		<div
			style={{
				padding: '0 10px',
			}}
		>
			<Button
				style={{
					borderColor: theme.palette.secondary.contrastText,
					color: theme.palette.secondary.main,
					marginBottom: 10,
					background: 'white',
					minWidth: window.innerWidth < 768 ? '100%' : 260,
				}}
				block
				size="large"
				onClick={() => history.push('/cart')}
				type="primary"
			>
				<Icon
					style={{
						color: theme.palette.secondary.main,
					}}
					type="shopping-cart"
				/>
				Edit Cart
			</Button>
			<Button
				style={{
					borderColor: theme.palette.secondary.main,
					color: theme.palette.secondary.contrastText,
					background: theme.palette.secondary.main,
					marginBottom: 10,
				}}
				block
				onClick={() => history.push('/checkout')}
				type="primary"
			>
				<Icon
					style={{
						color: theme.palette.secondary.contrastText,
					}}
					type="shopping-cart"
				/>
				Proceed to Buy
			</Button>
			<p> Total Price: $ {getTotal(cart, item => Number(item.price || 0) * item.quantity)} </p>
			<CartList showDelete cart={cart} isEditable showProducts={false} />
		</div>
	);
};

const mapStateToProps = state => ({
	primaryColor: get(state, 'user.user.preferences.color_scheme.primary'),
	secondaryColor: get(state, 'user.user.preferences.color_scheme.secondary'),
	accentColor: get(state, 'user.user.preferences.color_scheme.accent'),
	currentView: get(state, 'sidebar.global.right.view', null),
	cart: get(state, 'cart', []),
});

const mapDispatchToProps = dispatch => ({
	removeProductFromCart: id => dispatch(removeProduct(id)),
	toggleSidebar: () => dispatch(toggleGlobalRightSidebar()),
	updateView: view => dispatch(updateGlobalRightSidebar(view)),
	updateProductQuantity: product => dispatch(updateProduct(product)),
});

export default withRouter(
	withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ShoppingCart)),
);
