import React from 'react';
import { getTheme } from '../components/theme-controller';
import { connect } from 'react-redux';
import get from 'lodash/get';

const Icon = ({ primaryColor, secondaryColor, accentColor, dispatch, ...props }) => {
	const theme = getTheme(primaryColor, secondaryColor, accentColor);
	return (
		<svg
			width="17px"
			height="14px"
			viewBox="0 0 17 14"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>A7640432-DB7C-4799-ABF7-FC416B3E14BB@3x</title>
			<desc>Created with sketchtool.</desc>
			<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g
					id="Icon-Set"
					transform="translate(-348.000000, -69.000000)"
					fill={theme.palette.secondary.main}
					fillRule="nonzero"
				>
					<g id="noun_Location_1318437-Copy" transform="translate(348.000000, 69.000000)">
						<g id="Group" transform="translate(0.472222, 0.000000)">
							<path
								d="M8.02777778,6.3175 C8.06268116,6.3175 8.11503623,6.3175 8.1673913,6.3175 C9.35410628,6.23 10.2615942,5.1975 10.1917874,4.0075 C10.1568841,3.43 9.8951087,2.8875 9.45881643,2.5025 C9.02252415,2.1175 8.46407005,1.925 7.88816425,1.96 C6.70144928,2.03 5.79396135,3.0625 5.86376812,4.27 C5.93357488,5.425 6.89341787,6.3175 8.02777778,6.3175 Z M7.95797101,3.01 C7.97542271,3.01 8.01032609,3.01 8.02777778,3.01 C8.30700483,3.01 8.56878019,3.115 8.76074879,3.29 C8.98762077,3.4825 9.1272343,3.7625 9.14468599,4.06 C9.17958937,4.6725 8.70839372,5.215 8.09758454,5.2675 C7.48677536,5.3025 6.94577295,4.83 6.91086957,4.2175 C6.87596618,3.5875 7.34716184,3.0625 7.95797101,3.01 Z"
								id="Shape"
							></path>
							<path
								d="M11.6926329,6.65 C11.7100845,6.6325 11.7100845,6.615 11.7100845,6.615 C11.7275362,6.58 11.7449879,6.5625 11.7624396,6.545 C11.9369565,6.265 12.0591184,5.95 12.1463768,5.6175 C12.251087,5.25 12.303442,4.8475 12.303442,4.445 C12.303442,2.065 10.383756,0.14 8.01032609,0.14 C5.63689614,0.14 3.71721014,2.065 3.71721014,4.445 C3.71721014,4.5325 3.71721014,4.62 3.73466184,4.7075 C3.75211353,5.075 3.82192029,5.425 3.92663043,5.7575 C4.03134058,6.09 4.17095411,6.3875 4.34547101,6.65 L1.64045894,6.65 L0,13.86 L16.0555556,13.86 L14.4150966,6.65 L11.6926329,6.65 Z M8.02777778,1.19 C9.80785024,1.19 11.2737923,2.6425 11.2737923,4.445 C11.2737923,4.7425 11.2388889,5.04 11.1516304,5.32 C11.0818237,5.565 10.9945652,5.7925 10.8724034,6.0025 C10.8724034,6.02 10.8549517,6.02 10.8549517,6.0375 C10.8375,6.055 10.8200483,6.0725 10.8200483,6.1075 C10.6280797,6.3875 8.60368357,9.4325 8.04522947,10.2725 C7.57403382,9.5725 6.16044686,7.455 5.56708937,6.5625 L5.53218599,6.51 C5.41002415,6.335 5.3227657,6.2125 5.28786232,6.16 L5.27041063,6.125 C5.1307971,5.915 5.02608696,5.6875 4.9388285,5.4075 C4.85157005,5.1625 4.79921498,4.9 4.79921498,4.62 C4.79921498,4.55 4.78176329,4.4975 4.78176329,4.4275 C4.78176329,2.6425 6.24770531,1.19 8.02777778,1.19 Z M1.30887681,12.81 L2.46068841,7.7 L5.06099034,7.7 C5.84631643,8.89 7.08538647,10.745 7.29480676,11.0425 L7.32971014,11.0775 C7.48677536,11.3225 7.74855072,11.4625 8.04522947,11.4625 C8.32445652,11.4625 8.58623188,11.3225 8.7432971,11.1125 L8.79565217,11.0425 C8.86545894,10.92 10.1917874,8.9425 11.0294686,7.7 L13.6123188,7.7 L14.7641304,12.8275 L1.30887681,12.8275 L1.30887681,12.81 Z"
								id="Shape"
							></path>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};

const mapStateToProps = state => ({
	primaryColor: get(state, 'user.user.preferences.color_scheme.primary'),
	secondaryColor: get(state, 'user.user.preferences.color_scheme.secondary'),
	accentColor: get(state, 'user.user.preferences.color_scheme.accent'),
});

export default connect(mapStateToProps)(Icon);
