import React from 'react';
import MultiBackend from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/lib/HTML5toTouch';
import { Button, Empty, Icon, Input, InputNumber, Table, Tag, Tooltip, notification } from 'antd';
import DraggableBodyRow from '../../pages/bom/DraggableRow';
import { Link } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { CART } from '../../constants';
import cartHandler from './utils/cart';
import { getTheme } from '../theme-controller';
import { getMonth, getPrice } from './utils/helper';
import { getTotal } from '../../utils';
import { normalizeProductData } from '../add-cart';
import dummy_image from '../../image/dummy_image.png';

class DraggableCart extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			newKey: Date.now(),
			cart: cartHandler(props.cart || [], {}),
		};
		this.columns = [
			{
				title: 'Type',
				dataIndex: 'type',
				key: 'type',
				width: 180,
				render: (value, data) => {
					let TagType = null;
					switch (value) {
						case 'child_kit':
						case 'kit': {
							TagType = <Tag color="geekblue">Kit</Tag>;
							break;
						}
						case 'item': {
							TagType = <Tag color="purple">Item</Tag>;
							break;
						}
						default: {
							TagType = <Tag color="magenta">{value}</Tag>;
						}
					}
					return (
						<React.Fragment>
							{TagType}
							<Tooltip title={data.type === 'kit_item' ? 'Remove item from Kit' : 'Remove Item'}>
								<Button
									shape="circle-outline"
									className="delete-cart-item"
									type="danger"
									ghost
									size="small"
									icon="delete"
									style={{ marginRight: 5 }}
									onClick={() => this.handleDelete(data)}
								/>
							</Tooltip>
						</React.Fragment>
					);
				},
			},
			{
				title: 'Image',
				dataIndex: 'name',
				key: 'name',
				Header: '',
				render: () => (
					<div style={{ maxHeight: 40 }}>
						<img
							alt="Dummy"
							style={{
								display: 'block',
								margin: '0 auto',
								objectFit: 'contain',
								maxWidth: '100%',
								maxHeight: 32,
							}}
							src={dummy_image}
						/>
					</div>
				),
				width: 100,
			},
			{
				title: 'Part Number/Kit',
				dataIndex: 'name',
				key: 'name',
				width: 250,
				render: (value, data) => {
					if (data.type === 'kit') {
						return (
							<Input
								name={data.id}
								defaultValue={value}
								placeholder="Enter the name of kit"
								onBlur={this.handleKitName}
							/>
						);
					}
					return value;
				},
			},

			{
				title: 'Description',
				dataIndex: 'description',
				key: 'description',
				width: 350,
				render: (value, data) => {
					if (data.type === 'kit') {
						return (
							<Input
								name={data.id}
								defaultValue={value}
								placeholder="Enter the description of kit"
								onBlur={this.handleKitDescription}
							/>
						);
					}
					return value;
				},
			},
			{
				title: 'Quantity',
				dataIndex: 'quantity',
				key: 'quantity',
				width: 120,
				render: (value, data) => {
					return (
						<InputNumber
							value={value}
							defaultValue={0}
							min={1}
							onChange={qty => this.handleQuantity(qty, data)}
						/>
					);
				},
			},
			{
				title: 'Unit Total',
				dataIndex: 'price',
				key: 'price',
				width: 100,
				render: (value, data) => {
					if (data.type === 'kit' || data.type === 'child_kit') {
						return '-';
					}
					return `$${(+value).toFixed(2)}`;
				},
			},
			{
				title: 'Line Total',
				key: 'line',
				width: 120,
				render: (_, data) => {
					if (data.type === 'kit' || data.type === 'child_kit') {
						return '-';
					}
					return `$${(data.price * data.quantity).toFixed(2)}`;
				},
			},
			{
				title: 'Batch Total',
				key: 'batch',
				width: 120,
				render: (_, data) => {
					let value = (data.price * data.quantity).toFixed(2);
					if (data.type === 'kit') {
						const price = getPrice(data.children);
						value = price && price.toFixed(2);
					}

					if (data.type === 'child_kit') {
						const price = getPrice(data.children);
						value = price && price.toFixed(2);
					}
					return <div style={{ textAlign: 'right' }}>${value}</div>;
				},
			},
		];
	}

	componentCleanup = () => {
		const { onUnmount } = this.props;
		if (onUnmount) onUnmount(this.state.cart);
	};

	componentDidMount() {
		window.addEventListener('beforeunload', this.componentCleanup);
	}

	componentDidUpdate(prevProps, prevState) {
		const { cart, updater } = this.props;
		const { cart: stateCart } = this.state;
		if (JSON.stringify(stateCart) !== JSON.stringify(prevState.cart)) {
			if (updater) {
				updater(stateCart);
			}
		}
		if (JSON.stringify(prevProps.cart) !== JSON.stringify(cart)) {
			this.setState({ newKey: Date.now(), cart });
		}
	}

	componentWillUnmount() {
		this.componentCleanup();
		window.removeEventListener('beforeunload', this.componentCleanup); // remove the event handler for normal unmounting
	}

	handleDelete = item => {
		const {
			deleteKit,
			deleteProduct,
			addProductToCart,
			addKitToCart,
			removeSubKit,
			removeSubKitItem,
			removeKitItem,
		} = this;
		switch (item.type) {
			case 'kit': {
				deleteKit(item.id);
				break;
			}
			case 'item': {
				deleteProduct(item.id);
				break;
			}
			case 'kit_item': {
				if (!item.parentKit) {
					removeKitItem(item);
				} else {
					removeSubKitItem(item);
				}

				addProductToCart({
					id: item.id,
					price: item.price,
					quantity: item.quantity,
					name: item.name,
					parentKit: undefined,
					kit: undefined,
				});
				break;
			}
			case 'child_kit': {
				removeSubKit(item.kit_associated, item.id);
				addKitToCart({
					...item,
					type: 'kit',
				});
				break;
			}
			default:
				console.error('Unrecognizable Cart Item');
		}
	};

	addProductToCart = product => {
		const item = normalizeProductData(product);
		this.setState({
			cart: cartHandler(this.state.cart, { type: CART.ADD_PRODUCT, product: item }),
		});
	};

	addKitToCart = kit => {
		this.setState({ cart: cartHandler(this.state.cart, { type: CART.ADD_KIT, kit }) });
	};

	deleteKit = id => {
		this.setState({ cart: cartHandler(this.state.cart, { type: CART.REMOVE_KIT, id }) });
	};

	deleteProduct = id => {
		this.setState({ cart: cartHandler(this.state.cart, { type: CART.REMOVE_PRODUCT, id }) });
	};

	removeSubKit = (kit, subKit) => {
		this.setState({
			cart: cartHandler(this.state.cart, { type: CART.REMOVE_SUB_KIT, kit, subKit }),
		});
	};

	removeSubKitItem = item => {
		this.setState({ cart: cartHandler(this.state.cart, { type: CART.REMOVE_SUB_KIT_ITEM, item }) });
	};

	removeKitItem = item => {
		this.setState({ cart: cartHandler(this.state.cart, { type: CART.REMOVE_KIT_ITEM, item }) });
	};

	updateKitQuantity = kit => {
		this.setState({ cart: cartHandler(this.state.cart, { type: CART.UPDATE_KIT, kit }) });
	};

	addKitItem = (kit, item) => {
		this.setState({ cart: cartHandler(this.state.cart, { type: CART.ADD_KIT_ITEM, kit, item }) });
	};

	addSubKitItem = (parentKit, kit, item) => {
		this.setState({
			cart: cartHandler(this.state.cart, { type: CART.ADD_SUB_KIT_ITEM, parentKit, kit, item }),
		});
	};

	addSubKit = (kit, subKit) => {
		this.setState({
			cart: cartHandler(this.state.cart, { type: CART.ADD_SUB_KIT, kit, subKit }),
		});
	};

	sortItems = (source, destination) => {
		this.setState({
			cart: cartHandler(this.state.cart, { type: CART.REORDER_ITEMS, source, destination }),
		});
	};

	handleKitName = e => {
		const { updateKitQuantity } = this;
		updateKitQuantity({
			id: e.target.name,
			name: e.target.value || e.target.name,
		});
	};

	handleKitDescription = e => {
		const { updateKitQuantity } = this;
		updateKitQuantity({
			id: e.target.name,
			description: e.target.value || '',
		});
	};

	updateProductQuantity = product => {
		this.setState({ cart: cartHandler(this.state.cart, { type: CART.UPDATE_PRODUCT, product }) });
	};

	updateQuantity = (kit, value) => {
		kit.children.forEach(item => {
			if (item.type === 'child_kit') {
				this.updateQuantity(item, Math.ceil(item.quantity / kit.quantity) * value);
			} else {
				item.quantity = Math.ceil(item.quantity / kit.quantity) * value;
			}
		});
		kit.quantity = value;
	};

	handleQuantity = (value, item) => {
		const { updateProductQuantity, updateKitQuantity } = this;
		const { cart } = this.state;
		if (item.type === 'kit') {
			const updatedKit = JSON.parse(JSON.stringify(item));
			this.updateQuantity(updatedKit, value);
			updateKitQuantity(updatedKit);
		} else if (item.type === 'item') {
			updateProductQuantity({ id: item.id, quantity: value });
		} else if (item.type === 'kit_item') {
			if (item.parentKit) {
				const associatedKit = cart.find(
					cartItem => cartItem.id === item.parentKit && cartItem.type === 'kit',
				);
				associatedKit.children = associatedKit.children.map(product => {
					if (product.id === item.kit) {
						product.children = product.children.map(kitItem => {
							if (kitItem.id === item.id) {
								return {
									...kitItem,
									quantity: kitItem.quantity + 1,
								};
							}
							return kitItem;
						});
					}
					return product;
				});
				updateKitQuantity(associatedKit);
			} else {
				const associatedKit = cart.find(
					cartItem => cartItem.id === item.kit && cartItem.type === 'kit',
				);
				associatedKit.children = associatedKit.children.map(product => {
					if (product.id === item.id) {
						return {
							...product,
							quantity: value,
						};
					}
					return product;
				});
				updateKitQuantity(associatedKit);
			}
		} else if (item.type === 'child_kit') {
			const kitAssociated = cart.find(cartItem => cartItem.id === item.kit_associated);
			kitAssociated.children = kitAssociated.children.map(kitItem => {
				if (kitItem.id === item.id) {
					this.updateQuantity(kitItem, value);
				}
				return kitItem;
			});
			updateKitQuantity(kitAssociated);
		} else {
			console.error('Unknown cart type');
		}
	};

	addKit = () => {
		const { addKitToCart } = this;
		const randomId = Math.random().toString(36);
		const currentDate = new Date();
		const dateStamp = `${currentDate.getDate()}-${getMonth(
			currentDate.getMonth() + 1,
		)}-${currentDate.getFullYear()}-${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;
		addKitToCart({
			id: randomId,
			name: `KIT-${dateStamp}`,
			children: [],
		});
	};

	moveRow = (dragSource, dropSource) => {
		const {
			updateKitQuantity,
			addProductToCart,
			deleteProduct,
			deleteKit,
			addKitToCart,
			sortItems,
			addSubKit,
			removeSubKit,
			addSubKitItem,
			removeSubKitItem,
			addKitItem,
			removeKitItem,
		} = this;
		const { cart } = this.state;
		if (dragSource.type === 'item' && dropSource.type === 'item') {
			sortItems(dragSource, dropSource);
		} else if (dropSource.type === 'item' && dragSource.type === 'kit_item') {
			// Remove Item from Kit
			if (dragSource.parentKit) {
				removeSubKitItem(dragSource);
			} else {
				removeKitItem(dragSource);
			}

			const isItemPresent = cart.find(item => item.id === dragSource.id);

			if (isItemPresent) {
				addProductToCart({
					price: dragSource.price,
					id: dragSource.id,
					name: dragSource.name,
					quantity: isItemPresent.quantity + dragSource.quantity,
				});
			} else {
				addProductToCart({
					price: dragSource.price,
					id: dragSource.id,
					name: dragSource.name,
				});
			}
		} else if (dropSource.type === 'kit' && dragSource.type === 'item') {
			// Add Item to Kit
			deleteProduct(dragSource.id);
			addKitItem(dropSource.id, dragSource);
		} else if (dropSource.type === 'kit' && dragSource.type === 'kit_item') {
			// Move Items from one kit to another
			if (dropSource.id === dragSource.kit) {
				notification.warning({
					message: 'Invalid Drag and Drop',
					description: 'Item already exists in the kit.',
				});
			} else {
				const draggedFromKit = cart.find(item => item.id === dragSource.kit);
				if (draggedFromKit) {
					removeKitItem(dragSource);
					addKitItem(dropSource.id, dragSource);
				} else {
					removeSubKitItem(dragSource);
					addKitItem(dropSource.id, dragSource);
				}
			}
		} else if (dropSource.type === 'kit_item' && dragSource.type === 'kit_item') {
			// Move Items from one kit to another but dropped on kit_item
			if (dropSource.kit === dragSource.kit) {
				if (dropSource.parentKit && dragSource.parentKit) {
					const associatedParentKit = cart.find(item => item.id === dragSource.parentKit);
					const associatedKit = associatedParentKit.children.find(
						item => item.id === dragSource.kit,
					);
					const associatedKitIndex = associatedParentKit.children.findIndex(
						item => item.id === dragSource.kit,
					);
					const droppedIndex = associatedKit.children.findIndex(item => item.id === dropSource.id);
					const draggedIndex = associatedKit.children.findIndex(item => item.id === dragSource.id);
					const droppedItem = associatedKit.children[droppedIndex];
					const draggedItem = associatedKit.children[draggedIndex];

					associatedKit.children[draggedIndex] = droppedItem;
					associatedKit.children[droppedIndex] = draggedItem;

					associatedParentKit[associatedKitIndex] = associatedKit;
					updateKitQuantity(associatedParentKit);
				} else {
					const associatedKit = cart.find(item => item.id === dragSource.kit);
					const droppedIndex = associatedKit.children.findIndex(item => item.id === dropSource.id);
					const draggedIndex = associatedKit.children.findIndex(item => item.id === dragSource.id);
					const droppedItem = associatedKit.children[droppedIndex];
					const draggedItem = associatedKit.children[draggedIndex];

					associatedKit.children[draggedIndex] = droppedItem;
					associatedKit.children[droppedIndex] = draggedItem;

					updateKitQuantity(associatedKit);
				}
				return;
			} else {
				if (!dropSource.parentKit && !dragSource.parentKit) {
					removeKitItem(dragSource);
					addKitItem(dropSource.id, dragSource);
				} else {
					if (dropSource.parentKit && dragSource.parentKit) {
						// nested kits transaction
						addSubKitItem(dropSource.parentKit, dropSource.id, dragSource);
						removeSubKitItem(dragSource);
					} else if (dropSource.parentKit && !dragSource.parentKit) {
						// drag from normal to nested
						removeKitItem(dragSource);
						addSubKitItem(dropSource.parentKit, dropSource.id, dragSource);
					} else {
						// drag from nested to normal
						removeSubKitItem(dragSource);
						addKitItem(dropSource.id, dragSource);
					}
				}
			}
		} else if (dropSource.type === 'kit_item' && dragSource.type === 'item') {
			if (!dropSource.parentKit) {
				addKitItem(dropSource.kit, dragSource);
			} else {
				addSubKitItem(dropSource.parentKit, dropSource.kit, dragSource);
			}
			deleteProduct(dragSource.id);
		} else if (dropSource.type === 'kit' && dragSource.type === 'kit') {
			if (dropSource.id !== dragSource.id) {
				addSubKit(dropSource.id, dragSource);
				deleteKit(dragSource.id);
			}
		} else if (dropSource.type === 'item' && dragSource.type === 'child_kit') {
			removeSubKit(dragSource.kit_associated, dragSource.id);
			addKitToCart({
				...dragSource,
				type: 'kit',
			});
		} else if (dropSource.type === 'kit_item' && dragSource.type === 'kit') {
			if (dropSource.kit === dragSource.id || dropSource.parentKit === dragSource.id) {
				return;
			}
			addSubKit(dropSource.parentKit || dropSource.kit, dragSource);
			deleteKit(dragSource.id);
		} else if (dropSource.type === 'child_kit' && dragSource.type === 'item') {
			addSubKitItem(dropSource.kit_associated, dropSource.id, dragSource);
			deleteProduct(dragSource.id);
		} else if (dropSource.type === 'child_kit' && dragSource.type === 'kit_item') {
			if (dropSource.id === dragSource.kit) {
				return;
			}

			if (dragSource.parentKit) {
				removeSubKitItem(dragSource);
			} else {
				removeKitItem(dragSource);
			}

			addSubKitItem(dropSource.kit_associated, dropSource.id, dragSource);
		} else if (dropSource.type === 'kit' && dragSource.type === 'child_kit') {
			if (dragSource.kit_associated === dropSource.id) {
				return;
			}

			removeSubKit(dragSource.kit_associated, dragSource.id);
			addSubKit(dropSource.id, dragSource);
		} else if (dropSource.type === 'child_kit' && dragSource.type === 'kit') {
			if (dropSource.kit_associated === dragSource.id) {
				return;
			}

			addSubKit(dropSource.kit_associated, dragSource);
			deleteKit(dragSource.id);
		} else if (dropSource.type === 'child_kit' && dragSource.type === 'child_kit') {
			if (dropSource.kit_associated === dragSource.kit_associated) {
				const kitAssociated = cart.find(item => item.id === dropSource.kit_associated);
				const droppedIndex = kitAssociated.children.findIndex(item => item.id === dropSource.id);
				const draggedIndex = kitAssociated.children.findIndex(item => item.id === dragSource.id);

				const draggedItem = kitAssociated.children[draggedIndex];
				const droppedItem = kitAssociated.children[droppedIndex];

				kitAssociated.children[droppedIndex] = draggedItem;
				kitAssociated.children[draggedIndex] = droppedItem;
				updateKitQuantity(kitAssociated);
				return;
			}

			addSubKit(dropSource.kit_associated, dragSource);
			removeSubKit(dragSource.kit_associated, dragSource.id);
		}
	};

	render() {
		const { tableProps, primaryColor, secondaryColor, accentColor } = this.props;
		const { newKey, cart } = this.state;
		const theme = getTheme(primaryColor, secondaryColor, accentColor);

		return (
			<DndProvider backend={MultiBackend} options={HTML5toTouch}>
				<Table
					rowKey={item => {
						if (item.type === 'kit_item') {
							return item.id + item.kit;
						}
						if (item.type === 'child_kit') {
							return item.id + item.kit + item.kit_associated;
						}
						return item.id + item.type;
					}}
					key={newKey}
					bordered
					locale={{
						emptyText: (
							<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No items present in Cart" />
						),
					}}
					scroll={{ x: window.innerWidth < 768 ? 800 : 0 }}
					columns={this.columns}
					dataSource={cart}
					pagination={false}
					components={{ body: { row: DraggableBodyRow } }}
					onRow={(record, index) => ({
						index,
						record,
						moveRow: dragSource => this.moveRow(dragSource, record),
					})}
					footer={() => (
						<div className="bom-footer">
							<div className="footer-button-margin">
								<Button onClick={this.addKit} style={{ marginRight: 10 }}>
									Add Kit
								</Button>
								<Link to="/checkout">
									<Button
										type="primary"
										style={{
											background: theme.palette.secondary.main,
											borderColor: theme.palette.secondary.main,
											color: theme.palette.secondary.contrastText,
										}}
									>
										<Icon type="shopping-cart" />
										Proceed to Buy
									</Button>
								</Link>
							</div>
							<div style={{ fontWeight: 500 }}>
								Price Total: ${getTotal(cart, item => Number(item.price || 0) * item.quantity)}
							</div>
						</div>
					)}
					{...tableProps}
				/>
			</DndProvider>
		);
	}
}

export default DraggableCart;
