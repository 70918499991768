import React from 'react';
import { List } from 'antd';
import OrderRow from './OrderRow';
import { connect } from 'react-redux';

function OrderList({ orders = [] }) {
	return (
		<div className="bom-container">
			<List
				bordered
				dataSource={orders}
				renderItem={(item, index) => (
					<List.Item>
						<List.Item.Meta description={<OrderRow orderNo={item.id} cart={item.cart} />} />
					</List.Item>
				)}
			/>
		</div>
	);
}

const mapStateToProps = state => ({
	orders: state.orders,
});

export default connect(mapStateToProps)(OrderList);
