import auth0 from 'auth0-js';
import { authConfig } from '../constants/config';

export default class Auth {
	constructor({ domain, clientID, audience }) {
		this.auth0 = new auth0.WebAuth({
			domain,
			clientID,
			redirectUri: `${window.location.origin}/authenticate`,
			audience,
			responseType: 'token id_token',
			scope:
				'openid profile email read:current_user read:current_user_metadata update:current_user_metadata',
		});
	}
	login() {
		this.auth0.authorize();
	}
	logout() {
		this.auth0.logout({
			returnTo: window.location.origin,
		});
	}
	patchUserMetadata(token, userId, data, cb) {
		this.authManagement = new auth0.Management({
			domain: authConfig.domain,
			token,
		});
		this.authManagement.patchUserMetadata(userId, data, cb);
	}
	refreshToken() {
		this.auth0.checkSession({}, function(err, authResult) {
			// Authentication tokens or error
		});
	}
	parseTokens() {
		return new Promise((resolve, reject) =>
			this.auth0.parseHash((err, result) => {
				if (err) {
					reject(err);
				} else {
					resolve({ ...result, expiresAt: result.expiresIn * 1000 + new Date().getTime() });
				}
			}),
		);
	}
}
