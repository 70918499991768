export const ref = {};

export function setRef(name, currRef) {
	if (!name) {
		throw new Error(`${name} is not defined.`);
	}

	if (!currRef) {
		throw new Error(`${currRef} is not defined.`);
	}

	ref[name] = currRef;
}

export const removeRef = name => delete ref[name];

export const hasRef = name => !!ref[name];

export const useCustomRef = name => {
	return name ? ref[name] : ref;
};
