import { get } from 'lodash';

import { SIDEBAR } from '../constants';
import { leftSidebarContent } from '../constants/sidebar';

const initialState = {
	home: {
		left: {
			open: true,
			view: leftSidebarContent.Facets,
		},
		right: {
			open: false,
			view: null,
		},
	},
	global: {
		left: {
			open: false,
			view: null,
		},
		right: {
			open: false,
			view: null,
		},
	},
};

const sidebarReducer = (state = initialState, action) => {
	switch (action.type) {
		case SIDEBAR.RESET:
			return initialState;
		case SIDEBAR.UPDATE_LEFT_HOME: {
			return {
				...state,
				home: {
					...state.home,
					left: {
						open: true,
						view: action.payload,
					},
				},
			};
		}
		case SIDEBAR.UPDATE_RIGHT_HOME: {
			return {
				...state,
				home: {
					...state.home,
					right: {
						open: true,
						view: action.payload,
					},
				},
			};
		}
		case SIDEBAR.TOGGLE_LEFT_HOME: {
			return {
				...state,
				home: {
					...state.home,
					left: {
						...state.left,
						open: !get(state, 'home.left.open'),
					},
				},
			};
		}
		case SIDEBAR.TOGGLE_RIGHT_HOME: {
			return {
				...state,
				home: {
					...state.home,
					right: {
						...state.right,
						open: !get(state, 'home.right.open'),
					},
				},
			};
		}
		case SIDEBAR.UPDATE_LEFT_GLOBAL: {
			return {
				...state,
				global: {
					...state.global,
					left: {
						open: true,
						view: action.payload,
					},
				},
			};
		}
		case SIDEBAR.UPDATE_RIGHT_GLOBAL: {
			return {
				...state,
				global: {
					...state.global,
					right: {
						open: true,
						view: action.payload,
					},
				},
			};
		}
		case SIDEBAR.TOGGLE_LEFT_GLOBAL: {
			return {
				...state,
				global: {
					...state.global,
					left: {
						...state.left,
						open: !get(state, 'global.left.open'),
					},
				},
			};
		}
		case SIDEBAR.TOGGLE_RIGHT_GLOBAL: {
			return {
				...state,
				global: {
					...state.global,
					right: {
						...state.right,
						open: !get(state, 'global.right.open'),
					},
				},
			};
		}
		default:
			return state;
	}
};

export default sidebarReducer;
