import React from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isMobile from 'ismobilejs';
import CategoryMap from '../category-filter/category-map';
import { ReactComponent as Cart } from '../../icons/cart.svg';
import { getTheme } from '../theme-controller';
import { normalizeProductData } from '../add-cart';
import { addProduct, updateProduct } from '../../actions';

const customInput = {
	padding: '5px',
	outline: 0,
	borderRadius: 3,
	border: '1px solid #ddd',
	boxSizing: 'border-box',
	width: '100%',
};

class Suggestions extends React.PureComponent {
	state = {
		unit: '',
	};

	handleUnitChange = e => {
		e.stopPropagation();
		const value = e.target.value;
		this.setState({
			unit: isNaN(parseInt(value)) ? '' : parseInt(value),
		});
	};

	handleInputClick = e => {
		e.stopPropagation();
		this.textInput.focus();
	};

	render() {
		const {
			suggestion,
			showProfileLabel,
			source,
			renderAddProduct,
			addProductToCart,
			cart,
			updateCartProduct,
		} = this.props;
		const { unit } = this.state;
		const theme = getTheme(
			this.props.primaryColor,
			this.props.secondaryColor,
			this.props.accentColor,
		);
		const showLabel = window.innerWidth > 800;
		const isHome = source === 'home';
		return (
			<div
				key={suggestion._id}
				className="flex"
				style={{
					width: '100%',
					borderBottom: '1px solid #dfdfdf',
					padding: '3px 10px',
					justifyContent: 'space-around',
					alignItems: 'center',
				}}
			>
				<div
					style={{
						...(CategoryMap[suggestion._source['Profile_space_Group']] && {
							backgroundImage: `url(${CategoryMap[suggestion._source['Profile_space_Group']]})`,
						}),
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
						backgroundPosition: 'center center',
						height: '20px',
						width: '20px',
						borderRadius: '3px',
						backgroundColor: 'grey',
					}}
				/>
				<div
					style={{
						marginLeft: '5px',
						width: isMobile.any ? '60%' : isHome ? '60%' : '30%',
						...(!isMobile.any && {
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}),
					}}
				>
					{suggestion._source['P21_space_Part_space_#'] || suggestion._source['Vendor_space_Part#']}
				</div>
				<div
					style={{
						marginLeft: '5px',
						...(!isMobile.any && {
							width: '20%',
						}),
					}}
				>
					{showLabel && showProfileLabel && suggestion._source.Profile && (
						<div
							style={{
								borderRadius: 10,
								backgroundColor: theme.palette.secondary.main,
								color: '#fff',
								marginTop: 2,
								padding: '2px 7px',
								display: 'inline-block',
								...(!isMobile.any && {
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
								}),
							}}
						>
							{suggestion._source.Profile}
						</div>
					)}
				</div>
				{!isHome && !isMobile.any && (
					<div
						style={{
							marginLeft: '5px',
							width: '30%',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}}
					>
						{suggestion._source['Description'] ||
							suggestion._source['Description_space_2'] ||
							suggestion._source['Description_space_3'] ||
							suggestion._source['Description_space_4']}
					</div>
				)}
				{!isMobile.any && (
					<div
						style={{
							marginLeft: '5px',
							width: isHome ? '20%' : '10%',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}}
					>
						${suggestion._source['Unit_space_Price'] || 0}
					</div>
				)}
				<div
					style={{
						marginLeft: '5px',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						width: 60,
						position: 'relative',
					}}
				>
					<input
						ref={input => {
							this.textInput = input;
						}}
						onClick={this.handleInputClick}
						placeholder="Qty"
						style={customInput}
						value={unit}
						onChange={this.handleUnitChange}
					/>
				</div>
				{renderAddProduct ? (
					renderAddProduct(unit === '' ? 1 : unit)
				) : (
					<Button
						onClick={e => {
							e.preventDefault();
							e.stopPropagation();
							const isItemPresent = cart.find(
								item => item.id === suggestion._id && item.type === 'item',
							);
							const quantity = unit ? unit : 1;
							if (isItemPresent) {
								updateCartProduct({
									...isItemPresent,
									quantity: isItemPresent.quantity + quantity,
								});
							} else {
								addProductToCart(
									normalizeProductData({ ...suggestion, ...suggestion._source, quantity }),
								);
							}
						}}
						style={{
							minWidth: 0,
							minHeight: 0,
							paddingBottom: '2px',
							paddingTop: '2px',
							textTransform: 'none',
							marginLeft: '5px',
						}}
						size="small"
						variant="outlined"
					>
						Add <Cart />
					</Button>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	primaryColor: get(state, 'user.user.preferences.color_scheme.primary'),
	secondaryColor: get(state, 'user.user.preferences.color_scheme.secondary'),
	accentColor: get(state, 'user.user.preferences.color_scheme.accent'),
	cart: get(state, 'cart', []),
});

const mapDispatchToProps = dispatch => ({
	addProductToCart: product => dispatch(addProduct(product)),
	updateCartProduct: product => dispatch(updateProduct(product)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Suggestions);
