import { MultiList, RangeInput } from '@appbaseio/reactivesearch';
import componentIds from './componentIds';
import AssetsReactiveBase from '../components/AssetsReactiveBase';

export const facets = {
	Company: {
		type: 'menu',
		icon: 'company',
		facets: {
			Manufacturers: {
				component: MultiList,
				showCheckbox: false,
				showSearch: false,
				componentId: 'Curators',
				dataField: 'Curators.keyword',
			},
			OEM: {
				component: MultiList,
				showCheckbox: false,
				showSearch: false,
				componentId: 'OEM',
				dataField: 'OEM.keyword',
			},
			Customer: {
				react: { and: ['Account Name'] },
				skipReacting: true,
				component: MultiList,
				showCheckbox: false,
				showSearch: true,
				aggregationSize: 1000,
				sortBy: 'asc',
				showCount: false,
				skipReactDependency: true,
				componentId: 'Account Name',
				dataField: 'name.keyword',
			},
			Couriers: {
				component: MultiList,
				showCheckbox: false,
				showSearch: false,
				componentId: 'Couriers',
				dataField: 'Couriers.keyword',
			},
		},
	},
	People: {
		type: 'menu',
		icon: 'people',
		facets: {
			Contacts: {
				type: 'menu',
				facets: {
					People: {
						component: MultiList,
						react: { and: ['Full Name (Transfer to First/Last)'] },
						skipReacting: true,
						showCheckbox: false,
						showSearch: true,
						aggregationSize: 1000,
						sortBy: 'asc',
						skipReactDependency: true,
						componentId: 'Full Name (Transfer to First/Last)',
						dataField: 'fullname.keyword',
					},
					Manufacturers: {
						component: MultiList,
						showCheckbox: false,
						showSearch: false,
						componentId: 'Curators',
						dataField: 'Curators.keyword',
					},
				},
			},
			Experts: {
				component: MultiList,
				showCheckbox: false,
				showSearch: false,
				componentId: 'Experts',
				dataField: 'Experts.keyword',
			},
		},
	},
	Product: {
		useDynamicFacets: true,
		type: 'menu',
		icon: 'product',
		facets: {
			Categories: {
				type: 'menu',
				icon: 'categories',
				facets: {
					'Profile Group': {
						component: MultiList,
						showCheckbox: false,
						showSearch: false,
						icon: 'profile_group',
						componentId: 'Profile_space_Group',
						dataField: 'Profile_space_Group.keyword',
						filterLabel: 'Profile Group',
					},
					Profile: {
						component: MultiList,
						showCheckbox: false,
						showSearch: false,
						icon: 'profile_group',
						componentId: 'Profile',
						dataField: 'Profile.keyword',
					},
				},
			},
			Dimensions: {
				type: 'menu',
				icon: 'dimensions',
				facets: {
					ID: {
						component: RangeInput,
						componentId: 'id',
						dataField: 'ID_open_d_close__space_-_space_Nominal_numeric',
						range: {
							start: 0,
							end: 1000,
						},
						rangeLabels: {
							start: `${0}mm`,
							end: `${1000}mm`,
						},
						defaultValue: {
							start: 0,
							end: 1000,
						},
					},
					OD: {
						component: RangeInput,
						componentId: 'od',
						dataField: 'OD_open_mm_close__space_-_space_Nominal_numeric',
						range: {
							start: 0,
							end: 1000,
						},
						rangeLabels: {
							start: `${0}mm`,
							end: `${1000}mm`,
						},
						defaultValue: {
							start: 0,
							end: 1000,
						},
					},
					Height: {
						component: RangeInput,
						componentId: 'height',
						dataField: 'Height_open_mm_close__space_-_space_Nominal',
						range: {
							start: 0,
							end: 100,
						},
						rangeLabels: {
							start: `${0}mm`,
							end: `${100}mm`,
						},
						defaultValue: {
							start: 0,
							end: 100,
						},
					},
				},
			},
			Technical: {
				type: 'menu',
				icon: 'technical',
				facets: {
					Application: {
						component: MultiList,
						icon: 'application',
						showCheckbox: false,
						showSearch: false,
						componentId: 'Application',
						dataField: 'Application.keyword',
						placeholder: true,
					},
					'Fluid Type': {
						component: MultiList,
						icon: 'fluid_type',
						showCheckbox: false,
						showSearch: false,
						componentId: 'Fluid Type',
						dataField: 'Fluid_space_Type.keyword',
						placeholder: true,
					},
					Compound: {
						component: MultiList,
						icon: 'compound',
						showCheckbox: false,
						showSearch: false,
						componentId: 'Compound',
						dataField: 'Compound.keyword',
						placeholder: true,
					},
					'Material Compatibility': {
						component: MultiList,
						icon: 'material_compatibility',
						showCheckbox: false,
						showSearch: false,
						componentId: 'Material',
						dataField: 'Material_space_#1.keyword',
						placeholder: true,
					},

					Durometer: {
						component: MultiList,
						showCheckbox: false,
						icon: 'durometer_1',
						showSearch: false,
						componentId: 'Durometer',
						dataField: 'Durometer.keyword',
						placeholder: true,
					},
					Pressure: {
						component: MultiList,
						showCheckbox: false,
						icon: 'pressure',
						showSearch: false,
						componentId: 'Pressure',
						dataField: 'Pressure_space__open_PSI_close_.keyword',
						placeholder: true,
					},
					Temperature: {
						component: RangeInput,
						icon: 'temperature',
						componentId: 'temperature',
						dataField: 'Temperature_space_+C',
						placeholder: true,
						range: {
							start: 0,
							end: 1000,
						},
						rangeLabels: {
							start: `${0} C`,
							end: `${1000} C`,
						},
						defaultValue: {
							start: 0,
							end: 1000,
						},
					},
				},
			},
		},
	},
	Assets: {
		type: 'menu',
		icon: 'people',
		facets: {
			Equipment: {
				component: MultiList,
				skipReacting: true,
				showCheckbox: false,
				showSearch: true,
				aggregationSize: 1000,
				sortBy: 'asc',
				skipReactDependency: true,
				componentId: 'Equipment',
				dataField: 'Equipment.keyword',
				react: { and: ['Make', 'Equipment', 'Model'] },
			},
			Make: {
				component: MultiList,
				skipReacting: true,
				showCheckbox: false,
				showSearch: true,
				aggregationSize: 1000,
				sortBy: 'asc',
				skipReactDependency: true,
				componentId: 'Make',
				dataField: 'Make.keyword',
				react: { and: ['Make', 'Equipment', 'Model'] },
			},
			Model: {
				component: MultiList,
				skipReacting: true,
				showCheckbox: false,
				showSearch: true,
				aggregationSize: 1000,
				sortBy: 'asc',
				componentId: 'Model',
				skipReactDependency: true,
				dataField: 'Model',
				react: { and: ['Make', 'Equipment', 'Model'] },
			},
		},
	},
	Solutions: {
		type: 'list',
		icon: 'tools',
		items: {
			'Product Configurator': {
				type: 'link',
				icon: 'product_config',
				iconType: 'png',
				link: '/configurator',
			},
			Courier: {
				type: 'link',
				icon: 'courier',
				iconType: 'png',
				link: '/',
			},
			Calculator: {
				type: 'link',
				icon: 'calculator',
				iconType: 'png',
				link: '/',
			},
			Scheduler: {
				type: 'link',
				icon: 'scheduler',
				iconType: 'png',
				link: '/',
			},
			Equipment: {
				type: 'popover',
				icon: 'material_selector',
				iconType: 'png',
				image: 'https://i.imgur.com/W816q8K.png',
			},
			'Compound Expert': {
				type: 'popover',
				icon: 'compound',
				image: 'https://i.imgur.com/2Soerau.png',
			},
			Connect: {
				type: 'menu',
				icon: 'connect',
				iconType: 'png',
				content: {
					type: 'list',
					items: {
						API: {
							type: 'popover',
							image: 'https://i.imgur.com/VrjuYyK.png',
						},
						BOM: {
							type: 'popover',
							image: 'https://i.imgur.com/WxfU2Ds.png',
						},
					},
				},
			},
		},
	},
};

const flattenArray = source => {
	return source.reduce((agg, item) => {
		const values = Array.isArray(item) ? flattenArray(item) : [item];

		return [...agg, ...values];
	}, []);
};

const getNestedIds = facetsData => {
	const unFlattedIds = Object.keys(facetsData)
		.filter(item => facetsData[item].type !== 'list')
		.map(item => facetsData[item].facets || [])
		.reduce((agg, item) => {
			const facetIds = Object.keys(item).map(facetName => {
				if (item[facetName].type === 'menu') {
					return getNestedIds({
						[facetName]: item[facetName],
					});
				}

				// if (item[facetName].hiddenReactableComponent) {
				// 	return [item[facetName].componentId, `hidden-${item[facetName].componentId}`];
				// }

				return item[facetName].skipReacting ? [] : item[facetName].componentId;
			});
			return [...agg, ...facetIds];
		}, []);

	return flattenArray(unFlattedIds);
};

const facetComponentIds = getNestedIds(facets);

export const getComponentReactDependency = idToExclude => {
	const allAndIds = [...new Set([...componentIds.and, ...facetComponentIds])].filter(
		id => id !== idToExclude,
	);
	const allOrIds = [...new Set([...componentIds.or])].filter(id => id !== idToExclude);

	return {
		and: allAndIds,
		or: allOrIds,
	};
};

export const productIndex = 'Product';
