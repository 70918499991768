import React, { Component } from 'react';
import { ReactiveBase, StateProvider } from '@appbaseio/reactivesearch';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import get from 'lodash/get';
import { isEqual } from 'lodash';
import Nav from '../nav';
import SearchContainer from '../search-container';
import ThemeController, { getTheme } from '../theme-controller';
import HelpButton from '../Help';
import {
	APP_NAME,
	pagesWithSearch,
	viewsByPath,
	CLUSTER_PASSWORD,
	CLUSTER_USERNAME,
	CLUSTER_URL,
	pathByView,
} from '../../constants/config';

import ProductPage from '../../pages/product';
import ProductConfigurator from '../../pages/configurator';
import BOMView from '../../pages/bom';
import Account from '../../pages/account';
import Checkout from '../../pages/checkout';
import OrderList from '../../pages/order';
import EditableOrder from '../../pages/order/EditableOrder';
import {
	addDynamicFacets,
	resetAllSidebar,
	toggleHomeLeftSidebar,
	updateSubView,
} from '../../actions';
import AppLayout from './AppLayout';
import {
	companyFacets,
	peopleFacets,
	assetsFacets,
	PEOPLE_INDEX,
	COMPANY_INDEX,
	ASSET_INDEX,
} from '../../utils';
import GlobalSidebar from '../side-bar/GlobalSidebar';
// import AdminWrapper from './AdminWrapper';

class AppWrapper extends Component {
	state = {
		currentHeight: [0, 100],
		currentOD: [0, 1000],
		currentID: [0, 1000],
		currentRule: null,
		results: null,
		prevLocation: null,
	};

	componentDidUpdate(prevProps) {
		const { toggleAllSidebar, currentView, toggleMobileSidebar } = this.props;
		const prevLocation = get(prevProps, 'location.pathname');
		const currLocation = get(this.props, 'location.pathname');

		if (currentView && prevLocation && currLocation && prevLocation !== currLocation) {
			if (window.innerWidth < 768) {
				toggleMobileSidebar();
			} else {
				toggleAllSidebar();
			}
		}
	}

	handleSearchChange = dimensions => {
		this.setState({
			currentID: [dimensions[0], dimensions[1]],
			currentOD: [dimensions[2], dimensions[3]],
			currentHeight: [dimensions[4], dimensions[5]],
		});
	};

	handleViewUpdate = ({ customData: data, resultStats }) => {
		const { location, history, addFacets, dynamicFacets, updateView } = this.props;
		const { currentRule, results } = this.state;

		if (results === resultStats.numberOfResults) {
			return;
		}

		if (
			(!Array.isArray(data) && Object.keys(data).length === 0) ||
			(Array.isArray(data) && data.length === 0)
		) {
			const { addFacets } = this.props;
			addFacets([]);
			return;
		}

		const defaultView = get(data, 'default_view', '');
		const mainView = defaultView.split(':')[0];
		const route = pathByView[mainView];
		if (
			defaultView &&
			route &&
			viewsByPath[location.pathname] !== mainView &&
			currentRule !== `${data.advanced_expression_2}${data.advanced_expression_1}`
		) {
			// TODO: Review if commenting this has a side effect
			// history.push(`${route}${window.location.search}`);
		}

		// TODO: Review if commenting this has a side effect
		// if (defaultView) {
		// 	updateView(defaultView);
		// }
		if (JSON.stringify(dynamicFacets) !== JSON.stringify(data.facets)) {
			addFacets(data && Array.isArray(data.facets) && data.facets.length > 0 ? data.facets : []);
		}
		this.setState({
			currentRule: `${data.advanced_expression_2}${data.advanced_expression_1}`,
			results: resultStats.numberOfResults,
		});
	};

	resetRule = () => {
		this.setState({
			currentRule: null,
		});
	};

	handleStateChange = (prevState, nextState) => {
		const { prevLocation } = this.state;
		if (!isEqual(prevState, nextState)) {
			if (prevLocation !== `${window.location.pathname}${window.location.search}`) {
				this.setState({
					prevLocation: `${window.location.pathname}${window.location.search}`,
				});
			}
			window.ga('send', 'pageview');
		}
	};

	render() {
		const { currentHeight, currentID, currentOD, prevLocation } = this.state;
		const { token, location } = this.props;
		const newProps = {
			currentHeight,
			currentOD,
			currentID,
		};
		const theme = getTheme(this.props.primaryColor, this.props.secondaryColor);
		const authToken = process.env.REACT_APP_CI ? process.env.REACT_APP_TOKEN : token;
		const showSearch = pagesWithSearch.includes(window.location.pathname);
		return (
			<ThemeController location={location}>
				<ReactiveBase
					app={APP_NAME}
					url={CLUSTER_URL}
					theme={{
						colors: {
							backgroundColor: theme.palette.background.default,
							primaryColor: theme.palette.background.default,
							titleColor: theme.textColor,
							textColor: theme.textColor,
							primaryTextColor: theme.textColor,
						},
					}}
					credentials={`${CLUSTER_USERNAME}:${CLUSTER_PASSWORD}`}
					// headers={{
					// 	'x-auth0-token': authToken,
					// }}
					enableAppbase
					mapKey="AIzaSyBQdVcKCe0q_vOBDUvJYpzwGpt_d_uTj4Q"
					appbaseConfig={{
						recordAnalytics: true,
						suggestionAnalytics: true,
					}}
					transformRequest={data => {
						const parsedQuery = JSON.parse(data.body);

						if (parsedQuery.query && parsedQuery.query.length === 1) {
							const { query } = parsedQuery;

							const { execute, id } = query[0];

							if (execute === true && companyFacets.includes(id)) {
								const urlObj = new URL(data.url);

								const { protocol, hostname, pathname } = urlObj;

								return {
									...data,
									url: `${protocol}//${hostname}/${COMPANY_INDEX}/${pathname.split('/').pop()}`,
								};
							}

							if (execute === true && peopleFacets.includes(id)) {
								const urlObj = new URL(data.url);

								const { protocol, hostname, pathname } = urlObj;

								return {
									...data,
									url: `${protocol}//${hostname}/${PEOPLE_INDEX}/${pathname.split('/').pop()}`,
								};
							}
						}

						if (parsedQuery.query) {
							const isAssets = parsedQuery.query.reduce(
								(acc, qs) => acc && assetsFacets.includes(qs.id),
								true,
							);

							if (isAssets) {
								const urlObj = new URL(data.url);

								const { protocol, hostname, pathname } = urlObj;

								return {
									...data,
									url: `${protocol}//${hostname}/${ASSET_INDEX}/${pathname.split('/').pop()}`,
								};
							}
						}

						return data;
					}}
				>
					<React.Fragment>
						<Nav handleSearchChange={this.handleSearchChange} />
						{showSearch && (
							<SearchContainer
								resetRule={this.resetRule}
								handleSearchChange={this.handleSearchChange}
							/>
						)}
					</React.Fragment>

					<div className="layout">
						<GlobalSidebar direction="left" />
						<AppLayout
							{...newProps}
							showSidebar={showSearch || window.innerWidth < 768}
							handleViewUpdate={this.handleViewUpdate}
						/>
						<Route exact path="/account" component={() => <Account {...newProps} />} />
						<Route exact path="/cart" component={() => <BOMView {...newProps} />} />
						<Route exact path="/checkout" component={() => <Checkout {...newProps} />} />
						<Route
							exact
							path="/product/:productId"
							component={() => <ProductPage {...newProps} prevLocation={prevLocation} />}
						/>
						<Route
							exact
							path="/configurator"
							component={() => <ProductConfigurator {...newProps} />}
						/>
						<Route exact path="/orders/:id" component={() => <EditableOrder {...newProps} />} />
						<Route exact path="/orders" component={() => <OrderList {...newProps} />} />

						<GlobalSidebar direction="right" />
					</div>

					{/* <Route
							path="/admin-restricted-page"
							component={() => (
								<AdminWrapper>
									<RandomAdminPage {...newProps} />
								</AdminWrapper>
							)}
						/> */}

					<StateProvider onChange={this.handleStateChange} />
				</ReactiveBase>
				<HelpButton />
			</ThemeController>
		);
	}
}

const mapStateToProps = state => {
	return {
		token: get(state, 'user.user.jwt_token'),
		primaryColor: get(state, 'user.user.preferences.color_scheme.primary'),
		userEmail: get(state, 'user.user.email', ''),
		dynamicFacets: get(state, 'user.dynamic_facets', []),
		secondaryColor: get(state, 'user.user.preferences.color_scheme.secondary'),
		currentView: get(state, 'sidebar.home.left.view'),
	};
};

const mapDispatchToProps = dispatch => ({
	addFacets: facets_data => dispatch(addDynamicFacets(facets_data)),
	toggleAllSidebar: () => dispatch(resetAllSidebar()),
	toggleMobileSidebar: () => dispatch(toggleHomeLeftSidebar()),
	updateView: view => dispatch(updateSubView(view)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppWrapper));
