import React from 'react';
import { Route } from 'react-router-dom';

import Tabs from '../../pages/tabs';
import GroupView from '../../pages/group-view';
import CompareGroup from '../../pages/group-view/CompareGroup';
import Sidebar from '../side-bar';

const AppLayout = ({ currentHeight, showSidebar, currentID, currentOD, ...props }) => {
	return (
		<React.Fragment>
			{showSidebar && (
				<Sidebar
					direction="left"
					currentHeight={currentHeight}
					currentID={currentID}
					currentOD={currentOD}
				/>
			)}

			<Route exact path="/" render={() => <GroupView {...props} />} />
			<Route exact path="/recommend" render={() => <GroupView {...props} />} />
			<Route path="/compare" exact render={() => <Tabs {...props} />} />
			<Route exact path="/compare/group" render={() => <CompareGroup {...props} showDesc />} />

			{showSidebar && (
				<Sidebar
					direction="right"
					currentHeight={currentHeight}
					currentID={currentID}
					currentOD={currentOD}
				/>
			)}
		</React.Fragment>
	);
};

export default AppLayout;
