import React from 'react';
import get from 'lodash/get';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {
	MultiDropdownList,
	ResultList,
	ReactiveList,
	DynamicRangeSlider,
	SelectedFilters,
} from '@appbaseio/reactivesearch';
import './styles.css';
import { StepContent } from '@material-ui/core';
import Loader from '../../components/loader';
import { useCustomRef } from '../../utils/refService';

const styles = theme => ({
	container: {
		margin: '10px 0',
	},
	paper: {
		padding: '20px 0px',
	},
	button: {
		marginRight: theme.spacing.unit,
	},
	instructions: {
		marginTop: theme.spacing.unit,
		marginBottom: theme.spacing.unit,
	},
	title: {
		marginBottom: theme.spacing.unit,
		color: '#262626',
	},
	description: {
		color: '#8c8c8c',
	},
	results: {
		maxHeight: '250px',
		marginTop: '20px',
		overflowY: 'scroll',
	},
});

class Steps extends React.Component {
	constructor() {
		super();

		this.state = {
			activeStep: 0,
			values: {},
			key: 'on',
			hasMounted: true,
			application: [],
			profile: [],
			id: [null, null],
			od: [null, null],
			height: [null, null],
		};
		this.steps = [
			{
				label: 'Application',
				title: 'Choose Application',
				key: 'application',
				description: 'Choose a Application type.',
				render: (
					<MultiDropdownList
						key="application"
						componentId="application"
						dataField="Application.keyword"
						onQueryChange={(_, nextQuery) => console.log(nextQuery)}
						size={100}
						onValueChange={value => this.handleFilterValue(value, 'application')}
						style={{ marginTop: 10 }}
					/>
				),
			},
			{
				label: 'Seal/Kit',
				title: 'Choose a Seal/Kit',
				key: 'seal',
				description: 'Select your Seal or Kit.',
				render: (
					<>
						<Button style={{ marginRight: 8 }}>Yes</Button>
						<Button>No</Button>
					</>
				),
			},
			{
				label: 'Profile Group',
				title: 'Choose a Profile Group',
				key: 'profile',
				description: 'Select your Profile group.',
				render: (
					<MultiDropdownList
						key="profile"
						componentId="profile"
						dataField="Profile_space_Group.keyword"
						size={100}
						onValueChange={value => this.handleFilterValue(value, 'profile')}
						style={{ marginTop: 10 }}
					/>
				),
			},
			{
				label: 'Other Filters',
				title: 'Apply other Filters',
				key: 'filters',
				description: 'Choose a specific filters.',
				render: (
					<>
						<DynamicRangeSlider
							componentId="od"
							dataField="OD_open_D_close__space_-_space_Nominal_numeric"
							size={100}
							style={{ marginTop: 20 }}
							title="Outer Diameter"
							tooltipTrigger="hover"
							onValueChange={value => this.handleFilterValue(value, 'od')}
						/>
						<DynamicRangeSlider
							componentId="id"
							dataField="ID_open_d_close__space_-_space_Nominal_numeric"
							size={100}
							style={{ marginTop: 20 }}
							title="Inner Diameter"
							tooltipTrigger="hover"
							onValueChange={value => this.handleFilterValue(value, 'id')}
						/>

						<DynamicRangeSlider
							componentId="height"
							dataField="Height_open_h_close__space_-_space_Nominal_numeric"
							size={100}
							style={{ marginTop: 20 }}
							title="Height"
							tooltipTrigger="hover"
							onValueChange={value => this.handleFilterValue(value, 'height')}
						/>
					</>
				),
			},
		];
	}

	handleFilterValue = (value, name) => {
		this.setState({
			[name]: value,
		});
	};

	handleNext = () => {
		const { activeStep, key } = this.state;
		this.setState({
			activeStep: activeStep + 1,
			key: key === 'on' ? 'off' : 'on',
			hasMounted: false,
		});

		setTimeout(() => {
			this.setState({
				hasMounted: true,
			});
		}, 100);
	};

	handleBack = () => {
		this.setState(state => ({
			activeStep: state.activeStep - 1,
			key: state.key === 'on' ? 'off' : 'on',
			hasMounted: false,
		}));

		setTimeout(() => {
			this.setState({ hasMounted: true });
		}, 100);
	};

	handleButton = (value, key) => {
		this.setState(prevState => ({
			values: {
				...prevState.values,
				[key]: value,
			},
		}));
	};

	getQuery = () => {
		const { id, od, height, application, profile } = this.state;
		const rangeQuery = [
			{
				bool: {
					should: [
						{
							range: {
								'OD_open_D_close__space_-_space_Nominal_numeric': {
									gte: od[0],
									lte: od[1],
									boost: 2,
								},
							},
						},
						{
							bool: {
								must_not: { exists: { field: 'OD_open_D_close__space_-_space_Nominal_numeric' } },
							},
						},
					],
				},
			},
			{
				bool: {
					should: [
						{
							range: {
								'Height_open_h_close__space_-_space_Nominal_numeric': {
									gte: height[0],
									lte: height[1],
									boost: 2,
								},
							},
						},
						{
							bool: {
								must_not: {
									exists: { field: 'Height_open_h_close__space_-_space_Nominal_numeric' },
								},
							},
						},
					],
				},
			},
			{
				bool: {
					should: [
						{
							range: {
								'ID_open_d_close__space_-_space_Nominal_numeric': {
									gte: id[0],
									lte: id[1],
									boost: 2,
								},
							},
						},
						{
							bool: {
								must_not: { exists: { field: 'ID_open_d_close__space_-_space_Nominal_numeric' } },
							},
						},
					],
				},
			},
		];

		const applicationQuery = { terms: { 'Application.keyword': application } };
		const profileQuery = { terms: { 'Profile_space_Group.keyword': profile } };

		let finalQuery = [...rangeQuery];

		if (application.length) {
			finalQuery = [applicationQuery, finalQuery];
		}

		if (profile.length) {
			finalQuery = [profileQuery, finalQuery];
		}

		const query = {
			track_total_hits: true,
			query: {
				bool: {
					must: [
						{
							bool: {
								must: finalQuery,
							},
						},
					],
				},
			},
			size: 5,
			_source: { includes: ['*'], excludes: [] },
			from: 0,
		};

		return query;
	};

	clearFilter = () => {
		useCustomRef('facets').triggerUpdate();
	};

	render() {
		const { classes, moveForward } = this.props;
		const { activeStep, hasMounted, key } = this.state;
		return (
			<Grid container spacing={8}>
				<Grid item xs={6} className={classes.container}>
					{activeStep === this.steps.length ? (
						<Paper style={{ padding: 20 }} elevation={0} square>
							<Typography className={classes.instructions}>
								Congrats! You've completed the Customization of Product.
							</Typography>
						</Paper>
					) : (
						<Stepper activeStep={activeStep} orientation="vertical">
							{this.steps.map(step => {
								return (
									<Step key={step.key}>
										<StepLabel>{step.label}</StepLabel>

										<StepContent>
											<Paper className={classes.paper} elevation={0} square>
												<Typography className={classes.title} variant="title">
													{this.steps[activeStep].title}
												</Typography>
												<Typography className={classes.description} variant="body1">
													{this.steps[activeStep].description}
												</Typography>
												{hasMounted && this.steps[activeStep].render}
											</Paper>

											<div>
												<Button
													disabled={activeStep === 0}
													onClick={this.handleBack}
													className={classes.button}
												>
													Back
												</Button>
												<Button
													variant="contained"
													color="primary"
													onClick={this.handleNext}
													className={classes.button}
												>
													{activeStep === this.steps.length - 1 ? 'Finish' : 'Next'}
												</Button>
											</div>
										</StepContent>
									</Step>
								);
							})}
						</Stepper>
					)}
				</Grid>

				<Grid item xs={6} className={classes.container}>
					<Paper className={classes.paper} elevation={0} square>
						<Typography className={classes.title} variant="title">
							Items
						</Typography>
						<SelectedFilters showClearAll="default" onChange={this.clearFilter} />
						<ReactiveList
							defaultQuery={() => this.getQuery()}
							key={key}
							scrollTarget="resultContainer"
							componentId="result"
							dataField="_score"
							size={10}
							render={({ data, loading }) => (
								<div className={classes.results} id="resultContainer">
									{loading ? (
										<Loader />
									) : data.length ? (
										<ReactiveList.ResultListWrapper>
											{data.map(item => (
												<ResultList key={item._id}>
													<ResultList.Content>
														<ResultList.Title
															dangerouslySetInnerHTML={{ __html: item['Part#_space_1'] }}
														/>
														<ResultList.Description>
															{item.Stock_space_Item ? 'In Stock' : 'Not in Stock'}
														</ResultList.Description>
													</ResultList.Content>
												</ResultList>
											))}
										</ReactiveList.ResultListWrapper>
									) : (
										<Button fullWidth color="secondary" onClick={moveForward}>
											Upload Picture & Get help
										</Button>
									)}
								</div>
							)}
						/>
						<Button fullWidth color="secondary">
							Configure your Part
						</Button>
					</Paper>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = state => ({
	primaryColor: get(state, 'user.user.preferences.color_scheme.primary'),
	secondaryColor: get(state, 'user.user.preferences.color_scheme.secondary'),
});

export default withStyles(styles)(connect(mapStateToProps)(Steps));
