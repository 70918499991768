import React from 'react';
import { css } from 'emotion';
import CircularProgress from '@material-ui/core/CircularProgress';

const mainCls = css`
	width: 100%;
	margin-top: 50px;
	z-index: 100;
	display: flex;
	justify-content: center;
	align-items: center;
}
`;
const Loader = () => (
	<div className={mainCls}>
		<CircularProgress color="secondary" size={30} thickness={7} />
	</div>
);

export default Loader;
