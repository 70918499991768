import React from 'react';
import get from 'lodash/get';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { connect } from 'react-redux';
import {
	API_URL,
	DEFAULT_THEME_PRIMARY_COLOR,
	DEFAULT_THEME_SECONDARY_COLOR,
	DEFAULT_THEME_ACCENT_COLOR,
} from '../../constants/config';
import { fetchUser, getPlan } from '../../actions';

export const getTheme = (
	primaryColor = DEFAULT_THEME_PRIMARY_COLOR,
	secondaryColor = DEFAULT_THEME_SECONDARY_COLOR,
	accentColor = DEFAULT_THEME_ACCENT_COLOR,
) =>
	createMuiTheme({
		textColor: secondaryColor,
		palette: {
			primary: {
				main: primaryColor,
			},
			secondary: {
				main: accentColor,
			},
			background: {
				default: primaryColor,
			},
		},
		appBar: {
			height: 50,
		},
		typography: {
			useNextVariants: true,
		},
	});

class ThemeController extends React.Component {
	constructor(props) {
		super(props);
		let isLoading = true;
		if (props.email) {
			this.getUser();
			props.fetchAppPlan();
		} else {
			isLoading = false;
		}
		this.state = {
			isLoading,
		};
	}

	shouldComponentUpdate(nextProps) {
		const getProps = props => ({
			email: props.email,
			primaryColor: props.primaryColor,
			secondaryColor: props.secondaryColor,
			accentColor: props.accentColor,
			plan: props.plan,
			location: props.location.pathname,
		});
		if (JSON.stringify(getProps(this.props)) !== JSON.stringify(getProps(nextProps))) {
			return true;
		}
		return false;
	}

	componentDidUpdate(prevProps) {
		if (this.props.plan && prevProps.plan !== this.props.plan) {
			this.getUser();
		}
	}

	getUser() {
		fetch(`${API_URL}/user/${this.props.email}`, {
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then(res => res.json())
			.then(response => {
				this.setState({
					isLoading: false,
				});
				this.props.saveUser(response);
			})
			.catch(error => {
				this.setState({
					isLoading: false,
				});
				console.error(error);
			});
	}

	render() {
		const { children, primaryColor, secondaryColor, accentColor } = this.props;
		return (
			<MuiThemeProvider theme={getTheme(primaryColor, secondaryColor, accentColor)}>
				<CssBaseline />
				{children}
			</MuiThemeProvider>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	saveUser: payload => dispatch(fetchUser(payload)),
	fetchAppPlan: () => dispatch(getPlan()),
});

const mapStateToProps = state => {
	return {
		plan: get(state, 'user.plan'),
		email: get(state, 'user.user.email'),
		primaryColor: get(state, 'user.user.preferences.color_scheme.primary'),
		secondaryColor: get(state, 'user.user.preferences.color_scheme.secondary'),
		accentColor: get(state, 'user.user.preferences.color_scheme.accent'),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ThemeController);
