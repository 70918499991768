import React from 'react';

const ResultStats = props => (
	<div className="result-stats">
		<div>
			{props.numberOfResults} found in {props.time}ms
		</div>
	</div>
);

export default ResultStats;
