import { SUBVIEW } from '../constants';

const defaultView = '';

const cart = (state = defaultView, action) => {
	switch (action.type) {
		case SUBVIEW.UPDATE:
			return action.view;

		default:
			return state;
	}
};

export default cart;
