import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { func } from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import { get } from 'lodash';
import Auth from '../../utils/authenticate';
import { authConfig, API_URL } from '../../constants/config';
import { loginUser as login, fetchUser } from '../../actions';

class Authenticate extends Component {
	componentDidMount() {
		const { loginUser, history, saveUser } = this.props;
		const auth = new Auth(authConfig);
		const redirectPath = localStorage.getItem('lastRoute') || '/';
		const reset = () => {
			localStorage.setItem('lastRoute', '/');
		};
		auth
			.parseTokens()
			.then(user => {
				const role =
					user.idTokenPayload['http://we.dont.care/user_metadata'] &&
					user.idTokenPayload['http://we.dont.care/user_metadata'].user_role;
				console.log(role);
				const userNameSplited = user.idTokenPayload.name.split(' ');
				const userEmail = user.idTokenPayload.email;
				loginUser({
					first_name: userNameSplited[0],
					last_name: userNameSplited[1],
					email: user.idTokenPayload.email,
					verified_email: false,
					jwt_token: user.idToken,
					access_token: user.accessToken,
					user_role: role,
				});
				fetch(`${API_URL}/user/${userEmail}`, {
					headers: {
						'Content-Type': 'application/json',
					},
				})
					.then(res => res.json())
					.then(response => {
						saveUser(response);
						reset();
						history.replace(redirectPath);
					})
					.catch(error => {
						console.error(error);
						reset();
						history.replace(redirectPath);
					});
			})
			.catch(e => {
				console.error(e);
				reset();
				history.replace(redirectPath);
			});
	}

	render() {
		return (
			<div
				style={{
					textAlign: 'center',
					marginTop: '50%',
				}}
			>
				<CircularProgress style={{ color: '#fff' }} color="secondary" />
			</div>
		);
	}
}

Authenticate.propTypes = {
	loginUser: func,
};

const mapDispatchToProps = dispatch => ({
	saveUser: payload => dispatch(fetchUser(payload)),
	loginUser: payload => dispatch(login(payload)),
});

export default withRouter(connect(null, mapDispatchToProps)(Authenticate));
