import React from 'react';
import { getTheme } from '../../components/theme-controller';
import { connect } from 'react-redux';
import get from 'lodash/get';

const ListItem = ({ item, count, showCount, ...props }) => {
	const theme = getTheme(props.primaryColor, props.secondaryColor);
	return (
		<div
			style={{
				display: 'flex',
				width: '100%',
				justifyContent: 'space-between',
				alignItems: 'center',
				fontSize: 12,
			}}
		>
			<span style={{ paddingRight: '10px', color: theme.textColor }}>{item}</span>
			{showCount ? <span style={{ color: theme.textColor }}>{count}</span> : null}
		</div>
	);
};

ListItem.defaultProps = {
	showCount: true,
};

const mapStateToProps = state => ({
	primaryColor: get(state, 'user.user.preferences.color_scheme.primary'),
	secondaryColor: get(state, 'user.user.preferences.color_scheme.secondary'),
});

export default connect(mapStateToProps)(ListItem);
