import React from 'react';
import { authConfig } from '../../constants/config';
import Auth from '../../utils/authenticate';

class Login extends React.Component {
	componentDidMount() {
		const auth = new Auth(authConfig);
		auth.login();
	}
	render() {
		return (
			<div
				style={{
					marginTop: 200,
					textAlign: 'center',
				}}
			>
				Redirecting to Login ...
			</div>
		);
	}
}

export default Login;
