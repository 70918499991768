import React from 'react';
import ReactDOM from 'react-dom';
import thunkMiddleware from 'redux-thunk';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import storage from 'redux-persist/lib/storage';
import { omit } from 'lodash';

import { unregister } from './registerServiceWorker';
import Login from './pages/login';
import Authenticate from './pages/authenticate';

import rootReducer from './reducers';

import './css/index.css';
import './css/App.css';
import './css/Responsive.css';
import './css/SearchPage.css';

import PrivateRoute from './components/auth-wrapper/PrivateRoute';

const Root = () => (
	<Router>
		<Switch>
			<Route exact path="/authenticate" component={Authenticate} />
			<Route exact path="/login" component={Login} />
			<PrivateRoute />
		</Switch>
	</Router>
);

const blacklistTransform = createTransform((inboundState, key) => {
	if (key === 'user') {
		const noFacetsUser = omit(inboundState, ['dynamic_facets']);

		return {
			...noFacetsUser,
			dynamic_facets: [],
		};
	}
	return inboundState;
});

const config = {
	key: 'marketplace-upstream',
	whitelist: ['user', 'cart'],
	storage, // default storage is local storage
	transforms: [blacklistTransform],
};

const persistedReducer = persistReducer(config, rootReducer);

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(persistedReducer, composeEnhancer(applyMiddleware(thunkMiddleware)));

const persistor = persistStore(store);

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<Root />
		</PersistGate>
	</Provider>,
	document.getElementById('root'),
);
unregister();
