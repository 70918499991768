import React from 'react';
import { List, Tooltip } from 'antd';
import { get } from 'lodash';
import { facets } from '../../../constants/facets';

const FacetsIcon = ({ handleMenuClick }) => {
	return (
		<React.Fragment>
			<List
				itemLayout="horizontal"
				style={{ height: '100%', background: '#fafafa' }}
				dataSource={Object.keys(facets)}
				renderItem={item => (
					<Tooltip placement="rightBottom" title={item}>
						<List.Item
							onClick={() => handleMenuClick(item)}
							style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}
						>
							<img
								alt=""
								style={{ width: 20, height: 20 }}
								src={`/images/icons/${get(facets, `${item}.icon`)}.svg`}
							/>
						</List.Item>
					</Tooltip>
				)}
			/>
		</React.Fragment>
	);
};

export default FacetsIcon;
