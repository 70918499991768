import React from 'react';

export const normalizeProductData = product => {
	if (product.id && product.name) {
		return product;
	}
	return {
		id: product._id,
		name:
			product['P21_space_Part_space_#.search'] || product['P21_space_Part_space_#'] || 'Unknown',
		url: `https://backend.industahub.com/product/${product._id}`,
		description:
			product['Description'] ||
			product['Description_space_2'] ||
			product['Description_space_3'] ||
			product['Description_space_4'],
		// url: `https://6LI1ESwZx:49bc0007-bb8b-4c56-9a53-4dba983ce36e@scalr.api.appbase.io/marketplacev22/marketplacev2/${product._id}`,
		price: String(product['Unit_space_Price'] || product['List_space_Price'] || 0),
		autopop: false,
		quantity: product.quantity,
	};
};

export const addToCartMulti = (products = []) => {
	const normalizeProducts = products.map(product => normalizeProductData(product));

	if (window.Snipcart) {
		window.Snipcart.api.configure('show_cart_automatically', false);

		window.Snipcart.api.items.add(normalizeProducts).then(() => {
			window.Snipcart.api.modal.show();
		});
	}
};

const AddToCartComponent = ({ product, children, quantity, openCart }) => {
	return null;
};

AddToCartComponent.defaultProps = {
	quantity: 1,
	openCart: true,
};

export default AddToCartComponent;
