import React, { Component } from 'react';
import { ReactiveComponent } from '@appbaseio/reactivesearch';
import { string, number, oneOf } from 'prop-types';
import { isEmpty } from 'lodash';
import { setRef } from '../../utils/refService';

class Reactable extends Component {
	setQuery = null;

	constructor(props) {
		super(props);
		const { componentId } = props;
		setRef(`hidden-${componentId}`, this);

		const search = new URLSearchParams(window.location.search);
		const val = search.has(componentId) ? search.get(componentId) : '[]';
		const subValue = JSON.parse(val);

		this.state = {
			rawValue: val,
			value: subValue,
		};
	}

	urlCheck = () => {
		const { componentId } = this.props;
		const { rawValue } = this.state;
		const search = new URLSearchParams(window.location.search);
		const val = search.has(componentId) ? search.get(componentId) : '[]';

		if (val !== rawValue) {
			const subValue = JSON.parse(val);
			this.setState({ value: subValue, rawValue: val }, () => {
				this.update();
			});
			return false;
		}
		return true;
	};

	update() {
		const { dataField } = this.props;
		const { value } = this.state;
		if (isEmpty(value)) {
			this.setQuery({
				query: {
					match_all: {},
				},
			});
			return;
		}
		this.setQuery({
			query: {
				terms: {
					[dataField]: value,
				},
			},
			value,
		});
	}

	render() {
		const { componentId, dataField } = this.props;
		const { value, rawValue } = this.state;
		// const search = new URLSearchParams(window.location.search);
		// const val = search.has(componentId) ? search.get(componentId) : '';
		// if (val !== rawValue) {
		// 	const subValue = val.substr(2, val.length - 4);
		// 	this.setState({ value: subValue, rawValue: val }, () => {
		// 		this.update();
		// 	});
		// 	return null;
		// }

		return (
			<ReactiveComponent
				componentId={`hidden-${componentId}`}
				filterLabel={componentId}
				dataField={dataField}
				value={value}
				showFilter={false}
				URLParams
				onData={data => console.log('Data Loaded', data)}
				defaultQuery={() => {
					if (!this.urlCheck()) {
						return null;
					}
					return value
						? {
								term: {
									[dataField]: value,
								},
						  }
						: {
								query: {
									match_all: {},
								},
						  };
				}}
				customQuery={() => ({
					terms: {
						[dataField]: value,
					},
				})}
			>
				{({ setQuery, value: val }) => {
					this.setQuery = setQuery;
					return (
						<div style={{ display: 'none' }}>
							hidden-{componentId}: {val}
						</div>
					);
				}}
			</ReactiveComponent>
		);
	}
}

Reactable.propTypes = {
	componentId: string.isRequired,
	dataField: string.isRequired,
	size: number.isRequired,
	sortBy: oneOf(['asc', 'desc']),
};

Reactable.defaultProps = {
	sortBy: 'asc',
};

export default Reactable;
