import { ORDER } from '../constants';

const defaultOrder = [
	{
		id: 1,
		cart: [
			{
				type: 'kit',
				id: '0.ocvbznfq5q',
				name: 'KIT-5-NOV-2019-13:22:54',
				children: [
					{
						type: 'kit_item',
						id: 'AWgT9Ma94PDx88Kb7Ed6',
						name: 'SGX-50-H',
						url: 'https://backend.industahub.com/product/AWgT9Ma94PDx88Kb7Ed6',
						description: 'HS.CODE:3923.50(.90.90)',
						price: '115.6',
						autopop: false,
						quantity: 2,
						kit: '0.ocvbznfq5q',
					},
					{
						type: 'kit_item',
						id: 'AWgev5ySWF6iLczfO0j5',
						name: 'CJ0121002025-750',
						url: 'https://backend.industahub.com/product/AWgev5ySWF6iLczfO0j5',
						price: '0',
						autopop: false,
						quantity: 2,
						kit: '0.ocvbznfq5q',
					},
					{
						type: 'kit_item',
						id: 'AWgfb-uXWF6iLczfO-iw',
						name: 'K50011/K4079',
						url: 'https://backend.industahub.com/product/AWgfb-uXWF6iLczfO-iw',
						description: 'OR.  7.20 X   3.40 X  1.90',
						price: '21.4',
						autopop: false,
						quantity: 2,
						kit: '0.ocvbznfq5q',
					},
					{
						type: 'kit_item',
						id: 'AWgfeWo74PDx88Kb7f4N',
						name: 'P016004120P1B',
						url: 'https://backend.industahub.com/product/AWgfeWo74PDx88Kb7f4N',
						price: '0',
						autopop: false,
						quantity: 2,
						kit: '0.ocvbznfq5q',
					},
				],
				quantity: 2,
				description: 'desc',
			},
			{
				type: 'kit',
				id: '0.cgz0mfalob',
				name: 'KIT-5-NOV-2019-15:2:13',
				children: [
					{
						type: 'kit_item',
						id: 'AWgev5ySWF6iLczfO0j5',
						name: 'CJ0121002025-750',
						url: 'https://backend.industahub.com/product/AWgev5ySWF6iLczfO0j5',
						price: '0',
						autopop: false,
						quantity: 11,
						kit: '0.cgz0mfalob',
					},
					{
						type: 'kit_item',
						id: 'AWgfb-uXWF6iLczfO-iw',
						price: '21.4',
						quantity: 11,
						name: 'K50011/K4079',
						kit: '0.cgz0mfalob',
					},
					{
						type: 'kit_item',
						id: 'AWgT9Ma94PDx88Kb7Ed6',
						price: '115.6',
						quantity: 11,
						name: 'SGX-50-H',
						description: 'HS.CODE:3923.50(.90.90)',
						kit: '0.cgz0mfalob',
					},
				],
				quantity: 11,
				description: 'description',
			},
			{
				type: 'item',
				id: 'AWgfZ94hWF6iLczfO9fs',
				name: 'CJ0275003535-870',
				url: 'https://backend.industahub.com/product/AWgfZ94hWF6iLczfO9fs',
				description: 'RS. 2.750 X 3.535 X 0.870"',
				price: '53.05',
				autopop: false,
				quantity: 1,
			},
		],
	},
	{
		id: 2,
		cart: [
			{
				type: 'kit',
				id: '0.ocvbznfq5q',
				name: 'KIT-5-NOV-2019-13:22:54',
				children: [
					{
						type: 'kit_item',
						id: 'AWgT9Ma94PDx88Kb7Ed6',
						name: 'SGX-50-H',
						url: 'https://backend.industahub.com/product/AWgT9Ma94PDx88Kb7Ed6',
						description: 'HS.CODE:3923.50(.90.90)',
						price: '115.6',
						autopop: false,
						quantity: 2,
						kit: '0.ocvbznfq5q',
					},
					{
						type: 'kit_item',
						id: 'AWgev5ySWF6iLczfO0j5',
						name: 'CJ0121002025-750',
						url: 'https://backend.industahub.com/product/AWgev5ySWF6iLczfO0j5',
						price: '0',
						autopop: false,
						quantity: 2,
						kit: '0.ocvbznfq5q',
					},
					{
						type: 'kit_item',
						id: 'AWgfb-uXWF6iLczfO-iw',
						name: 'K50011/K4079',
						url: 'https://backend.industahub.com/product/AWgfb-uXWF6iLczfO-iw',
						description: 'OR.  7.20 X   3.40 X  1.90',
						price: '21.4',
						autopop: false,
						quantity: 2,
						kit: '0.ocvbznfq5q',
					},
					{
						type: 'kit_item',
						id: 'AWgfeWo74PDx88Kb7f4N',
						name: 'P016004120P1B',
						url: 'https://backend.industahub.com/product/AWgfeWo74PDx88Kb7f4N',
						price: '0',
						autopop: false,
						quantity: 2,
						kit: '0.ocvbznfq5q',
					},
				],
				quantity: 2,
				description: 'desc',
			},
		],
	},
];

const orders = (state = defaultOrder, action) => {
	if (action.type === ORDER.UPDATE_ORDER) {
		const updatedItems = state.map(item => {
			if (item.id === action.id) {
				item.cart = action.order;
			}
			return item;
		});
		return updatedItems;
	} else {
		return state;
	}
};

export default orders;
