/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { arrayOf, shape, func, bool } from 'prop-types';
import './ResultItem.css';
import ResultItem from './ResultItem';
import { ReactComponent as Search } from '../../icons/search.svg';
import { useCustomRef } from '../../utils/refService';
import { fetchCompanySearch } from '../../utils/helpers';

const groupBy = (items, key) =>
	items.reduce(
		(result, item) => ({
			...result,
			[item[key]]: [...(result[item[key]] || []), item],
		}),
		{},
	);

const styles = {
	card: {
		width: 200,
		padding: 10,
	},
	content: {
		padding: '5px 10px',
	},
	typo: {
		fontSize: '10px',
	},
	media: {
		objectFit: 'contain',
	},
	action: {
		padding: 3,
	},
	button: {
		fontSize: 10,
		width: '50%',
		paddingTop: 3,
		paddingBottom: 3,
		minHeight: 15,
		borderRadius: 0,
	},
};

class ResultList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			searchItems: [],
			currentCompany: null,
		};

		const search = new URLSearchParams(window.location.search);
		if (search.has('Account Name')) {
			const companyName = search.get('Account Name');
			this.state.currentCompany = companyName.substr(2, companyName.length - 4);
		}
	}

	componentDidMount() {
		this.fetchSearch(true);
	}

	componentDidUpdate() {
		this.fetchSearch();
	}

	fetchSearch = (force = false) => {
		const { currentCompany } = this.state;
		const search = new URLSearchParams(window.location.search);
		if (search.has('Account Name')) {
			let companyName = search.get('Account Name');
			companyName = companyName.substr(2, companyName.length - 4);
			if (force || currentCompany !== companyName) {
				fetchCompanySearch(companyName).then(res => {
					this.setState({ searchItems: res.popular_searches, currentCompany: companyName });
				});
			}
		}
	};

	// shouldComponentUpdate(nextProps) {
	// 	const { hits, promotedData, popularResults } = this.props;
	// 	if (
	// 		JSON.stringify(nextProps.hits) !== JSON.stringify(hits) ||
	// 		JSON.stringify(nextProps.promotedData) !== JSON.stringify(promotedData) ||
	// 		JSON.stringify(nextProps.popularResults) !== JSON.stringify(popularResults)
	// 	) {
	// 		return true;
	// 	}

	// 	return false;
	// }

	render() {
		const { hits, promotedData, popularResults, triggerAnalytics, showDesc } = this.props;
		const { searchItems } = this.state;

		const groupedList = groupBy(
			hits.filter(h => !h._promoted),
			'Application',
		);
		return (
			<div className="container">
				{promotedData && promotedData.length ? (
					<div>
						<h3>Promoted Results</h3>
						<div className="card-result">
							{promotedData.map(result => (
								<ResultItem
									isPopular
									key={result._click_id}
									result={result}
									showDesc={showDesc}
									onClick={() => triggerAnalytics(result._click_id)}
								/>
							))}
						</div>
					</div>
				) : null}
				{popularResults && (
					<div>
						<h3>Popular Results</h3>
						<div className="card-result">
							{popularResults.map(result => (
								<ResultItem
									key={result._click_id}
									result={result}
									showDesc={showDesc}
									onClick={() => triggerAnalytics(result._click_id)}
								/>
							))}
						</div>
					</div>
				)}

				{Object.keys(groupedList)
					.sort()
					.map(item => (
						<div key={item}>
							<h3>{item}</h3>
							<div className="card-result">
								{groupedList[item].map(result => (
									<ResultItem
										key={result._click_id}
										result={result}
										showDesc={showDesc}
										onClick={() => triggerAnalytics(result._click_id)}
									/>
								))}
							</div>
						</div>
					))}
			</div>
		);
	}
}

ResultList.propTypes = {
	popularResults: arrayOf(shape({})).isRequired,
	hits: arrayOf(shape({})).isRequired,
	promotedData: arrayOf(shape({})).isRequired,
	triggerAnalytics: func.isRequired,
	// eslint-disable-next-line react/require-default-props
	showDesc: bool,
};

export default withStyles(styles)(ResultList);
