import { CART } from '../constants';

const defaultCart = [];

const cart = (state = defaultCart, action) => {
	switch (action.type) {
		case CART.UPDATE_CART:
			return action.cart;
		case CART.ADD_PRODUCT: {
			const isItemPresent = state.find(item => item.id === action.product.id);
			if (isItemPresent) {
				return state;
			}
			const quantity = action.product && action.product.quantity ? action.product.quantity : 1;
			return [...state, { type: 'item', ...action.product, quantity }];
		}
		case CART.REMOVE_PRODUCT: {
			const filteredItems = state.filter(item => item.id !== action.id);
			return filteredItems;
		}
		case CART.UPDATE_PRODUCT: {
			const updatedItems = state.map(item => {
				if (item.type === 'item' && item.id === action.product.id) {
					return { ...item, ...action.product };
				}
				return item;
			});
			return updatedItems;
		}
		default:
			return state;
	}
};

export default cart;
