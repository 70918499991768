import { SIDEBAR, FACETS, USER, CATEGORY, CART, SUBVIEW } from '../constants';

export * from './user';
export * from './sidebar';

export const toggleSidebar = () => ({
	type: SIDEBAR.TOGGLE,
});

export const addFacets = filter => ({
	type: FACETS.ADD_FACETS,
	filter,
});

export const removeFacet = filter => ({
	type: FACETS.REMOVE_FACETS,
	filter,
});

export const loginUser = user => ({
	type: USER.LOGIN,
	user,
});

export const logoutUser = user => ({
	type: USER.LOGOUT,
	user,
});

export const fetchUser = user => ({
	type: USER.GET_ROLE_DETAILS,
	user,
});

export const updateCategory = category => ({
	type: CATEGORY.UPDATE,
	category,
});

export const addProduct = product => ({
	type: CART.ADD_PRODUCT,
	product,
});

export const removeProduct = id => ({
	type: CART.REMOVE_PRODUCT,
	id,
});

export const updateProduct = product => ({
	type: CART.UPDATE_PRODUCT,
	product,
});

export const updateCart = cart => ({
	type: CART.UPDATE_CART,
	cart,
});

export const updateSubView = view => ({
	type: SUBVIEW.UPDATE,
	view,
});
