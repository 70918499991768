import React from 'react';
import { Button, Icon, Input, Descriptions, Tag, Tooltip, Avatar } from 'antd';
import { Link } from 'react-router-dom';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Geocode from 'react-geocode';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { GoogleMap, Marker, withGoogleMap } from 'react-google-maps';
import '../../css/Checkout.css';
import { removeProduct } from '../../actions';
import { getTheme } from '../../components/theme-controller';
import { getTotal } from '../../utils';
import { MAP_GEOCODE_KEY } from '../../constants/config';
import CartList from '../../components/cart-list';

Geocode.setApiKey(MAP_GEOCODE_KEY);

const MyMapComponent = withGoogleMap(props => (
	<GoogleMap defaultZoom={8} defaultCenter={props.latLng} center={props.latLng}>
		{props.isMarkerShown && <Marker position={props.latLng} />}
	</GoogleMap>
));

export function totalPrice(cartItem) {
	const totalPrice = cartItem.children.reduce((agg, item) => agg + item.price * item.quantity, 0);
	return totalPrice;
}

class Checkout extends React.Component {
	state = {
		loading: false,
		address: 'New York',
		newAddress: 'New York',
		editAddress: true,
		latLng: {
			lat: 40.7128,
			lng: -74.006,
		},
		newLatLng: {
			lat: 40.7128,
			lng: -74.006,
		},
	};

	getUserLocation = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(this.geoSuccess, this.geoError);
		}
	};

	geoError = () => {
		this.setState({
			loading: false,
		});
	};

	geoSuccess = position => {
		this.setState({
			loading: true,
		});
		Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
			response => {
				const address = response.results[0].formatted_address;
				this.setState({
					newAddress: address,
					newLatLng: {
						lat: position.coords.latitude,
						lng: position.coords.longitude,
					},
					latLng: {
						lat: position.coords.latitude,
						lng: position.coords.longitude,
					},
					address: address,
					editAddress: false,
					loading: false,
				});
			},
			error => {
				this.setState({
					loading: false,
				});
				console.error(error);
			},
		);
	};

	handleChange = address => {
		this.setState({ newAddress: address });
	};

	handleEdit = () => {
		this.setState(prevState => ({
			editAddress: !prevState.editAddress,
		}));
	};

	handleSelect = address => {
		this.setState({
			newAddress: address,
		});
		geocodeByAddress(address)
			.then(results => getLatLng(results[0]))
			.then(latLng => {
				this.setState({
					newLatLng: { ...latLng },
				});
			})
			.catch(error => console.error('Error', error));
	};

	handleCancel = () => {
		this.setState(prevState => ({
			newLatLng: prevState.latLng,
			newAddress: prevState.address,
		}));
		this.handleEdit();
	};

	handleSave = () => {
		this.setState(prevState => ({
			latLng: prevState.newLatLng,
			address: prevState.newAddress,
		}));
		this.handleEdit();
	};

	getCartTotal = () => {
		const { cart } = this.props;
		return getTotal(cart, item => Number(item.price || 0) * item.quantity);
	};

	componentDidMount() {
		this.getUserLocation();
	}

	render() {
		const { editAddress, newLatLng, newAddress, loading } = this.state;
		const {
			cart,
			removeProductFromCart,
			firstName,
			lastName,
			primaryColor,
			secondaryColor,
			accentColor,
		} = this.props;
		const theme = getTheme(primaryColor, secondaryColor, accentColor);

		return (
			<div className="checkout-container">
				<div className="checkout-content">
					<div className="info">
						<div className="card">
							<h2>Time</h2>
							<div className="content">
								<h2>3 - 5 days</h2>
							</div>
						</div>
						<div className="card">
							<div>
								<h2>Ship To</h2>
								{!editAddress && (
									<Button size="small" onClick={this.handleEdit}>
										Change
									</Button>
								)}
							</div>
							<div className="content">
								<MyMapComponent
									loadingElement={<div style={{ height: `100%` }} />}
									containerElement={<div style={{ height: `200px` }} />}
									mapElement={<div style={{ height: `100%` }} />}
									isMarkerShown
									latLng={newLatLng}
									loading
								/>
								{loading ? (
									<h3 className="address">Fetching current Location</h3>
								) : editAddress ? (
									<div className="address-input">
										<PlacesAutocomplete
											value={newAddress}
											onChange={this.handleChange}
											onSelect={this.handleSelect}
										>
											{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
												<div className="autocomplete-dropdown">
													<Input
														{...getInputProps({
															placeholder: 'Search Places ...',
															className: 'location-search-input',
														})}
													/>
													<div className="autocomplete-dropdown-container">
														{loading && <div>Loading...</div>}
														{suggestions.map(suggestion => {
															const className = suggestion.active
																? 'suggestion-item--active'
																: 'suggestion-item';
															// inline style for demonstration purpose
															const style = suggestion.active
																? { backgroundColor: '#fafafa', cursor: 'pointer' }
																: { backgroundColor: '#ffffff', cursor: 'pointer' };
															return (
																<div
																	{...getSuggestionItemProps(suggestion, {
																		className,
																		style,
																	})}
																>
																	<span>{suggestion.description}</span>
																</div>
															);
														})}
													</div>
												</div>
											)}
										</PlacesAutocomplete>
										<div className="address-actions">
											<Button type="primary" ghost onClick={this.handleCancel}>
												Cancel
											</Button>
											<Button style={{ marginLeft: 10 }} onClick={this.handleSave} type="primary">
												Save
											</Button>
										</div>
									</div>
								) : (
									<React.Fragment>
										<h3 className="address">{newAddress}</h3>
									</React.Fragment>
								)}
							</div>
						</div>
						<div className="card">
							<div>
								<h2>Payment</h2>
								<Button size="small">Change/Add</Button>
							</div>
							<div className="content">
								<Icon type="money-collect" style={{ fontSize: 20 }} />
							</div>
						</div>
						<div className="card">
							<div>
								<h2>Summary</h2>
								<Link to="/cart">
									<Button size="small">Edit Order</Button>
								</Link>
							</div>
							<div className="content">
								<CartList showDelete cart={cart} />
								<Button
									type="primary"
									block
									style={{
										background: theme.palette.secondary.main,
										borderColor: theme.palette.secondary.main,
										color: theme.palette.secondary.contrastText,
									}}
								>
									<Icon
										type="shopping-cart"
										style={{
											color: theme.palette.secondary.contrastText,
										}}
									/>
									Place Order
								</Button>
								<div className="cart-price">
									<h2>Total</h2>
									<h2>${this.getCartTotal()}</h2>
								</div>
							</div>
						</div>
					</div>
					<div className="summary">
						<div className="user-info">
							<div className="avatar">
								<Link to="/account">
									<Avatar
										size={54}
										style={{
											color: theme.palette.secondary.main,
											backgroundColor: theme.palette.secondary.contrastText,
											border: `1px solid ${theme.palette.secondary.main}`,
										}}
									>
										{firstName.charAt(0) + lastName.charAt(0)}
									</Avatar>
								</Link>
								<div className="user-name">
									<h3 className="grey">Order From</h3>
									<h3>{firstName + ' ' + lastName}</h3>
								</div>
							</div>
						</div>
						<Button
							block
							type="primary"
							style={{
								background: theme.palette.secondary.main,
								borderColor: theme.palette.secondary.main,
								color: theme.palette.secondary.contrastText,
							}}
						>
							<Icon
								type="shopping-cart"
								style={{
									color: theme.palette.secondary.contrastText,
								}}
							/>
							Place Order
						</Button>
						<div className="payment-info">
							<div>
								<h2>Subtotal</h2>
								<h2>${this.getCartTotal()}</h2>
							</div>
							<div>
								<h2>Taxes and Fees</h2>
								<h2>${(this.getCartTotal() * 0.02).toFixed(2)}</h2>
							</div>
							<div>
								<h2>Delivery</h2>
								<h2>$3</h2>
							</div>
						</div>

						<div className="total-payment">
							<h2>Total</h2>
							<h2>${(this.getCartTotal() * 1.02 + 3).toFixed(2)}</h2>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	cart: (state && state.cart) || [],
	firstName: get(state, 'user.user.first_name', ''),
	lastName: get(state, 'user.user.last_name', ''),
	primaryColor: get(state, 'user.user.preferences.color_scheme.primary'),
	secondaryColor: get(state, 'user.user.preferences.color_scheme.secondary'),
	accentColor: get(state, 'user.user.preferences.color_scheme.accent'),
});

const mapDispatchToProps = dispatch => ({
	removeProductFromCart: id => dispatch(removeProduct(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
