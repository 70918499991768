import { CART } from '../../../constants';

const defaultCart = [];

const cart = (state = defaultCart, action) => {
	switch (action.type) {
		case CART.ADD_PRODUCT: {
			const isItemPresent = state.find(item => item.id === action.product.id);
			if (isItemPresent) {
				return state;
			}
			const quantity = action.product && action.product.quantity ? action.product.quantity : 1;
			return [...state, { type: 'item', ...action.product, quantity }];
		}
		case CART.ADD_KIT: {
			const isItemPresent = state.find(item => item.id === action.kit.id);
			if (isItemPresent) {
				return state;
			}
			return [...state, { type: 'kit', ...action.kit, quantity: 1 }];
		}
		case CART.REMOVE_PRODUCT: {
			const filteredItems = state.filter(item => item.id !== action.id);
			return filteredItems;
		}
		case CART.REMOVE_KIT: {
			const filteredItems = state.filter(item => item.id !== action.id);
			return filteredItems;
		}
		case CART.UPDATE_PRODUCT: {
			const updatedItems = state.map(item => {
				if (item.type === 'item' && item.id === action.product.id) {
					return { ...item, ...action.product };
				}
				return item;
			});
			return updatedItems;
		}
		case CART.UPDATE_KIT: {
			const updatedItems = state.map(item => {
				if (item.type === 'kit' && item.id === action.kit.id) {
					return { ...item, ...action.kit };
				}
				return item;
			});
			return updatedItems;
		}
		case CART.ADD_SUB_KIT: {
			const kit = state.find(item => item.type === 'kit' && item.id === action.kit);
			const nestedKits = action.subKit.children
				.filter(item => item.type === 'child_kit')
				.map(item => ({
					...item,
					kit_associated: kit.id,
				}));
			const updatedSubKitChildren = action.subKit.children
				.filter(item => item.type !== 'child_kit')
				.map(item => ({
					...item,
					parentKit: kit.id,
				}));
			kit.children = [
				...kit.children,
				...nestedKits,
				{
					...action.subKit,
					children: updatedSubKitChildren,
					kit_associated: kit.id,
					type: 'child_kit',
				},
			];
			const updatedItems = state.map(item => {
				if (item.type === 'kit' && item.id === action.kit) {
					return kit;
				}
				return item;
			});
			return updatedItems;
		}
		case CART.REMOVE_SUB_KIT: {
			const kit = state.find(item => item.type === 'kit' && item.id === action.kit);
			kit.children = kit.children.filter(item => item.id !== action.subKit);
			const updatedItems = state.map(item => {
				if (item.type === 'kit' && item.id === action.kit) {
					return kit;
				}
				return item;
			});
			return updatedItems;
		}
		case CART.REMOVE_SUB_KIT_ITEM: {
			const kit = state.find(item => item.type === 'kit' && item.id === action.item.parentKit);
			kit.children = kit.children.map(item => {
				if (item.id === action.item.kit) {
					item.children = item.children.filter(childItem => childItem.id !== action.item.id);
				}
				return item;
			});
			const updatedItems = state.map(item => {
				if (item.type === 'kit' && item.id === kit.id) {
					return kit;
				}
				return item;
			});
			return updatedItems;
		}
		case CART.ADD_SUB_KIT_ITEM: {
			const kit = state.find(item => item.type === 'kit' && item.id === action.parentKit);
			const associatedKit = kit.children.find(item => item.id === action.kit);
			const isItemPresent = associatedKit.children.find(
				childItem => childItem.id === action.item.id,
			);
			kit.children = kit.children.map(item => {
				if (item.id === action.kit) {
					if (isItemPresent) {
						item.children = item.children.map(childItem => {
							if (childItem.id === item.id) {
								childItem.quantity = childItem.quantity + 1;
							}
							return childItem;
						});
					} else {
						item.children = [
							...item.children,
							{
								...action.item,
								type: 'kit_item',
								parentKit: kit.id,
								kit: action.kit,
							},
						];
					}
				}
				return item;
			});
			const updatedItems = state.map(item => {
				if (item.type === 'kit' && item.id === kit.id) {
					return kit;
				}
				return item;
			});
			return updatedItems;
		}
		case CART.ADD_KIT_ITEM: {
			const kit = state.find(item => action.kit === item.id);
			const isItemPresent = kit.children.find(item => item.id === action.item.id);

			if (isItemPresent) {
				kit.children = kit.children.map(item => {
					if (item.id === action.item.id) {
						item.quantity = item.quantity + 1;
					}
					return item;
				});
			} else {
				kit.children = [
					...kit.children,
					{
						...action.item,
						parentKit: undefined,
						kit: kit.id,
						type: 'kit_item',
					},
				];
			}
			const updatedItems = state.map(item => {
				if (item.type === 'kit' && item.id === kit.id) {
					return kit;
				}
				return item;
			});
			return updatedItems;
		}
		case CART.REMOVE_KIT_ITEM: {
			const kit = state.find(item => action.item.kit === item.id);
			kit.children = kit.children.filter(item => item.id !== action.item.id);

			const updatedItems = state.map(item => {
				if (item.type === 'kit' && item.id === kit.id) {
					return kit;
				}
				return item;
			});
			return updatedItems;
		}
		case CART.REORDER_ITEMS: {
			const droppedToIndex = state.findIndex(
				item => item.id === action.destination.id && item.type === 'item',
			);
			const draggedFromIndex = state.findIndex(
				item => item.id === action.source.id && item.type === 'item',
			);

			const draggedItem = state[draggedFromIndex];
			const droppedToItem = state[droppedToIndex];

			const newState = JSON.parse(JSON.stringify(state));

			newState[draggedFromIndex] = droppedToItem;
			newState[droppedToIndex] = draggedItem;
			return newState;
		}
		default:
			return state;
	}
};

export default cart;
