import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Validators, FormGenerator } from 'react-reactive-form';
import get from 'lodash/get';
import { withStyles } from '@material-ui/core/styles';
import ReactFilestack from 'filestack-react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PricingTable from '../../components/pricing-table';
import { connect } from 'react-redux';
import { logoutUser, getPlan } from '../../actions';
import { parseJwt } from '../../utils';
import Auth from '../../utils/authenticate';
import {
	authConfig,
	metaDataConfig,
	FILE_STACK_API_KEY,
	DEFAULT_THEME_ACCENT_COLOR,
	whitelistedUsers,
} from '../../constants/config';
import { css } from 'emotion';

const planCls = css`
	margin: 0 auto;
	text-align: center;
	font-size: 26px;
	text-align: ;
	padding: 50px 20px 0px 20px;
`;

const auth = new Auth(authConfig);

const TextInput = control => (
	<div style={{ padding: 10 }}>
		<TextField
			id="standard-name"
			style={{
				width: 300,
				marginLeft: 'calc(50% - 150px)',
			}}
			{...control.meta}
			{...control.handler()}
		/>
	</div>
);

const FileUpload = control => {
	const { onChange, value } = control.handler();
	return (
		<div style={{ padding: '10px 0', width: 300, marginLeft: 'calc(50% - 150px)' }}>
			<ReactFilestack
				apikey={FILE_STACK_API_KEY}
				options={{
					maxFiles: 1,
				}}
				onSuccess={response => {
					onChange(response.filesUploaded[0].url);
				}}
				preload={true}
				render={({ onPick }) => (
					<div>
						<div>
							<Button
								style={{
									backgroundColor: DEFAULT_THEME_ACCENT_COLOR,
									color: '#fff',
								}}
								onClick={onPick}
							>
								Upload Logo
							</Button>
						</div>
						{value && (
							<img src={value} style={{ maxWidth: 200, maxHeight: 50, margin: '20px 0' }} />
						)}
					</div>
				)}
			/>
		</div>
	);
};
const styles = () => ({
	container: {
		marginTop: 100,
		width: '100%',
	},
});

class Account extends React.Component {
	constructor(props) {
		super(props);
		const user = parseJwt(props.token);
		const metaData = user ? user[metaDataConfig] || {} : {};
		const isAdmin = whitelistedUsers.includes(props.userEmail);
		this.fieldConfig = {
			controls: {
				name: {
					formState: metaData.name || user.name,
					options: {
						validators: Validators.required,
					},
					render: TextInput,
					meta: { label: 'Name' },
				},
				email: {
					formState: { value: user.email, disabled: true },
					options: {
						validators: Validators.required,
					},
					render: TextInput,
					meta: { label: 'Email' },
				},
				plan: {
					formState: { value: props.plan || 'free', disabled: true },
					render: TextInput,
					meta: { label: 'Plan' },
				},
				logoURL: {
					formState: metaData.logoURL,
					render: isAdmin ? FileUpload : null,
				},
				$field_0: {
					isStatic: false,
					render: ({ invalid, pristine, meta: { handleSubmit } }) => (
						<div style={{ marginLeft: 'calc(50% - 40px)', marginTop: 20 }}>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								disabled={invalid || pristine}
								onClick={handleSubmit}
							>
								Submit
							</Button>
						</div>
					),
				},
			},
		};
		this.state = {
			open: false,
			error: null,
		};
	}
	componentDidMount() {
		auth.refreshToken();
		this.props.fetchPlan();
	}
	componentDidUpdate(prevProps) {
		if (this.props.plan && prevProps.plan !== this.props.plan) {
			// Update plan in form
			this.loginForm.get('plan').setValue(this.props.plan);
		}
	}
	setForm = form => {
		this.loginForm = form;
		this.loginForm.meta = {
			handleSubmit: this.handleSubmit,
		};
	};

	handleClickOpen = () => {
		this.setState({ open: true });
	};

	handleClose = () => {
		this.setState({ open: false });
	};
	handleSubmit = e => {
		const { token, accessToken } = this.props;
		const user = parseJwt(token);
		e.preventDefault();
		const formValues = this.loginForm.value;
		auth.patchUserMetadata(
			accessToken,
			user.sub,
			{
				name: formValues.name,
				logoURL: formValues.logoURL,
				plan: formValues.plan,
			},
			(error, result) => {
				this.setState(
					{
						error,
					},
					this.handleClickOpen,
				);
			},
		);
	};
	handleLogout = () => {
		auth.logout();
		this.props.logout();
		this.handleClose();
	};
	render() {
		const { classes, plan, accentColor, userEmail } = this.props;
		const { error } = this.state;
		return (
			<div className={classes.container}>
				<form autoComplete="off">
					<FormGenerator fieldConfig={this.fieldConfig} onMount={this.setForm} />
					<Dialog
						open={this.state.open}
						onClose={this.handleClose}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle id="alert-dialog-title">{error ? 'Error' : 'Login Again'}</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								{error
									? "Something went wrong! Couldn't update the profile."
									: `Your changes have been saved successfully.
								For these changes to take effect, you need to login again.`}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.handleClose} color="secondary">
								Cancel
							</Button>
							{!error && (
								<Button onClick={this.handleLogout} autoFocus>
									Confirm
								</Button>
							)}
						</DialogActions>
					</Dialog>
				</form>
				{plan && (
					<h3 className={planCls}>
						Your current plan is <span style={{ color: accentColor }}>{plan}</span>.
					</h3>
				)}
				{
					whitelistedUsers.includes(userEmail) && (
						<div
							id="billing"
							style={{
								paddingTop: 100,
								paddingBottom: 50,
							}}
						>
							<PricingTable />
						</div>
					)
				}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		userEmail: get(state, 'user.user.email', ''),
		token: get(state, 'user.user.jwt_token'),
		plan: get(state, 'user.plan'),
		accessToken: get(state, 'user.user.access_token'),
		accentColor: get(state, 'user.user.preferences.color_scheme.accent'),
	};
};

const mapDispatchToProps = dispatch => ({
	logout: () => dispatch(logoutUser()),
	fetchPlan: () => dispatch(getPlan()),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Account));
