import get from 'lodash/get';
import { API_URL } from '../constants/config';
import { createAction } from './utils';
import { USER_PLAN, USER } from '../constants';

export const getPlan = () => {
	return (dispatch, getState) => {
		const token = get(getState(), 'user.user.jwt_token');
		dispatch(createAction(USER_PLAN.GET));
		return fetch(`${API_URL}/subscription`, {
			headers: {
				'x-auth0-token': token,
				'Content-Type': 'application/json',
			},
		})
			.then(res => res.json())
			.then(response => dispatch(createAction(USER_PLAN.GET_SUCCESS, response)))
			.catch(err => dispatch(createAction(USER_PLAN.GET_ERROR, null, err)));
	};
};

export const updatePlan = payload => {
	return (dispatch, getState) => {
		const token = get(getState(), 'user.user.jwt_token');
		dispatch(createAction(USER_PLAN.UPDATE));
		return fetch(`${API_URL}/subscription`, {
			method: 'POST',
			headers: {
				'x-auth0-token': token,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(payload),
		})
			.then(response => response.json())
			.then(res => dispatch(createAction(USER_PLAN.UPDATE_SUCCESS, res)))
			.catch(err => dispatch(createAction(USER_PLAN.UPDATE_ERROR, null, err)));
	};
};

export const addDynamicFacets = payload => {
	return createAction(USER.ADD_DYNAMIC_FACETS, payload);
};
