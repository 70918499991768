import React, { Component } from 'react';
import styled, { css } from 'react-emotion';
import { Check } from 'react-feather';
import { connect } from 'react-redux';
import Stripe from 'react-stripe-checkout';
import ReactToolTip from 'react-tooltip';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import AppButton from './AppButton';
// import PlusMinus from './PlusMinus';
import NewPricingCard from './NewPricingCard';
import theme from './theme';
import { media, hexToRgb } from '../../utils/media';
import { tableDetails, pricingDetails } from './utils';
import { updatePlan, getPlan } from '../../actions';
// import { createAppSubscription, deleteAppSubscription, getAppPlan } from '../../batteries/modules/actions';
// import { getAppPlanByName } from '../../batteries/modules/selectors';

const CheckList = ({ list }) =>
	list.map(item => (
		<li key={item}>
			<Check style={{ marginRight: '6px', fontWeight: 'strong' }} width="15" height="15" /> {item}
		</li>
	));

const hideOnLarge = css`
	${media.ipadPro(css`
		display: none;
	`)};
	margin: 50px auto;
	user-select: none;
`;

const showOnLarge = css`
	display: none !important;
	${media.ipadPro(css`
		display: flex !important;
	`)};
	user-select: none;
`;

const Title = styled('div')`
	font-size: 20px;
	font-weight: bold;
	line-height: 27px;
	text-align: center;
	margin-bottom: 25px;
`;

const Price = styled('div')`
	height: 54px;
	font-family: 'Open Sans';
	font-size: 36px;
	line-height: 26px;
	text-align: center;
	margin: 0 auto 20px auto;
	> small {
		font-family: 'Open Sans';
		font-size: 12px;
		font-weight: 400;
		line-height: 22px;
		text-align: center;
		padding-left: 4rem;
	}
`;

const Table = styled('table')`
	text-align: center;
	border-collapse: separate;
	border-spacing: 16px 0px;

	> thead > tr {
		&:nth-child(1) {
			> td {
				padding-top: 30px;
				vertical-align: top;
			}
		}
	}
	> thead > tr > td,
	> tbody > tr > td {
		width: 205px;
		border: 1.5px solid #f4f4f4;
		border-bottom: 0;
		padding: 11px 2px;
		font-size: 18px;
		font-weight: 400;
		line-height: 22px;
		text-align: center;

		&:nth-child(1) {
			min-width: 240px;
			border-color: transparent;
			text-align: left;
			font-size: 20px;
			font-weight: 400;
			line-height: 27px;
			> span {
				padding-bottom: 0px;
				border-bottom-style: dashed;
				border-bottom-width: 3px;
				a {
					text-decoration: none;
					color: inherit;
				}
			}
		}

		&:nth-child(3) {
			border-color: ${theme.badge.blue};
			background: ${theme.badge.blue};
			color: #ffffff;
		}
		&:nth-child(4) {
			border-color: ${theme.badge.darkBlue};
			background: ${theme.badge.darkBlue};
			color: #ffffff;
		}
		&:nth-child(5) {
			border-color: ${theme.badge.red};
			background: ${theme.badge.red};
			color: #ffffff;
		}
	}
	> tbody > tr {
		text-align: left;
		&:hover {
			td {
				&:nth-child(1) {
					border-color: ${hexToRgb('#ECECEC', 0.21)};
					background: ${hexToRgb('#ECECEC', 0.21)};
				}
				&:nth-child(2) {
					border-color: ${hexToRgb('#ECECEC', 0.21)};
					background: ${hexToRgb('#ECECEC', 0.21)};
				}
				&:nth-child(3) {
					border-color: ${hexToRgb(theme.badge.blue, 0.79)};
					background: ${hexToRgb(theme.badge.blue, 0.79)};
				}
				&:nth-child(4) {
					border-color: ${hexToRgb(theme.badge.darkBlue, 0.79)};
					background: ${hexToRgb(theme.badge.darkBlue, 0.79)};
				}
				&:nth-child(5) {
					border-color: ${hexToRgb(theme.badge.red, 0.79)};
					background: ${hexToRgb(theme.badge.red, 0.79)};
				}
			}
		}
		> td {
			border-top: 0;
		}

		> td:nth-child(1) {
			border-top: 1.5px;
		}
		&:last-child {
			> td {
				&:nth-child(2) {
					border-bottom: 1.5px #f4f4f4 solid;
				}
			}
		}
	}
`;

const ListCaption = styled('div')`
	margin-top: 13px;
	color: ${hexToRgb('#FFFFFF', 0.8)};
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 17px;
	text-align: left;
	text-decoration: none !important;
	text-transform: uppercase;
	margin-left: 3px;
`;

const HeadingTr = css`
	height: 100px;
	&:hover {
		td {
			&:nth-child(1) {
				border-color: ${hexToRgb('#FFFFFF', 1)} !important;
				background: ${hexToRgb('#FFFFFF', 1)} !important;
			}
			&:nth-child(2) {
				border-color: ${hexToRgb('#f4f4f4', 1)} !important;
				background: ${hexToRgb('#FFFFFF', 1)} !important;
			}
			&:nth-child(3) {
				border-color: ${hexToRgb(theme.badge.blue, 1)} !important;
				background: ${hexToRgb(theme.badge.blue, 1)} !important;
			}
			&:nth-child(4) {
				border-color: ${hexToRgb(theme.badge.darkBlue, 1)} !important;
				background: ${hexToRgb(theme.badge.darkBlue, 1)} !important;
			}
			&:nth-child(5) {
				border-color: ${hexToRgb(theme.badge.red, 1)} !important;
				background: ${hexToRgb(theme.badge.red, 1)} !important;
			}
		}
	}
	td {
		color: ${hexToRgb('#232e44', 0.5)};
		font-size: 16px !important;
		font-weight: 600 !important;
		line-height: 28px !important;
		text-transform: uppercase;
		text-decoration: none !important;
		padding-bottom: 20px !important;
		> small {
			display: block;
			clear: both;
			color: #232e44;
			font-size: 12px;
			line-height: 17px;
			text-transform: none;
			font-weight: 400;
			a {
				color: inherit;
			}
		}
	}
`;

class PricingTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			active: undefined,
			showConfirmBox: false,
		};
		// test key
		this.stripeKey = 'pk_test_thDOgevZ8bLsPMd9Pg2TipBN00KQm3fgzc';
	}

	handleToken = (token, plan) => {
		const { createSubscription, fetchAppPlan } = this.props;
		createSubscription({
			token,
			plan,
		}).then(({ payload }) => {
			if (payload) {
				fetchAppPlan();
			}
		});
	};

	deleteSubscription = () => {
		const { deleteSubscription, fetchAppPlan } = this.props;
		deleteSubscription().then(({ payload }) => {
			if (payload) {
				fetchAppPlan();
				this.cancelConfirmBox();
			}
		});
	};

	showConfirmBox = () => {
		this.setState({
			showConfirmBox: true,
		});
	};

	cancelConfirmBox = () => {
		this.setState({
			showConfirmBox: false,
		});
	};

	isCurrentPlan(plan) {
		return plan === this.props.plan;
	}

	render() {
		const {
			// active,
			// showConfirmBox,
		} = this.state;
		const {
			// isSubmitting,
			// isLoading,
		} = this.props;
		const isExpertPlan = this.isCurrentPlan(pricingDetails.Experts.key);
		const isDistributorPlan = this.isCurrentPlan(pricingDetails.Distributors.key);
		const isWholesalePlan = this.isCurrentPlan(pricingDetails.WholesaleDistributors.key);
		const isManufacturePlan = this.isCurrentPlan(pricingDetails.Manufacturers.key);
		return (
			<React.Fragment>
				{/* <Modal
					title="Delete Subscription"
					visible={showConfirmBox}
					onCancel={this.cancelConfirmBox}
					footer={[
						<Button key="back" onClick={this.cancelConfirmBox}>
							Cancel
						</Button>,
						<Button
							loading={isSubmitting}
							key="submit"
							type="primary"
							onClick={this.deleteSubscription}
						>
							Unsubscribe
						</Button>,
					]}
				>
					<p>Are you sure to unsubscribe current subscription?</p>
				</Modal> */}
				<Table className={hideOnLarge}>
					<thead>
						<tr colSpan="1">
							<td>
								<ReactToolTip place="right" effect="solid" multiline />
							</td>
							{Object.keys(pricingDetails).map(i => (
								<td key={i}>
									<Title>{pricingDetails[i].name}</Title>
									<Price>
										${pricingDetails[i].price}
										<br />
										<small>/month</small>
									</Price>
								</td>
							))}
						</tr>
					</thead>
					<tbody>
						<tr className={HeadingTr}>
							<td>Core Platform</td>
							{Object.keys(pricingDetails).map((_, index) => (
								<td key={index} />
							))}
						</tr>
						{tableDetails.map((item, index) => (
							<tr key={index}>
								{item.map((k, index2) => (
									<td key={`${index}${index2}`}>{k}</td>
								))}
							</tr>
						))}
					</tbody>
					<tfoot>
						<tr>
							<td />
							<td>
								<Stripe
									name={`Marketplace ${pricingDetails.Experts.name} Plan`}
									amount={0}
									token={token => this.handleToken(token, pricingDetails.Experts.key)}
									disabled={isExpertPlan}
									stripeKey={this.stripeKey}
								>
									<AppButton
										uppercase
										big
										bold
										shadow
										color={theme.colors.accentText}
										backgroundColor={theme.colors.accent}
										style={{ marginTop: 40 }}
									>
										{isExpertPlan ? 'Current Plan' : 'Subscribe'}
									</AppButton>
								</Stripe>
							</td>
							<td>
								<Stripe
									name={`Marketplace ${pricingDetails.Distributors.name} Plan`}
									amount={pricingDetails.Distributors.price * 100}
									token={token => this.handleToken(token, pricingDetails.Distributors.key)}
									stripeKey={this.stripeKey}
									disabled={isDistributorPlan}
								>
									<AppButton
										uppercase
										big
										bold
										shadow
										color="#FFFFFF"
										backgroundColor={theme.badge.blue}
										style={{ marginTop: 40 }}
										// onClick={isDistributorPlan ? this.showConfirmBox : undefined}
									>
										{isDistributorPlan ? 'Current Plan' : 'Subscribe'}
									</AppButton>
								</Stripe>
							</td>
							<td>
								<Stripe
									name={`Marketplace ${pricingDetails.WholesaleDistributors.name} Plan`}
									disabled={isWholesalePlan}
									amount={pricingDetails.WholesaleDistributors.price * 100}
									token={token => this.handleToken(token, pricingDetails.WholesaleDistributors.key)}
									stripeKey={this.stripeKey}
								>
									<AppButton
										uppercase
										big
										bold
										shadow
										color="#FFFFFF"
										backgroundColor={theme.badge.darkBlue}
										// onClick={isWholesalePlan ? this.showConfirmBox : undefined}
										style={{ marginTop: 40 }}
									>
										{isWholesalePlan ? 'Current Plan' : 'Subscribe'}
									</AppButton>
								</Stripe>
							</td>
							<td>
								<Stripe
									name={`Marketplace ${pricingDetails.Manufacturers} Plan`}
									disabled={isManufacturePlan}
									amount={pricingDetails.Manufacturers.price * 100}
									token={token => this.handleToken(token, pricingDetails.Manufacturers.key)}
									stripeKey={this.stripeKey}
								>
									<AppButton
										uppercase
										big
										bold
										shadow
										color="#FFFFFF"
										backgroundColor={theme.badge.darkBlue}
										// onClick={isManufacturePlan ? this.showConfirmBox : undefined}
										style={{ marginTop: 40 }}
									>
										{isManufacturePlan ? 'Current Plan' : 'Subscribe'}
									</AppButton>
								</Stripe>
							</td>
						</tr>
					</tfoot>
				</Table>
				<div
					className={showOnLarge}
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						justifyContent: 'center',
						maxWidth: '95%',
						marginRight: 'auto',
						marginLeft: 'auto',
					}}
				>
					<NewPricingCard
						style={{ color: theme.colors.accentText }}
						name={pricingDetails.Experts.name}
						isCurrentPlan={isExpertPlan}
						buttonText={isExpertPlan ? 'Current Plan' : undefined}
						price={`$${pricingDetails.Experts.price}`}
						stripeName={`Marketplace ${pricingDetails.Experts.name} Plan`}
						amount={pricingDetails.Experts.price}
						token={token => this.handleToken(token, pricingDetails.Experts.key)}
						stripeKey={this.stripeKey}
					>
						<ListCaption style={{ color: '#000' }}>Standard Features</ListCaption>
						<CheckList
							list={[
								'Search',
								'Bargain Hunt',
								'Receive Broadcast Requests',
								'Default System Notifications',
							]}
						/>
						<ListCaption style={{ color: '#000' }}>Premium Features</ListCaption>
						<CheckList list={['Multi-Search', 'Post Inventory', 'Send Broadcast Requests']} />
					</NewPricingCard>
					<NewPricingCard
						style={{ backgroundColor: theme.badge.blue }}
						name={pricingDetails.Distributors.name}
						isCurrentPlan={isDistributorPlan}
						price={`$${pricingDetails.Distributors.price}`}
						stripeName={`Marketplace ${pricingDetails.Distributors} Plan`}
						amount={pricingDetails.Distributors.price * 100}
						token={token => this.handleToken(token, pricingDetails.Distributors.key)}
						stripeKey={this.stripeKey}
						linkColor="inherit"
						// pricingList={['50K Records', '1M API Calls']}
						buttonText={isDistributorPlan ? 'Current Plan' : undefined}
						// onClickButton={isDistributorPlan ? this.showConfirmBox : undefined}
					>
						<ListCaption>Standard Features</ListCaption>
						<CheckList
							list={[
								'Search',
								'Bargain Hunt',
								'Receive Broadcast Requests',
								'Default System Notifications',
							]}
						/>
						<ListCaption>Premium Features</ListCaption>
						<CheckList
							list={[
								'Multi-Search',
								'Post Inventory',
								'Send Broadcast Requests',
								'Mobile-Friendly Search Pages',
								'Export Inventory Data',
								'Export Member Records',
								'Related Items',
								'Customizable System Notifications',
								'Web Market',
								'Auto-Delete Unrefreshed Records',
							]}
						/>
					</NewPricingCard>
					<NewPricingCard
						style={{ backgroundColor: theme.badge.darkBlue }}
						name={pricingDetails.WholesaleDistributors.name}
						isCurrentPlan={isWholesalePlan}
						price={`$${pricingDetails.WholesaleDistributors.price}`}
						stripeName={`Marketplace ${pricingDetails.WholesaleDistributors} Plan`}
						amount={pricingDetails.WholesaleDistributors.price * 100}
						token={token => this.handleToken(token, pricingDetails.WholesaleDistributors.key)}
						stripeKey={this.stripeKey}
						linkColor="inherit"
						// pricingList={['1M Records', '10M API Calls']}
						buttonText={isWholesalePlan ? 'Current Plan' : undefined}
						onClickButton={isWholesalePlan ? this.showConfirmBox : undefined}
					>
						<ListCaption>Standard Features</ListCaption>
						<CheckList
							list={[
								'Search',
								'Bargain Hunt',
								'Receive Broadcast Requests',
								'Default System Notifications',
							]}
						/>
						<ListCaption>Premium Features</ListCaption>
						<CheckList
							list={[
								'Multi-Search',
								'Post Inventory',
								'Send Broadcast Requests',
								'Mobile-Friendly Search Pages',
								'Export Inventory Data',
								'Export Member Records',
								'Related Items',
								'Customizable System Notifications',
								'Web Market',
								'Auto-Delete Unrefreshed Records',
								'Advanced Data Exports',
								'Application Program Interface Capability (APIs)',
								'Distance To Seller Displayed',
								'Portal Access',
							]}
						/>
					</NewPricingCard>
					<NewPricingCard
						style={{ backgroundColor: theme.badge.pink }}
						name={pricingDetails.Manufacturers.name}
						isCurrentPlan={isManufacturePlan}
						price={`$${pricingDetails.Manufacturers.price}`}
						stripeName={`Marketplace ${pricingDetails.Manufacturers} Plan`}
						amount={pricingDetails.Manufacturers.price * 100}
						token={token => this.handleToken(token, pricingDetails.Manufacturers.key)}
						stripeKey={this.stripeKey}
						linkColor="inherit"
						// pricingList={['1M Records', '10M API Calls']}
						buttonText={isManufacturePlan ? 'Current Plan' : undefined}
						onClickButton={isManufacturePlan ? this.showConfirmBox : undefined}
					>
						<ListCaption>Standard Features</ListCaption>
						<CheckList
							list={[
								'Search',
								'Bargain Hunt',
								'Receive Broadcast Requests',
								'Default System Notifications',
							]}
						/>
						<ListCaption>Premium Features</ListCaption>
						<CheckList
							list={[
								'Multi-Search',
								'Post Inventory',
								'Send Broadcast Requests',
								'Mobile-Friendly Search Pages',
								'Export Inventory Data',
								'Export Member Records',
								'Related Items',
								'Customizable System Notifications',
								'Web Market',
								'Auto-Delete Unrefreshed Records',
								'Advanced Data Exports',
								'Application Program Interface Capability (APIs)',
								'Distance To Seller Displayed',
							]}
						/>
					</NewPricingCard>
				</div>
			</React.Fragment>
		);
	}
}

PricingTable.propTypes = {
	createSubscription: PropTypes.func.isRequired,
	// deleteSubscription: PropTypes.func.isRequired,
	fetchAppPlan: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	// isSubmitting: PropTypes.bool.isRequired,
	// isExpertPlan: PropTypes.bool.isRequired,
	// isBootstrapPlan: PropTypes.bool.isRequired,
	// isGrowthPlan: PropTypes.bool.isRequired,
	// errors: PropTypes.array.isRequired,
};
const mapStateToProps = state => {
	// const appPlan = getAppPlanByName(state);
	return {
		// isSubmitting: get(state, '$deleteAppSubscription.isFetching'),
		isLoading: get(state, '$createAppSubscription.isFetching', false),
		plan: get(state, 'user.plan'),
		// isBootstrapPlan: get(appPlan, 'isBootstrap'),
		// isGrowthPlan: get(appPlan, 'isGrowth'),
		// errors: [
		// 	get(state, '$deleteAppSubscription.error'),
		// ],
	};
};

const mapDispatchToProps = dispatch => ({
	createSubscription: payload => dispatch(updatePlan(payload)),
	// deleteSubscription: appName => dispatch(deleteAppSubscription(appName)),
	fetchAppPlan: () => dispatch(getPlan()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PricingTable);
