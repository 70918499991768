import React from 'react';
import Appbase from 'appbase-js';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Button, CircularProgress, Tooltip } from '@material-ui/core';
import Help from '../../components/Help';
import { APP_NAME, CLUSTER_URL, CLUSTER_USERNAME, CLUSTER_PASSWORD } from '../../constants/config';
import './product.css';
import { normalizeProductData } from '../../components/add-cart';
import { addProduct } from '../../actions';

class ProductPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			itemDetails: null,
			isLoading: true,
			response: null,
		};
		this.appbaseRef = Appbase({
			app: APP_NAME,
			url: CLUSTER_URL,
			credentials: `${CLUSTER_USERNAME}:${CLUSTER_PASSWORD}`,
		});
	}

	async componentDidMount() {
		const {
			match: {
				params: { productId },
			},
		} = this.props;
		// TODO: Replace - proxy issue
		const response = await this.appbaseRef.get({
			type: '_doc',
			id: productId,
		});
		if (response.found) {
			const itemDetails = {
				...response,
				...response._source,
			};
			this.setState({
				itemDetails,
				response,
			});
		}
		this.setState({
			isLoading: false,
		});
	}

	// item - Response of Item, key - Object Property you want to fetch
	getMultipleKeys = (item, key) => {
		const descriptionKey = Object.keys(item).filter(property =>
			property.toLowerCase().startsWith(key.toLowerCase()),
		);

		const descriptions = descriptionKey.map(key => item[key]);
		return descriptions;
	};

	renderBackButton = () => {
		const { prevLocation } = this.props;
		return (
			<Button
				onClick={() => this.props.history.push(prevLocation || '/')}
				style={{ margin: '0px 0 30px' }}
			>
				Go Back to Search
			</Button>
		);
	};

	isItemPresent = result => {
		const { cart } = this.props;

		const addedToBOM = cart.find(item => item.id === result._id && item.type === 'item');

		return !!addedToBOM;
	};

	getKey = (key, unit) => {
		const { itemDetails } = this.state;

		if (itemDetails[key] === undefined) {
			return 'N/A';
		}

		return unit ? `${itemDetails[key]} ${unit}` : itemDetails[key];
	};

	render() {
		const { itemDetails, isLoading, response } = this.state;
		const { addToCart } = this.props;
		if (isLoading) {
			return (
				<div className="loader-container" style={{ width: '100%' }}>
					<CircularProgress className="loader" color="secondary" />
				</div>
			);
		}

		let dataAttrs = {};
		let data = null;

		if (itemDetails) {
			data = normalizeProductData(itemDetails);
			for (let i in normalizeProductData(itemDetails)) {
				if (['name', 'price', 'id'].includes(i)) {
					dataAttrs[`data-${i}`] = data[i];
				}
			}
		}

		return (
			<React.Fragment>
				{itemDetails ? (
					<div className="product-container">
						<div {...dataAttrs} style={{ display: 'none' }} />
						{this.renderBackButton()}
						<div className="header">
							<div className="image-column">
								<img src="/images/icons/seals-sample-image.png" alt="Product Name" />
							</div>
							<div className="header-column">
								<div>
									<h3>Part Number</h3>
									<p>
										{itemDetails['P21_space_Part_space_#'] || itemDetails['Part#_space_1'] || 'N/A'}
									</p>
								</div>
								<div>
									<h3>Profile Group</h3>
									<p>{itemDetails['Profile_space_Group'] || 'No group Found'}</p>
								</div>
								<div>
									<h3>Description</h3>
									{this.getMultipleKeys(itemDetails, 'description').map(item => (
										<p key={item}>{item}</p>
									))}
								</div>
							</div>
						</div>
						<div style={{ padding: '10px' }}>
							<Button
								size="large"
								color="primary"
								style={{ marginLeft: '8px' }}
								onClick={() => this.props.history.push('/configurator')}
							>
								Customize Product
							</Button>
							<Tooltip
								title={
									this.isItemPresent({ ...response, ...response._source })
										? 'Item already added'
										: 'Add to Cart'
								}
							>
								<Button
									size="large"
									color="secondary"
									style={{ marginLeft: '8px', pointerEvents: 'all', cursor: 'pointer' }}
									disabled={this.isItemPresent({ ...response, ...response._source })}
									onClick={() =>
										addToCart(normalizeProductData({ ...response, ...response._source }))
									}
								>
									Add to Cart
								</Button>
							</Tooltip>
						</div>
						<div className="flex-column details">
							<div>
								<img src="/images/icons/price.svg" alt="Price" />
								<h3>Price</h3>
								<p>{itemDetails.List_space_Price || 'N/A'}</p>
							</div>
							<div>
								<img src="/images/icons/quantity.svg" alt="Quantity" />
								<h3>Quantity Available</h3>
								<p>{this.getKey('Quantity_space_On_space_Hand')}</p>
							</div>
							<div>
								<img src="/images/icons/color.svg" alt="Colors" />
								<h3>Colors</h3>
								<p>{this.getMultipleKeys(itemDetails, 'color').join(', ')}</p>
							</div>
							<div>
								<img src="/images/icons/type.svg" alt="Type" />
								<h3>Type</h3>
								<p>{this.getKey('Male_space_Female')}</p>
							</div>
						</div>
						<div className="flex-column details">
							<div>
								<img src="/images/icons/material.svg" alt="material" />
								<h3>Material</h3>
								<p>{this.getMultipleKeys(itemDetails, 'material').join(', ')}</p>
							</div>
							<div>
								<img src="/images/icons/id.svg" alt="ID" />
								<h3>ID</h3>
								<p>
									{this.getKey('ID_open_d_close__space_-_space_Nominal_numeric', 'in')},{' '}
									{this.getKey('ID_open_d_close__space_-_space_Nominal_numeric', 'mm')}
								</p>
							</div>
							<div>
								<img src="/images/icons/od.svg" alt="OD" />
								<h3>OD</h3>
								<p>
									{this.getKey('OD_open_D_close__space_-_space_Nominal_numeric', 'in')},{' '}
									{this.getKey('OD_open_mm_close__space_-_space_Nominal_numeric', 'mm')}
								</p>
							</div>
							<div>
								<img src="/images/icons/height.svg" alt="Height" />
								<h3>Height</h3>
								<p>
									{this.getKey('Height_open_h_close__space_-_space_Nominal_numeric', 'in')},{' '}
									{this.getKey('Height_open_mm_close__space_-_space_Nominal', 'mm')}
								</p>
							</div>
						</div>
						<div className="flex-column details center">
							<div>
								<img src="/images/icons/temp.svg" alt="Max Temperature" />
								<h3>Operating Temparature (Max)</h3>
								<p>
									{this.getKey('Temperature_space_+C', '℃')},{' '}
									{this.getKey('Temperature_space_+F', '℉')}
								</p>
							</div>
							<div>
								<img src="/images/icons/temp.svg" alt="Min Temperature" />
								<h3>Operating Temparature (Min)</h3>
								<p>
									{this.getKey('Temperature_space_-C', '℃')},{' '}
									{this.getKey('Temperature_space_-F', '℉')}
								</p>
							</div>
						</div>
					</div>
				) : (
					<div className="product-container" style={{ height: 'calc(100vh - 100px)' }}>
						{this.renderBackButton()}
						<h2>No Results Found!</h2>
					</div>
				)}
				<Help />
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	cart: get(state, 'cart', []),
});

const mapDispatchToProps = dispatch => ({
	addToCart: product => dispatch(addProduct(product)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductPage));
