import React from 'react';
import get from 'lodash/get';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { getTheme } from '../../components/theme-controller';
import Grid from '@material-ui/core/Grid';

import './styles.css';
import Steps from './Steps';
import Quote from './Quote';
import { CssBaseline } from '@material-ui/core';

const styles = theme => ({
	container: {
		margin: '10px 0',
	},
	paper: {
		border: '1px solid #e8e8e8',
		padding: '20px',
		width: '100%',
	},
	button: {
		marginRight: theme.spacing.unit,
	},
	instructions: {
		marginTop: theme.spacing.unit,
		marginBottom: theme.spacing.unit,
	},
	title: {
		marginBottom: theme.spacing.unit,
		color: '#262626',
	},
	description: {
		color: '#8c8c8c',
		marginBottom: theme.spacing.unit,
	},
});

class Configurator extends React.Component {
	constructor() {
		super();
		this.steps = [
			{
				label: 'Find or Configure Model',
				title: 'Find or Configure Model',
				key: 'model',
				description: 'Choose a Model, or upload a picture to us and get help.',
				render: <Steps moveForward={this.handleNext} />,
			},
			{
				label: 'Request for Quote',
				title: 'Request for Quote',
				key: 'quote',
				description: 'Fill the form and request Quote.',
				render: <Quote />,
			},
			{
				label: 'Payment',
				title: 'Payment',
				key: 'payment',
				description: 'Payment for the configured product.',
				render: <h1>Payment</h1>,
			},
		];

		this.state = {
			activeStep: 0,
			values: {},
		};
	}

	handleNext = () => {
		const { activeStep } = this.state;
		this.setState({
			activeStep: activeStep + 1,
		});
	};

	handleBack = () => {
		this.setState(state => ({
			activeStep: state.activeStep - 1,
		}));
	};

	handleButton = (value, key) => {
		this.setState(prevState => ({
			values: {
				...prevState.values,
				[key]: value,
			},
		}));
	};

	render() {
		const { classes } = this.props;
		const { activeStep } = this.state;

		return (
			<>
				<MuiThemeProvider theme={getTheme('#2196f3')}>
					<CssBaseline />
					<div className="product-container">
						<Stepper activeStep={activeStep}>
							{this.steps.map(step => {
								return (
									<Step key={step.key} className={classes.step}>
										<StepLabel>{step.label}</StepLabel>
									</Step>
								);
							})}
						</Stepper>
						<div>
							{activeStep === this.steps.length ? (
								<div>
									<Typography className={classes.instructions}>
										Congrats! You've completed the Customization of Product. Here are the Final
										Values:
									</Typography>
								</div>
							) : (
								<div>
									<Grid container spacing={8}>
										<Grid item xs={12} className={classes.container}>
											<Paper className={classes.paper} elevation={0} square>
												<Typography className={classes.title} variant="title">
													{this.steps[activeStep].title}
												</Typography>
												<Typography className={classes.description} variant="body1">
													{this.steps[activeStep].description}
												</Typography>
												{this.steps[activeStep].render}
											</Paper>
										</Grid>
									</Grid>
									<div>
										<Button
											disabled={activeStep === 0}
											onClick={this.handleBack}
											className={classes.button}
										>
											Back
										</Button>
										<Button
											variant="contained"
											color="primary"
											onClick={this.handleNext}
											className={classes.button}
										>
											{activeStep === this.steps.length - 1 ? 'Finish' : 'Next'}
										</Button>
									</div>
								</div>
							)}
						</div>
					</div>
				</MuiThemeProvider>
			</>
		);
	}
}

const mapStateToProps = state => ({
	primaryColor: get(state, 'user.user.preferences.color_scheme.primary'),
	secondaryColor: get(state, 'user.user.preferences.color_scheme.secondary'),
});

export default withStyles(styles)(connect(mapStateToProps)(Configurator));
