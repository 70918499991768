import React from 'react';
import { TextField, Grid, Button, withStyles } from '@material-ui/core';

const styles = () => ({
	container: {
		margin: '20px 0 10px',
	},
});
class Quote extends React.Component {
	constructor() {
		super();
		this.inputs = [
			{
				name: 'application',
				label: 'Application',
				default: '',
			},
			{
				name: 'seal',
				label: 'Seal/Kit',
				default: '',
			},
			{
				name: 'od',
				label: 'Outer Diameter',
				default: '',
			},
			{
				name: 'id',
				label: 'Inner Diameter',
				default: '',
			},
			{
				name: 'height',
				label: 'Height',
				default: '',
			},
		];
		const values = this.inputs.reduce((agg, item) => {
			return { [item.name]: item.default, ...agg };
		}, {});
		this.state = {
			...values,
		};
	}

	handleChange = name => event => {
		this.setState({ [name]: event.target.value });
	};

	render() {
		const { classes } = this.props;
		return (
			<>
				<Grid container spacing={16}>
					{this.inputs.map(item => (
						<Grid item xs={6}>
							<TextField
								id={item.name}
								key={item.name}
								margin="none"
								label={item.label}
								value={this.state[item.name]}
								onChange={this.handleChange(item.name)}
								style={{ width: '100%' }}
								variant="outlined"
							/>
						</Grid>
					))}
					<Grid item xs={6}>
						<Button fullWidth>Upload Picture</Button>
					</Grid>
				</Grid>
				<div className={classes.container}>
					<Button color="secondary">Reset</Button>
					<Button>Get Quote</Button>
				</div>
			</>
		);
	}
}

export default withStyles(styles)(Quote);
