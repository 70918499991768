import React from 'react';
import { ReactiveList, SelectedFilters } from '@appbaseio/reactivesearch';
import { CircularProgress } from '@material-ui/core';
import { get, isEqual } from 'lodash';
import { connect } from 'react-redux';
import { CLUSTER_URL, AUTH_HEADER } from '../../constants/config';
import ResultList from './ResultList';
import CompareResultList from './CompareResultList';
import ErrorPopup from '../../components/error-popup';
import ResultStats from '../../components/result-stats';
import { getComponentReactDependency } from '../../constants/facets';
import { useCustomRef, hasRef } from '../../utils/refService';
import { companyFacets } from '../../utils';

class Results extends React.Component {
	state = {
		popularResults: [],
		filters: {},
	};

	componentDidMount() {
		this.fetchPopularResults();
	}

	clearFilter = () => {
		console.log('Clear results filter');
		setTimeout(() => {
			useCustomRef('facets').triggerUpdate();
			if (hasRef('result-list')) {
				useCustomRef('result-list').forceUpdate();
			}
		}, 200);
	};

	onChangeFilter = values => {
		const { filters } = this.state;
		if (!isEqual(values[companyFacets[0]], filters[companyFacets[0]])) {
			if (hasRef('result-list')) {
				useCustomRef('result-list').forceUpdate();
			}
		}
		if (!isEqual(values, filters)) this.setState({ filters: values });
	};

	fetchPopularResults = async () => {
		try {
			const response = await fetch(
				`${CLUSTER_URL}/_analytics/product-data/popular-results?size=20`,
				{
					headers: {
						Authorization: AUTH_HEADER,
					},
					method: 'GET',
				},
			);
			const res = await response.json();

			const ids = res.popular_results.map(result => ({ _id: result.key }));

			if (ids.length) {
				const popularResults = await fetch(`${CLUSTER_URL}/product-data/_mget`, {
					headers: {
						Authorization: AUTH_HEADER,
					},
					method: 'POST',
					body: JSON.stringify({ docs: ids }),
				}).then(r => r.json());

				// console.log(popularResults);
				this.setState({
					popularResults: popularResults.docs,
				});
			}
		} catch (err) {
			console.log(err);
		}
	};

	render() {
		const { popularResults } = this.state;
		const { handleViewUpdate, dynamic_facets, isCompare } = this.props;
		const userFacets = dynamic_facets.map(facet => facet.id.replace('.keyword', ''));

		const ResultListComp = isCompare ? CompareResultList : ResultList;

		const reactList = {
			...getComponentReactDependency('list_view'),
			and: [
				...new Set([
					...userFacets,
					...getComponentReactDependency('list_view').and,
					'Application',
					'OEM',
					'Curators',
					'Industry',
					'Experts',
					'Nominal_height',
					'Nominal_OD',
					'Nominal_id',
					'Description',
					'P21_space_Part_space_#',
				]),
			],
		};
		return (
			<React.Fragment>
				<SelectedFilters
					showClearAll="default"
					className="selected-filter"
					onChange={this.onChangeFilter}
					onClear={this.clearFilter}
				/>
				<ReactiveList
					defaultQuery={() => ({
						track_total_hits: true,
					})}
					componentId={`${window.location.pathname}_group_view`}
					dataField="original_title"
					react={reactList}
					onData={handleViewUpdate}
					pagination
					render={({ data, promotedData, loading, triggerAnalytics }) => {
						return loading ? (
							<div className="circularProgress">
								<CircularProgress color="secondary" size={30} thickness={7} />
							</div>
						) : (
							<ResultListComp
								hits={data}
								triggerAnalytics={triggerAnalytics}
								promotedData={promotedData}
								popularResults={popularResults}
								showDesc={isCompare}
							/>
						);
					}}
					renderError={error => {
						return <ErrorPopup error={error} />;
					}}
					className="result-data"
					innerClass={{
						listItem: 'result-item',
						title: 'result-item-title',
						resultStats: 'result-stats',
						noResults: 'no-results',
						poweredBy: 'hide',
					}}
					renderResultStats={stats => <ResultStats {...stats} />}
					renderNoResults={() => (
						<div>
							<p>We couldn't find any results.</p>
							<br />
							<a href="/">Try a different search criteria.</a>
						</div>
					)}
					showResultStats
				/>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	dynamic_facets: get(state, 'user.dynamic_facets', []),
});

export default connect(mapStateToProps)(Results);
