import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import ActiveCart from '../../icons/ActiveCart';
import { ReactComponent as Cart } from '../../icons/cart.svg';
import {
	removeProduct,
	updateHomeLeftSidebar,
	toggleGlobalRightSidebar,
	updateGlobalRightSidebar,
} from '../../actions';
import { rightSidebarContent } from '../../constants/sidebar';

const styles = theme => ({
	margin: {
		margin: theme.spacing.unit * 2,
	},
	padding: {
		padding: `0 ${theme.spacing.unit * 3}px`,
		cursor: 'pointer',
	},
});

class ShoppingCart extends React.Component {
	constructor() {
		super();
		this.state = {
			showCart: false,
		};
	}

	handleCart = () => {
		const { updateView, currentView, toggleSidebar, updateMobileView } = this.props;
		this.setState(({ showCart: prevValue }) => ({
			showCart: !prevValue,
		}));
		const view = rightSidebarContent.Cart;
		if (view !== currentView) {
			if (window.innerWidth < 768) {
				updateMobileView(view);
			} else {
				updateView(view);
			}
		} else {
			toggleSidebar();
		}
	};

	render() {
		const { cart } = this.props;

		return (
			<Badge
				color="secondary"
				badgeContent={cart.length}
				style={{ margin: '0 10px', padding: '0 6px', cursor: 'pointer' }}
				onClick={this.handleCart}
			>
				{this.state.showCart ? <ActiveCart className="icon" /> : <Cart className="icon" />}
			</Badge>
		);
	}
}

const mapStateToProps = state => ({
	primaryColor: get(state, 'user.user.preferences.color_scheme.primary'),
	secondaryColor: get(state, 'user.user.preferences.color_scheme.secondary'),
	accentColor: get(state, 'user.user.preferences.color_scheme.accent'),
	currentView: get(state, 'sidebar.global.right.view', null),
	cart: get(state, 'cart', []),
});

const mapDispatchToProps = dispatch => ({
	removeProductFromCart: id => dispatch(removeProduct(id)),
	toggleSidebar: () => dispatch(toggleGlobalRightSidebar()),
	updateView: view => dispatch(updateGlobalRightSidebar(view)),
	updateMobileView: view => dispatch(updateHomeLeftSidebar(view)),
});

export default withRouter(
	withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ShoppingCart)),
);
