import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';

import 'antd/dist/antd.css';
import '../../css/Bom.css';

import { updateProduct, updateCart } from '../../actions';
import { defaultDataFields } from '../../utils';
import DraggableCart from '../../components/draggable-cart';
import ItemSearch from '../../components/item-search';
import { normalizeProductData } from '../../components/add-cart';

const searchRef = React.createRef(null);
const draggableRef = React.createRef(null);

class BOMView extends React.Component {
	state = {
		isSuggestionSelected: false,
		newKey: Date.now(),
	};

	addProduct = selectedSuggestion => {
		const cart = draggableRef.current.state.cart;
		if (selectedSuggestion) {
			const itemToAdd = normalizeProductData(selectedSuggestion);

			const isItemPresent = cart.find(item => item.id === itemToAdd.id && item.type === 'item');
			if (isItemPresent) {
				if (draggableRef && draggableRef.current) {
					draggableRef.current.updateProductQuantity({
						id: itemToAdd.id,
						quantity: (isItemPresent.quantity || 0) + (itemToAdd.quantity || 0),
					});
				}
			} else {
				if (draggableRef && draggableRef.current) {
					draggableRef.current.addProductToCart(normalizeProductData(selectedSuggestion));
				}
			}
			if (searchRef && searchRef.current && searchRef.current.clearValue) {
				searchRef.current.clearValue();
			}
		}
	};

	render() {
		const { containerStyle, hideSearch, updateCart, cart } = this.props;

		return (
			<React.Fragment>
				<div className="bom-container" style={containerStyle}>
					{hideSearch ? null : <ItemSearch searchRef={searchRef} draggableRef={draggableRef} />}
					<DraggableCart
						ref={draggableRef}
						onUnmount={cartItems => {
							updateCart(cartItems);
						}}
						updater={updateCart}
						cart={cart}
					/>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	const dataFields = get(state, 'user.user.dataFields.length')
		? get(state, 'user.user.dataFields')
		: defaultDataFields;

	return {
		cart: get(state, 'cart', []),
		dataFields,
		primaryColor: get(state, 'user.user.preferences.color_scheme.primary'),
		secondaryColor: get(state, 'user.user.preferences.color_scheme.secondary'),
		accentColor: get(state, 'user.user.preferences.color_scheme.accent'),
	};
};

const mapDispatchToProps = dispatch => ({
	updateProductQuantity: product => dispatch(updateProduct(product)),
	updateCart: cart => dispatch(updateCart(cart)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BOMView);
