import React from 'react';
import { getTheme } from '../components/theme-controller';
import { connect } from 'react-redux';
import get from 'lodash/get';

const Icon = ({ primaryColor, secondaryColor, accentColor, dispatch, ...props }) => {
	const theme = getTheme(primaryColor, secondaryColor, accentColor);
	return (
		<svg
			width="20px"
			height="15px"
			viewBox="0 0 20 15"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>46DF85FF-DE43-453A-A6C1-52BF448279E0@3x</title>
			<desc>Created with sketchtool.</desc>
			<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g
					id="Icon-Set"
					transform="translate(-346.000000, -158.000000)"
					fillRule="nonzero"
					stroke={theme.palette.secondary.main}
				>
					<g id="Group-5-Copy" transform="translate(346.000000, 158.000000)">
						<rect id="Rectangle" x="0.5" y="0.5" width="19" height="14"></rect>
						<path d="M6.5,1 L6.5,13.9230769" id="Line-3" strokeLinecap="square"></path>
						<path
							d="M18.5917969,7.92307692 L0.5,7.92307692"
							id="Line-3-Copy-2"
							strokeLinecap="square"
						></path>
						<path d="M13.5,1 L13.5,13.9230769" id="Line-3-Copy" strokeLinecap="square"></path>
					</g>
				</g>
			</g>
		</svg>
	);
};

const mapStateToProps = state => ({
	primaryColor: get(state, 'user.user.preferences.color_scheme.primary'),
	secondaryColor: get(state, 'user.user.preferences.color_scheme.secondary'),
	accentColor: get(state, 'user.user.preferences.color_scheme.accent'),
});

export default connect(mapStateToProps)(Icon);
