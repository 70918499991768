import { CLUSTER_URL, AUTH_HEADER, APP_NAME } from '../constants/config';

export const fetchIndex = async ({
	dataFields = {},
	size = 10,
	execute = true,
	fuzziness = 0,
	enableSynonyms = true,
	searchOperators = false,
	queryFormat = 'or',
	index,
	value,
}) => {
	const url = `${CLUSTER_URL}/${index}/_reactivesearch.v3`;
	const data = {
		settings: { recordAnalytics: true, enableQueryRules: true },
		query: [
			{
				id: 'search',
				type: 'search',
				dataField: Object.keys(dataFields),
				execute,
				react: { and: 'search__internal' },
				fuzziness,
				searchOperators,
				size,
				queryFormat,
				fieldWeights: Object.values(dataFields),
				enableSynonyms,
			},
			{
				id: 'search__internal',
				type: 'search',
				dataField: Object.keys(dataFields),
				execute,
				fuzziness,
				searchOperators,
				size,
				queryFormat,
				fieldWeights: Object.values(dataFields),
				value,
				enableSynonyms,
			},
		],
	};

	return fetch(url, {
		method: 'POST',
		headers: {
			Authorization: AUTH_HEADER,
		},
		body: JSON.stringify(data),
	}).then(res => res.json());
};

export const fetchCompanies = args =>
	fetchIndex({
		index: 'company-data',
		size: 3,
		dataFields: {
			'Address 1: City.synonyms': 0.7,
			'Address 1: City.search': 0.1,
			'Address 1: City.lang': 0.9,
			'Address 1: City.keyword': 1,
			'Address 1: City.autosuggest': 0.9,
			'Address 1: City': 1,
			'Address 1.synonyms': 0.7,
			'Address 1.search': 0.1,
			'Address 1.lang': 0.9,
			'Address 1.keyword': 1,
			'Address 1.autosuggest': 0.9,
			'Address 1': 1,
			'name.synonyms': 0.7,
			'name.search': 0.1,
			'name.lang': 0.9,
			'name.keyword': 1,
			'name.autosuggest': 0.9,
			name: 1,
			Account: 1,
		},
		...args,
	});
export const fetchPeople = args =>
	fetchIndex({
		index: 'people-data',
		size: 3,
		dataFields: {
			fullname: 1,
			'fullname.autosuggest': 1,
			'fullname.keyword': 1,
			'fullname.lang': 1,
			'fullname.search': 1,
			'fullname.synonyms': 1,
			'Address 1': 1,
			'Address 1.autosuggest': 1,
			'Address 1.keyword': 1,
			'Address 1.lang': 1,
			'Address 1.search': 1,
			'Address 1.synonyms': 1,
			'Address 1: City': 1,
			'Address 1: City.autosuggest': 1,
			'Address 1: City.keyword': 1,
			'Address 1: City.lang': 1,
			'Address 1: City.search': 1,
			'Address 1: City.synonyms': 1,
		},
		...args,
	});

export const fetchAssets = args =>
	fetchIndex({
		index: 'assets-data',
		size: 3,
		dataFields: {
			'Make.synonyms': 0.7,
			'Make.search': 0.1,
			'Make.lang': 0.9,
			'Make.keyword': 1,
			'Make.delimiter': 0.4,
			'Make.autosuggest': 0.9,
			'Equipment.synonyms': 0.7,
			'Equipment.search': 0.1,
			'Equipment.lang': 0.9,
			'Equipment.keyword': 1,
			'Equipment.delimiter': 0.4,
			'Equipment.autosuggest': 0.9,
			'Model.synonyms': 0.7,
			'Model.search': 0.1,
			'Model.lang': 0.9,
			'Model.keyword': 1,
			'Model.delimiter': 0.4,
			'Model.autosuggest': 0.9,
		},
		...args,
	});

export const recordSearch = async (query, eventData) => {
	const url = `${CLUSTER_URL}/${APP_NAME}/_analytics/search`;
	const data = {
		query,
		event_data: eventData,
	};
	return fetch(url, {
		method: 'PUT',
		headers: {
			Authorization: AUTH_HEADER,
		},
		body: JSON.stringify(data),
	}).then(res => res.json());
};

export const fetchCompanySearch = async (type, size = 5) => {
	const url = `${CLUSTER_URL}/_analytics/${APP_NAME}/popular-searches?company=${encodeURIComponent(
		type,
	)}&size=${size}`;

	return fetch(url, {
		headers: {
			Authorization: AUTH_HEADER,
		},
		method: 'GET',
		mode: 'cors',
	}).then(res => res.json());
};
