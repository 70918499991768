import React from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRouter, Link } from 'react-router-dom';
import { Avatar } from '@material-ui/core';
import get from 'lodash/get';
import Auth from '../../utils/authenticate';
import {
	logoutUser,
	toggleGlobalLeftSidebar,
	updateGlobalLeftSidebar,
	toggleGlobalRightSidebar,
	updateGlobalRightSidebar,
	updateHomeLeftSidebar,
} from '../../actions';
import {
	authConfig,
	whitelistedUsers,
	gridViewsOptions,
	ADMIN_URL,
	metaDataConfig,
	hideCartOnNav,
} from '../../constants/config';
import { parseJwt } from '../../utils';

import { ReactComponent as Phone } from '../../icons/phone.svg';
import ShoppingCart from '../search-container/ShoppingCart';
import { getTheme } from '../theme-controller';
import { leftSidebarContent, rightSidebarContent } from '../../constants/sidebar';

const auth = new Auth(authConfig);

const handleLogin = () => {
	// Set last route to be redirect after success login
	localStorage.setItem('lastRoute', window.location.pathname);
	return auth.login();
};

const styles = theme => ({
	root: {
		zIndex: 101,
		paddingTop: 7,
		'&[x-placement*="bottom"] $arrow': {
			top: 7,
			left: '5px !important',
			marginTop: '-0.9em',
			width: '3em',
			height: '1em',
			'&::before': {
				borderWidth: '0 1em 1em 1em',
				borderColor: `transparent transparent ${theme.palette.common.white} transparent`,
			},
		},
	},
	paper: {
		borderRadius: 2,
		display: 'flex',
		flexWrap: 'wrap',
		background: '#fff',
		boxShadow: '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
	},
	typography: {
		padding: theme.spacing.unit * 2,
	},
	arrow: {
		position: 'absolute',
		fontSize: 7,
		width: '3em',
		height: '3em',
		'&::before': {
			content: '""',
			margin: 'auto',
			display: 'block',
			width: 0,
			height: 0,
			borderStyle: 'solid',
		},
	},
	modal: {
		'& > div': {
			backgroundColor: 'rgba(255, 255, 255, 1)',
		},
	},
});
class Nav extends React.PureComponent {
	popperRef = null;

	state = {
		anchorEl: null,
		open: false,
		isModalOpen: false,
		openUserMenu: false,
	};

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleMenuToggle = e => {
		this.setState(prevState => ({
			open: !prevState.open,
			isModalOpen: false,
		}));
	};

	handleModal = e => {
		e.stopPropagation();
		this.setState(prevState => ({
			isModalOpen: !prevState.isModalOpen,
		}));
	};

	setRef = node => {
		this.popperRef = node;
	};

	handleClickOutside = event => {
		if (
			this.state.open &&
			this.popperRef &&
			!this.popperRef.contains(event.target) &&
			!this.state.anchorEl.contains(event.target)
		) {
			this.setState({
				open: false,
			});
		}
	};

	handleLogout = () => {
		auth.logout();
		setTimeout(() => this.props.logout(), 100);
		this.handleClose();
	};

	handleToggle = () => {
		this.setState(state => ({ openUserMenu: !state.openUserMenu }));
	};

	handleClose = event => {
		if (this.anchorEl.contains(event && event.target)) {
			return;
		}

		this.setState({ openUserMenu: false });
	};

	handleAdminSidebar = () => {
		const { userEmail, toggleLeftSidebar, updateLeftView, currentLeftView } = this.props;
		const view = leftSidebarContent.Admin;
		if (whitelistedUsers.includes(userEmail)) {
			if (view !== currentLeftView) {
				updateLeftView(view);
			} else {
				toggleLeftSidebar();
			}
		}
	};

	handleUserMenu = () => {
		const { toggleRightSidebar, updateRightView, currentRightView, updateMobileView } = this.props;
		const view = rightSidebarContent.Profile;
		if (view !== currentRightView) {
			if (window.innerWidth < 768) {
				updateMobileView(view);
			} else {
				updateRightView(view);
			}
		} else {
			toggleRightSidebar();
		}
	};

	render() {
		const { open, openUserMenu } = this.state;
		const { isLoggedIn, userName, token, showUpgrade } = this.props;
		const theme = getTheme(
			this.props.primaryColor,
			this.props.secondaryColor,
			this.props.accentColor,
		);
		const themeDark = theme.palette.primary.dark;
		const user = parseJwt(token);
		const metaData = user && user[metaDataConfig];
		const logoURL = metaData && metaData.logoURL;
		const customName = metaData && metaData.name;

		return (
			<div className="navbar" style={{ color: theme.textColor, background: 'white' }}>
				<div className="title-wrapper">
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							paddingLeft: '15px',
							cursor: 'pointer',
						}}
						ref={node => {
							this.setState({
								anchorEl: node,
							});
						}}
					>
						<Link to="/">
							<img
								alt="blank"
								src={logoURL || '/images/transparent.png'}
								style={{
									maxWidth: '200px',
									maxHeight: 50,
								}}
							/>
						</Link>
					</div>
					<span className="sm-phone" style={{ marginLeft: 10 }}>
						<Phone />
						&nbsp;123-456-7890
					</span>
				</div>
				<div className="right-menu">
					{showUpgrade && (
						<span className="hide-sm hide-md">
							<Button
								style={{
									backgroundColor: themeDark,
									margin: '0 20px',
								}}
								onClick={() => {
									window.location = '/account#billing';
								}}
							>
								Upgrade Account
							</Button>
						</span>
					)}
					<span className="hide-sm hide-md">
						<Phone />
						&nbsp;123-456-7890
					</span>
					<div>
						{isLoggedIn ? (
							<React.Fragment>
								{hideCartOnNav.find(item => window.location.pathname === item) ? null : (
									<ShoppingCart />
								)}
								<Button
									buttonRef={node => {
										this.anchorEl = node;
									}}
									aria-owns={openUserMenu ? 'menu-list-grow' : undefined}
									aria-haspopup="true"
									onClick={this.handleUserMenu}
									style={{ color: theme.textColor }}
								>
									{window.innerWidth < 560 ? null : customName || userName}
									<Avatar
										style={{
											marginLeft: 5,
											color: theme.palette.secondary.main,
											background: 'transparent',
											border: `1px solid ${theme.palette.secondary.main}`,
										}}
									>
										{(customName || userName).split(' ').reduce((agg, item) => {
											return agg + item.charAt(0);
										}, '')}
									</Avatar>
								</Button>
							</React.Fragment>
						) : (
							<Button
								onClick={handleLogin}
								variant="contained"
								color="primary"
								className="inverse-button"
							>
								Sign In
							</Button>
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	const email = get(state, 'user.user.email');
	const defaultBrandTitle = whitelistedUsers.includes(email) ? 'Admin User' : 'Preview User';
	return {
		isLoggedIn: !!get(state, 'user.user'),
		showUpgrade: !(whitelistedUsers.includes(email) || get(state, 'user.plan') !== 'free'),
		userName: get(state, 'user.user.first_name', '') + ' ' + get(state, 'user.user.last_name', ''),
		userEmail: get(state, 'user.user.email'),
		brandName: get(state, 'user.user.preferences.branding_title', defaultBrandTitle),
		gridViews: get(state, 'user.user.grid_views', gridViewsOptions),
		token: get(state, 'user.user.jwt_token'),
		primaryColor: get(state, 'user.user.preferences.color_scheme.primary'),
		secondaryColor: get(state, 'user.user.preferences.color_scheme.secondary'),
		accentColor: get(state, 'user.user.preferences.color_scheme.accent'),
		currentLeftView: get(state, 'sidebar.global.left.view', null),
		currentRightView: get(state, 'sidebar.global.right.view', null),
	};
};

const mapDispatchToProps = dispatch => ({
	logout: () => dispatch(logoutUser()),
	toggleLeftSidebar: () => dispatch(toggleGlobalLeftSidebar()),
	updateLeftView: view => dispatch(updateGlobalLeftSidebar(view)),
	toggleRightSidebar: () => dispatch(toggleGlobalRightSidebar()),
	updateRightView: view => dispatch(updateGlobalRightSidebar(view)),
	updateMobileView: view => dispatch(updateHomeLeftSidebar(view)),
});

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Nav)));
