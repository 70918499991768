/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { arrayOf, shape, func, bool } from 'prop-types';
import { isEmpty } from 'lodash';
import './ResultItem.css';
import ResultItem from './ResultItem';
import { ReactComponent as Search } from '../../icons/search.svg';
import { useCustomRef, setRef, removeRef } from '../../utils/refService';
import { fetchCompanySearch } from '../../utils/helpers';
import { RestaurantMenu } from '@material-ui/icons';

const groupBy = (items, key) =>
	items.reduce(
		(result, item) => ({
			...result,
			[item[key]]: [...(result[item[key]] || []), item],
		}),
		{},
	);

const styles = {
	card: {
		width: 200,
		padding: 10,
	},
	content: {
		padding: '5px 10px',
	},
	typo: {
		fontSize: '10px',
	},
	media: {
		objectFit: 'contain',
	},
	action: {
		padding: 3,
	},
	button: {
		fontSize: 10,
		width: '50%',
		paddingTop: 3,
		paddingBottom: 3,
		minHeight: 15,
		borderRadius: 0,
	},
};

const dummyLabels = ['Application', 'Industry', 'Recent Orders'];

class ResultList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			searchItems: [],
			currentCompany: null,
		};

		const search = new URLSearchParams(window.location.search);
		if (search.has('Account Name')) {
			const companyName = search.get('Account Name');
			this.state.currentCompany = companyName.substr(2, companyName.length - 4);
		}
	}

	componentDidMount() {
		setRef('result-list', this);
		this.fetchSearch(true);
	}

	componentDidUpdate() {
		this.fetchSearch();
	}

	componentWillUnmount() {
		removeRef('result-list');
	}

	fetchSearch = (force = false) => {
		const { currentCompany, searchItems } = this.state;
		const search = new URLSearchParams(window.location.search);
		if (search.has('Account Name')) {
			let companyName = search.get('Account Name');
			companyName = companyName.substr(2, companyName.length - 4);
			if (force || currentCompany !== companyName) {
				fetchCompanySearch(companyName, 6).then(res => {
					this.setState({
						searchItems: res.popular_searches.filter(a => !isEmpty(a.key)).slice(0, 5),
						currentCompany: companyName,
					});
				});
			}
		} else if (searchItems.length > 0 || currentCompany !== null) {
			this.setState({ searchItems: [], currentCompany: null });
		}
	};

	render() {
		const { hits, promotedData, popularResults, triggerAnalytics, showDesc } = this.props;
		const { searchItems } = this.state;
		const search = new URLSearchParams(window.location.search);

		const groupedList = groupBy(
			hits.filter(h => !h._promoted),
			'Application',
		);
		return (
			<div className="container">
				{searchItems.length > 0 && (
					<div style={{ padding: 10, paddingLeft: 0 }}>
						<h3>My Searches</h3>
						{searchItems.map(it => (
							<div
								onClick={e => {
									e.preventDefault();
									const searchRef = useCustomRef('search');
									searchRef.setState({ value: it.key }, () => {
										searchRef.searchRef.triggerQuery();
										searchRef.recordSearch(it.key);
									});
								}}
								key={it.key}
								style={{
									display: 'flex',
									width: '100%',
									maxWidth: 200,
									justifyContent: 'space-between',
									alignItems: 'center',
									fontSize: 16,
									cursor: 'pointer',
									paddingLeft: 10,
								}}
							>
								<span style={{ paddingRight: '10px', color: 'rgb(51, 51, 51)' }}>{it.key}</span>
								<Search />
							</div>
						))}
					</div>
				)}
				{promotedData && promotedData.length ? (
					<div>
						<h3>Promoted Results</h3>
						<div className="card-result">
							{promotedData.map(result => (
								<ResultItem
									isPopular
									key={result._click_id}
									result={result}
									showDesc={showDesc}
									onClick={() => triggerAnalytics(result._click_id)}
								/>
							))}
						</div>
					</div>
				) : null}
				{!search.has('q') && popularResults && popularResults.length ? (
					<div>
						<h3>Popular Results</h3>
						<div className="card-result">
							{popularResults.map(({ _source, ...rest }) => {
								const flattenResult = { ...rest, ..._source };
								return (
									<ResultItem
										key={flattenResult._id}
										result={flattenResult}
										showDesc={showDesc}
										onClick={() => null}
									/>
								);
							})}
						</div>
					</div>
				) : null}

				{Object.keys(groupedList)
					.sort()
					.map((item, i) => (
						<div key={item}>
							<h3>{dummyLabels[i] || item}</h3>
							<div className="card-result">
								{groupedList[item].map(result => (
									<ResultItem
										key={result._click_id}
										result={result}
										showDesc={showDesc}
										onClick={() => triggerAnalytics(result._click_id)}
									/>
								))}
							</div>
						</div>
					))}
			</div>
		);
	}
}

ResultList.propTypes = {
	popularResults: arrayOf(shape({})).isRequired,
	hits: arrayOf(shape({})).isRequired,
	promotedData: arrayOf(shape({})).isRequired,
	triggerAnalytics: func.isRequired,
	// eslint-disable-next-line react/require-default-props
	showDesc: bool,
};

export default withStyles(styles)(ResultList);
